import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { ElementExecutor } from "@apexcura/core";
import { CONSTANTS } from '../../utils/app-constants';
import Storage from '../../utils/local-storage';
import Utils, { returnSnippetCode } from '../../utils';
import { toast } from 'react-toastify';
import useExitPopUp from '../../hooks/useExitPopUp';
import LoadingState from '../LoadingState';
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;
const { BASE_URL } = CONSTANTS;

const chatbotDetailsSchema=()=>({
    name:"chatbot_details",
    className:"flex flex-col pt-4 gap-6",
    fields:[
        {
            name:"chatbot_text",
            className:"flex justify-between ",
            fields:[
                {
                    name:"chatbot_text",
                    label:"Virtual Assistant",
                    className:"text-black text-lg font-semibold uppercase",
                    element:"div",
                },
                {
                    name: 'chatbot_edit_icon',
                    className: '',
                    iconsClassName:"size-4",
                    element: 'button',
                    icon:`${BASE_URL}/api/public/assets/images/edit.svg`,
                    isSVGStylesOverride: false,
                    visible: true,
                },
            ]
        },
        {
            name:"chatbot_details_snippet",
            className:"justify-between gap-2 px-5 w-full grid grid-cols-12",
            fields:[
                {
                    name:"chatbot_name",
                    className:"flex flex-col gap-5 col-span-5",
                    fields:[
                        {
                            name: "name_of_the_chatbot",
                            className:"flex items-center gap-2 w-full ",
                            fields:[
                                {
                                    name:"chatbot_name_text",
                                    label:"Virtual Assistant Name:",
                                    className:"text-gray-4 text-sm font-base",
                                    element:"div",
                                },
                                {
                                    name:"chatbot_name_value",
                                    label:"",
                                    className:"text-black text-base font-semibold",
                                    element:"div",
                                    value:null,
                                    visible:true
                                },
                                {
                                    name:"chatbot_name_value_textfield",
                                    label:"",
                                    value:"",
                                    className:"rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                                    element:"input-text",
                                    visible:false,
                                }
                            ]
                        },
                        {
                            name:"chatbot_logo_upload",
                            className:"flex flex-col gap-2  w-full ",
                            fields:[
                                {
                                    name:"chatbot_design_text",
                                    label:"Virtual Assistant Icon",
                                    className:"text-gray-4 text-sm font-base",
                                    element:"div",
                                },
                                {
                                    name:"chatbot_preview_image",
                                    className:"w-[140px] h-[140px] p-2 border-2 rounded-md",
                                    fields:[
                                        {
                                            name:"chatbot_design_image_preview",
                                            className:"w-full h-full object-cover",
                                            element:"image",
                                            img:"",
                                            visible:true,
                                            alt:"preview_image"
                                        },
                                        {
                                            name:"chatbot_design_image_upload_preview",
                                            className:"w-[100%] h-[100%] flex items-center justify-center",
                                            element:"avatar-upload",
                                            visible:false,
                                            value:"",
                                            icon: "add_avatar",
                                            iconsClassName:"icon-button absolute -bottom-[0.7rem] -right-[0.7rem] w-7 p-2 bg-gray-200 rounded-md",
                                            containerClassName:
                                              "avatar-uploader h-[90%] w-[90%]  border border-gray-100  relative",
                                        },
                                    ]
                                },
                            ]
                        },
                    ]
                },
                {
                    name:"chatbot_snippet_code",
                    className:"col-span-7 w-full flex flex-col gap-5",
                    fields:[
                        {
                            name:"chatbot_snippet_and_copy_button",
                            className:"w-[100%] flex justify-between items-center",
                            fields:[
                                {
                                    name:"chatbot_snippet_code_name",
                                    element:"div",
                                    label:"Script Code",
                                    className:"text-gray-4 text-base font-semibold",
                                },
                                {
                                    name:"chatbot_copy_button",
                                    element:"button",
                                    icon:`${BASE_URL}/api/public/assets/images/copy.svg`,
                                    visible: true,
                                    label:"Copy",
                                    className: "flex gap-2 items-center justify-center px-3 py-2 text-base ",
                                    iconsClassName: "size-4",
                                    isSVGStylesOverride: false,
                                }
                            ]
                        },
                        {
                            name:'chatbot-snippet-code_text',
                            className:'h-[150px]  bg-white overflow-scroll border border-2 font-mono whitespace-pre',
                            element:'div',
                            label:returnSnippetCode(Storage.getItem("details")["website"])
                        }
                    ]
                }
            ]
        },
        {
            name:"chatbot_buttons",
            className:"flex gap-2 items-center justify-start px-5 pt-2",
            visible:false,
            fields:[
                {
                    name: "chatbot_save_changes",
                    label: "Save Changes",
                    element: "button",
                    className:"p-2 px-3 text-default-500 bg-[#3341bb] rounded-md text-white font-semibold",
                    visible:true
                },
                {
                    name:'chatbot_cancel_button',
                    label:"Cancel",
                    element:'button',
                    className:"text-primary p-2 px-3 text-default-500 bg-white border border-primary rounded-md font-semibold ", 
                    visible:true
                }
            ]
        }
    ]
});

const contactFormUrlSchema=()=>({
    name:"contact_form_url",
    className:"flex flex-col gap-6",
    fields:[
        {
            name:"contact_form_url_snippet_code",
            className:"col-span-8 w-full flex flex-col gap-3",
            fields:[
                {
                    name:"contact_form_url_snippet_and_copy_button",
                    className:"w-[100%] flex justify-between items-center",
                    fields:[
                        {
                            name:"contact_form_url_snippet_code_name",
                            element:"div",
                            label:"Contact Form Endpoint",
                            className:"text-gray-4 text-base font-semibold",
                        },
                        {
                            name:"contact_form_url_copy_button",
                            element:"button",
                            icon:`${BASE_URL}/api/public/assets/images/copy.svg`,
                            visible: true,
                            label:"Copy",
                            className: "flex gap-2 items-center justify-center px-3 py-2 text-base ",
                            iconsClassName: "size-4",
                            isSVGStylesOverride: false,
                        }
                    ]
                },
                {
                    name:'contact_form_url_snippet_code_text',
                    className:'h-[60px] px-3 flex items-center bg-white overflow-scroll border border-2 font-mono whitespace-pre [&::-webkit-scrollbar]:hidden',
                    element:'div',
                    label:`${BASE_URL}/api/contactForm`
                }
            ]
        }
    ]
});

const Widget = () => {
    
    const [loading,setLoading]=useState(false)
    const org_id=Storage.getItem('details')['org_id'];
    const [orgDetails,setOrgDetails]=useState<any>({});
    const [chatbotName,setChatbotName]=useState("")
    const [chatbotLogo,setChatbotLogo]=useState("");
    const [chatbotUploadLogo,setChatbotUploadLogo]=useState<any>(false);
    const [chatBotSchema,setChatBotSchema]=useState<any>({...chatbotDetailsSchema()})
    const [contactFormUrl,setContactFormUrl]=useState<any>({...contactFormUrlSchema()})
    const [schema, setSchema] = useState<any>({
        className: 'flex flex-col w-full gap-2 bg-white rounded-md px-4 pb-4',
        schema: [
            {
                name: 'tabs_and_datepicker',
                className: 'flex justify-between items-center w-full text-sm',
                visible: true,
                fields: [
                    {
                        name: 'tabs',
                        element: 'tabs', 
                        value: '', 
                        visible:true,
                        options:[
                            { label: 'Virtual Assistant ', value: 'vr_assistant',key:1 },
                            { label: 'Contact Form', value: 'contact_url',key:2 },
                        ],
                        required: true, 
                    },
                    
                ]
            },
            {
                name: 'schema_form',
                className: 'flex flex-col gap-2 w-full ',
                fields: [{...chatBotSchema}],
            },
            
        ],
    })
    
    const [detectChange, setDetectChange] = useState(false)
    useExitPopUp(detectChange)

    useEffect(()=>{
        API_GET_OrganizationDetails();
    },[])

    const handleChangeOrgDetails=(data:any)=>{
        setChatBotSchema((prev:any)=>{
            prev.fields=prev.fields.map((field:any)=>{
                if(field.name==="chatbot_details_snippet"){
                    field.fields=field.fields.map((ffield:any)=>{
                        if(ffield.name==="chatbot_name"){
                            ffield.fields=ffield.fields.map((fffield:any)=>{
                                if(fffield.name==="name_of_the_chatbot"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="chatbot_name_value"){
                                            ffffield.label=data?.chatbot_name
                                        }
                                        if(ffffield.name==="chatbot_name_value_textfield"){
                                            ffffield.value=data?.chatbot_name
                                        }
                                        return ffffield;
                                    })
                                }
                                if(fffield.name==="chatbot_logo_upload"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="chatbot_preview_image"){
                                            ffffield.fields=ffffield.fields.map((fffffield:any)=>{
                                                if(fffffield.name==="chatbot_design_image_preview"){
                                                    fffffield.img=`${BASE_URL}/api/${data?.chatbot_icon}`
                                                }
                                                // if(fffffield.name==="chatbot_design_image_upload_preview"){
                                                //     fffffield.value=`data:image/png;base64,${data?.chatbot_icon}`
                                                // }
                                                return fffffield;
                                            })
                                        }
                                        return ffffield;
                                    })
                                }
                                return fffield;
                            })
                        }
                        
                        return ffield;
                    })
                }
                return field;
            })
            return {...prev}
        })

        setChatbotName(data?.chatbot_name)
        setChatbotLogo(data?.chatbot_icon)
    }

    const API_GET_OrganizationDetails=async ()=>{
        setLoading(true)
        try{
            const res:any=await Utils.makeApiCall( `/getOrganizationDetails?organizationId=${org_id}`,"GET");
            if(res.status){

                handleChangeOrgDetails(res.data);
                setOrgDetails(res.data)
            }else{
                toast.error(res.message ? res.message : FAILURE_MSG,{position:"top-center",autoClose:AUTO_CLOSE})
            }
        }catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
            console.error("Failed to fetch data", error);
        }
        setLoading(false)
    }

    const API_PUT_OrganizationDetails=async (save_button:string)=>{
        const payload:FormData=new FormData();
        if(save_button==="chatbot_save_changes"){
            if(chatbotName.length===0){
                toast.error("Chatbot Name cannot be empty.",{position:"top-center",autoClose:AUTO_CLOSE})
                return
            }
            payload.append("chatbot_name",chatbotName);
            if(chatbotUploadLogo){
                payload.append(`chatbot_icon`, chatbotUploadLogo.originFileObj);
             }
        }

        setLoading(true)
        try{
            const res:any=await Utils.makeFormdataApiCall( `/organization?organizationId=${org_id}`,"PUT",payload);
            if(res.status){
                toast.success('Organization Details updated successfully', { position: "top-center", autoClose: 2000 })
                API_GET_OrganizationDetails()
                handleChatBotCancelButton();
            }else{
                toast.error(res.message ? res.message : FAILURE_MSG,{position:"top-center",autoClose:AUTO_CLOSE})
            }
        }catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
            console.error("Failed to update data", error);
        }
        setLoading(false)
    }


    const handleChatbotSchema=()=>{
        setChatBotSchema((prev:any)=>{
            prev.fields=prev.fields.map((field:any)=>{
                if(field.name==="chatbot_text"){
                    field.fields=field.fields.map((ffield:any)=>{
                        if(ffield.name==="chatbot_edit_icon"){
                            ffield.visible=false;
                        }
                        return ffield;
                    })
                }
                if(field.name==="chatbot_details_snippet"){
                    field.fields=field.fields.map((ffield:any)=>{
                        if(ffield.name==="chatbot_name"){
                            ffield.fields=ffield.fields.map((fffield:any)=>{
                                if(fffield.name==="name_of_the_chatbot"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="chatbot_name_value"){
                                            ffffield.visible=false;
                                        }
                                        if(ffffield.name==="chatbot_name_text"){
                                            ffffield.required=true;
                                        }
                                        if(ffffield.name==="chatbot_name_value_textfield"){
                                            ffffield.visible=true;
                                        }
                                        return ffffield;
                                    })
                                }
                                if(fffield.name==="chatbot_logo_upload"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="chatbot_preview_image"){
                                            ffffield.fields=ffffield.fields.map((fffffield:any)=>{
                                                if(fffffield.name==="chatbot_design_image_preview"){
                                                    fffffield.visible=false;
                                                }
                                                if(fffffield.name==="chatbot_design_image_upload_preview"){
                                                    fffffield.visible=true;
                                                }
                                                return fffffield;
                                            })
                                        }
                                        return ffffield;
                                    })
                                }
                                return fffield;
                            })
                        }
                        
                        return ffield;
                    })
                }
                if(field.name==="chatbot_buttons"){
                    field.visible=true;
                }
                return field;
            })
            return {...prev}
        })
    }

    const handleChatBotCancelButton=()=>{
        setChatBotSchema((prev:any)=>{
            prev.fields=prev.fields.map((field:any)=>{
                if(field.name==="chatbot_text"){
                    field.fields=field.fields.map((ffield:any)=>{
                        if(ffield.name==="chatbot_edit_icon"){
                            ffield.visible=true;
                        }
                        return ffield;
                    })
                }
                if(field.name==="chatbot_details_snippet"){
                    field.fields=field.fields.map((ffield:any)=>{
                        if(ffield.name==="chatbot_name"){
                            ffield.fields=ffield.fields.map((fffield:any)=>{
                                if(fffield.name==="name_of_the_chatbot"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="chatbot_name_value"){
                                            ffffield.visible=true;
                                        }
                                        if(ffffield.name==="chatbot_name_text"){
                                            ffffield.required=false;
                                        }
                                        if(ffffield.name==="chatbot_name_value_textfield"){
                                            ffffield.visible=false;
                                            ffffield.value=orgDetails?.chatbot_name
                                        }
                                        return ffffield;
                                    })
                                }
                                if(fffield.name==="chatbot_logo_upload"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="chatbot_preview_image"){
                                            ffffield.fields=ffffield.fields.map((fffffield:any)=>{
                                                if(fffffield.name==="chatbot_design_image_preview"){
                                                    fffffield.visible=true;
                                                    fffffield.img=`${BASE_URL}/api/${orgDetails?.chatbot_icon}`
                                                }
                                                if(fffffield.name==="chatbot_design_image_upload_preview"){
                                                    fffffield.visible=false;
                                                }
                                                return fffffield;
                                            })
                                        }
                                        return ffffield;
                                    })
                                }
                                return fffield;
                            })
                        }
                        return ffield;
                    })
                }
                if(field.name==="chatbot_buttons"){
                    field.visible=false;
                }
                return field;
            })
            return {...prev}
        })
        setChatbotName(orgDetails?.chatbot_name)
        setChatbotLogo(orgDetails?.chatbot_icon)
    }

    const handleChangeTabs=(value:any)=>{
        setSchema((prev:any)=>{
            const updatedSchema=prev.schema.map((field:any)=>{
                if(field.name==="schema_form"){
                    field.fields=[value==="contact_url"? {...contactFormUrl} : {...chatBotSchema} ]
                }
                return field;
            })
            return {...prev,schema:updatedSchema}
        })
    }

    const handleSelectedRecord=(e:any)=>{
        if(e.name==="chatbot_edit_icon"){
            handleChatbotSchema();
        }
        if(e.name==="chatbot_cancel_button"){
            setDetectChange(false)
            handleChatBotCancelButton()
        }
        if(e.name==="chatbot_name_value_textfield"){
            setChatbotName(e.value)
        }
        if(e.name==="chatbot_save_changes"){
            setDetectChange(false)
            API_PUT_OrganizationDetails("chatbot_save_changes")
        }
        if (e.name === "chatbot_copy_button") {
            
            const snippetField = chatBotSchema.fields.find((field: { name: string; }) => field.name === "chatbot_details_snippet")
                ?.fields.find((field: { name: string; }) => field.name === "chatbot_snippet_code")
                ?.fields.find((field: { name: string; }) => field.name === "chatbot-snippet-code_text");
        
            if (snippetField) {
                const text = snippetField.label; 
                navigator.clipboard.writeText(text);
                toast.success("Script is copied.",{position:"top-center",autoClose:500})
            }
        }
        if(e.name==="contact_form_url_copy_button"){
            const snippetField = contactFormUrl.fields.find((field: { name: string; }) => field.name === "contact_form_url_snippet_code")
                ?.fields.find((field: { name: string; }) => field.name === "contact_form_url_snippet_code_text")
        
            if (snippetField) {
                const text = snippetField.label; 
                navigator.clipboard.writeText(text);
                toast.success("Contact Form URL is copied.",{position:"top-center",autoClose:500})
            }
        }
        if(e.name==="tabs"){
            handleChangeTabs(e?.value?.value)
        }
        if(e.name==="chatbot_design_image_upload_preview"){
            setChatbotUploadLogo(e.value)
        }
    }
  return (
    <div>
        {loading && <LoadingState/>}
      <ElementExecutor
        data={schema}
        setData={(e: any) => {
          setSchema({ ...e });
        }}
        selectedRecord={(e: any) => {
          handleSelectedRecord(e);
          if(["chatbot_name_value_textfield"].includes(e?.name)){
            setDetectChange(true) 
        }
        }}
        />
    </div>
  )
}

export default Widget;
