import { ElementExecutor } from '@apexcura/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Utils from '../../utils';
import { CONSTANTS } from '../../utils/app-constants';
import { toast } from 'react-toastify';
import LoadingState from '../LoadingState';
import { Tooltip,Popconfirm } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE, BASE_URL } = CONSTANTS;

const PressNotes = () => {

    const [schema, setSchema] = useState<any>({
        className:
          "w-full bg-white rounded-lg py-[10px] px-[20px] flex flex-col gap-3",
        schema: [
          {
            name: "filters",
            fields: [
              {
                name:"date_and_search",
                className:"flex gap-2 justify-center items-center ",
                fields:[
                    {
                        name: 'search',
                        label: '',
                        placeholder: 'Name, mobile..',
                        element: 'input-text',
                        isSearch:true,
                        icon:"search",
                        value: null,
                        iconsClassName:"w-[15px] flex justify-center items-center"
                    },
                    {
                      name:"segments",
                      element:"segmented",
                      segmentedOptions:["Today","Previous Day","Last Week"],
                      visible:true,
                      value:"Last Week"
                  },
                    {
                        name: 'date',
                        label: '',
                        placeholder: 'Select a date',
                        element: 'daterangepicker',
                        value: [
                            moment().subtract(7, 'days').format('DD-MM-YYYY'),
                            moment().format('DD-MM-YYYY'),
                        ],
                        className: 'flex-1 w-[240px]',
                    },
                    
                ]
              },
              {
                name: "btn_add",
                label: "Add",
                element: "button",
                icon: "add",
                className: "add-button",
                iconsClassName: "size-4",
              },
            ],
            className: "flex justify-between items-center gap-2",
          },
          {
            className: "mt-2",
            name: "table",
            element: "table",
            variant: "plain",
            size: "small",
            count: 0,
            visible: true,
            loading: true,
            pagination: true,
            thead: [
              { name: "title", label: "Title", key: "title" },
              { name: "authorname", label: "Author", key: "authorname" },
              { name: "submittedon", label: "Submitted On", key: "submittedon" },
              {
                name: "actions",
                label: "",
                key: "actions",
                sortable:false,
                render: (e: any, record: any) => {
                  return (
                    <div className='flex gap-4 justify-center items-center'>
                      <div
                        onKeyDown={() =>
                          handleSelectedRecord({ name: "edit", value: record })
                        }
                        tabIndex={0}
                        role="button"
                        onClick={() =>
                          handleSelectedRecord({ name: "edit", value: record })
                        }
                      >
                         <Tooltip title="Edit">
                          <img
                            src={
                              `${BASE_URL}/api/public/assets/images/edit.svg`
                            }
                            alt="Edit the record"
                            className='w-4 h-4'
                          />
                        </Tooltip>
                      </div>
                      <div
                      // onKeyDown={() =>
                      //   handleSelectedRecord({ name: "delete", value: record })
                      // }
                      tabIndex={0}
                      role="button"
                      // onClick={() =>
                      //   handleSelectedRecord({ name: "delete", value: record })
                      // }
                    >
                      <Popconfirm 
                          title="Are you sure you want to delete this pressNote?" 
                          onConfirm={()=> handleSelectedRecord({name: 'delete',value: record})}
                          okButtonProps={{
                            style: { backgroundColor: '#FF0000' }
                          }}
                          icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
                        >
                        <img
                          src={
                            `${BASE_URL}/api/public/assets/images/discard-red.svg`
                          }
                          alt="Delete the record"
                          className="w-5 h-5"
                        />
                      </Popconfirm>
                      </div>
                    </div>
                  );
                },
              },
              
            ],
            tbody: [
            ],
            modalContent: {
              className: "",
              fields: [],
            },
          },
        ],
    });
    const navigate=useNavigate();
    const [dateRange, setDateRange] = useState<[string, string]>([
      moment().subtract(7, 'days').format('DD-MM-YYYY'),
      moment().format('DD-MM-YYYY'),
    ])
    const [loading,setLoadng]=useState(false);

    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        const id = setTimeout(() => {
            API_GET_Data({
                startDate: dateRange[0],
                endDate: dateRange[1],
                searctext: searchText,
            })
        }, 300)

        return () => {
            clearTimeout(id)
        }
    }, [searchText])

    const API_GET_Data = async ({
      page = 1,
      limit = 10,
      startDate = '',
      endDate = '',
      searctext = '',
  } = {}) => {
    setSchema((prev: any) => Utils.handleLoading(prev, true))
      try {
          const res = await Utils.makeApiCall(
              `/pressNote/getAllPressNotes?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&search=${searctext}`,
              'GET'
          )

          if (res.status) 
          {
              setSchema((prev: any) => {
                  const ps = prev.schema
                  ps.map((s: any) => {
                      if (s.element === 'table') {
                          s.count = res.total
                          s['tbody'] = res.data.map((acc: any) => {
                              const submittedon = acc['submittedon']
                                  ? moment(acc['submittedon']).format(
                                      'DD-MM-YYYY'
                                  )
                                  : '';
                              return {
                                  ...acc,
                                  submittedon,
                              
                              }
                          })
                          s['loading'] = false
                      }
                      return s
                  })
                  return { ...prev, schema: ps }
              })
          } 
          else {
              toast.error(res.message ? res.message : FAILURE_MSG, {
                  position: 'top-center',
                  autoClose: AUTO_CLOSE,
              })
          }
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, {
              position: 'top-center',
              autoClose: AUTO_CLOSE,
          })
          console.error('Failed to fetch schema', error)
      }

      setSchema((prev: any) => Utils.handleLoading(prev, false))
    }

    const API_DELETE_Data=async (e:any)=>{
      setSchema((prev: any) => Utils.handleLoading(prev, true))
      try{
        const res:any =await Utils.makeApiCall(`/pressNote?pressNoteId=${e.value._id}`,"DELETE");
        if(res.status){
          setSearchText("")
          API_GET_Data({startDate:"",endDate:"",searctext:""})
          toast.success(res.message , {
            position: 'top-center',
            autoClose: AUTO_CLOSE,
        })
        }else {
              toast.error(res.message ? res.message : FAILURE_MSG, {
                  position: 'top-center',
                  autoClose: AUTO_CLOSE,
              })
          }

      }
      catch (error) {
        toast.error(CATCH_ERROR_MSG, {
            position: 'top-center',
            autoClose: AUTO_CLOSE,
        })
        console.error('Failed to delete.', error)
      }finally{
        setSchema((prev: any) => Utils.handleLoading(prev, false))
      }
    }

    const handleSelectedRecord=(e:any)=>{
        if(e.name === "delete")
        {
         API_DELETE_Data(e)
        }
        if(e.name === "btn_add"){
            navigate("/pressNotes/add")
        }
        if (e.name === "edit") {
          navigate(`/pressNotes/edit?pressNoteId=${e.value._id}`);
      }      
        if(e.name === "search"){
          setSearchText(e.value)
        }
        if (e.name === 'date') {
          if (e.value === '') {
              setDateRange(['', ''])
              setSchema((prevState: any) => {
                  const updatedSchema = prevState.schema.map((s: any) => {
                      if (s.element === 'table') {
                          s.value = {
                              name: 'pagination',
                              page: 1,
                              pageSize: 10,
                          }
                      }
                      return s
                  })
                  return { ...prevState, schema: updatedSchema }
              })
              API_GET_Data({
                  startDate: '',
                  endDate: '',
                  searctext: searchText,
              })
          } else {
              setDateRange(e.value)
              API_GET_Data({
                  startDate: e.value[0],
                  endDate: e.value[1],
                  searctext: searchText,
              })
          }
      }
      if (e.name === 'table') {
        if (e.value?.name === 'pagination') {
            const { page, pageSize } = e.value
            API_GET_Data({
                page: page,
                limit: pageSize,
                startDate: dateRange[0],
                endDate: dateRange[1],
                searctext: searchText,
            })
        }
    }
    if (e.name === "segments") {
      if (e.value === "Today") {
        setDateRange([
          moment().format("DD-MM-YYYY"),
          moment().format("DD-MM-YYYY"),
        ]);
        API_GET_Data({
          startDate: moment().format("DD-MM-YYYY"),
          endDate: moment().format("DD-MM-YYYY"),
          searctext: searchText,
        });
        setSchema((prev: any) => {
          const updatedSchema = prev.schema.map((field: any) => {
            if (field.name === "filters") {
              field.fields = field.fields.map((ffield: any) => {
                if (ffield.name === "date_and_search") {
                  ffield.fields = ffield.fields.map((ff: any) => {
                    if (ff.name === "date") {
                      ff.value = [
                        moment().format("DD-MM-YYYY"),
                        moment().format("DD-MM-YYYY"),
                      ];
                    }
                    return ff;
                  });
                }
                return ffield;
              });
            }
            if (field.name === "table") {
              field.value = { name: "pagination", page: 1, pageSize: 10 };
            }
            return field;
          });
          return { ...prev, schema: updatedSchema };
        });
      }
      if (e.value === "Previous Day") {
        setDateRange([
          moment().subtract(1, "days").format("DD-MM-YYYY"),
          moment().subtract(1, "days").format("DD-MM-YYYY"),
        ]);
        API_GET_Data({
          startDate: moment().subtract(1, "days").format("DD-MM-YYYY"),
          endDate: moment().subtract(1, "days").format("DD-MM-YYYY"),
          searctext: searchText,
        });
        setSchema((prev: any) => {
          const updatedSchema = prev.schema.map((field: any) => {
            if (field.name === "filters") {
              field.fields = field.fields.map((ffield: any) => {
                if (ffield.name === "date_and_search") {
                  ffield.fields = ffield.fields.map((ff: any) => {
                    if (ff.name === "date") {
                      ff.value = [
                        moment().subtract(1, "days").format("DD-MM-YYYY"),
                        moment().subtract(1, "days").format("DD-MM-YYYY"),
                      ];
                    }
                    return ff;
                  });
                }
                return ffield;
              });
            }
            if (field.name === "table") {
              field.value = { name: "pagination", page: 1, pageSize: 10 };
            }
            return field;
          });
          return { ...prev, schema: updatedSchema };
        });
      }
      if (e.value === "Last Week") {
        setDateRange([
          moment().subtract(7, "days").format("DD-MM-YYYY"),
          moment().format("DD-MM-YYYY"),
        ]);
        API_GET_Data({
          startDate: moment().subtract(7, "days").format("DD-MM-YYYY"),
          endDate: moment().format("DD-MM-YYYY"),
          searctext: searchText,
        });
        setSchema((prev: any) => {
          const updatedSchema = prev.schema.map((field: any) => {
            if (field.name === "filters") {
              field.fields = field.fields.map((ffield: any) => {
                if (ffield.name === "date_and_search") {
                  ffield.fields = ffield.fields.map((ff: any) => {
                    if (ff.name === "date") {
                      ff.value = [
                        moment().subtract(7, "days").format("DD-MM-YYYY"),
                        moment().format("DD-MM-YYYY"),
                      ];
                    }
                    return ff;
                  });
                }
                return ffield;
              });
            }
            if (field.name === "table") {
              field.value = { name: "pagination", page: 1, pageSize: 10 };
            }
            return field;
          });
          return { ...prev, schema: updatedSchema };
        });
      }
    }
    }


  return (
    <div>
      {loading && <LoadingState/>}
      <ElementExecutor
        data={schema}
        selectedRecord={handleSelectedRecord}
      />
    </div>
  )
}

export default PressNotes
