import { ElementExecutor } from '@apexcura/core';
import React, { useEffect, useState } from 'react';
import { unstable_usePrompt, useNavigate} from 'react-router-dom';
// import { Prompt } from 'react-router';
import Utils from '../../utils';
import { CONSTANTS } from '../../utils/app-constants';
import { toast } from 'react-toastify';
import moment from 'moment';
import LoadingState from '../LoadingState';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../redux/rootReducer';
import { AppDispatch } from '../../redux/store';
import useExitPopUp from '../../hooks/useExitPopUp';
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;

const addBlogs = ()=>( {
    className: "w-full bg-white rounded-lg p-8 flex flex-col gap-3 items-center",
    schema: [
      {
        name: "upload_fields",
        className: "w-full flex flex-col gap-2",
        fields: [
          {
            name: "name_and_type",
            className: "grid grid-cols-3  gap-2",
            fields: [
              {
                name: "title",
                element: "input-text",
                placeholder: "",
                label: "Title",
                containerClassName: "w-full flex flex-col",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                required: true,
                visible: true,
              },
              {
                name: "author_name",
                element: "input-text",
                placeholder: "",
                label: "Author Name",
                containerClassName: "w-full flex flex-col",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                required: true,
                visible: true,
              },
              {
                name: "submitted_o",
                element: "datepicker",
                placeholder: "",
                label: "Submitted On",
                containerClassName: "w-full flex flex-col",
                labelClassName:
                "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md w-full px-3 py-1 text-gray-500 flex  tyext-sm gap-1 font-semibold focus:outline-none",
                required: true,
                visible: true,
                  
              },
            ],
          },
          {
            className:"flex flex-col gap-2 w-full",
            name:"editor",
            fields:[
              {
                element:"div",
                name:"Content",
                label:"Content",
                className:"text-gray-500 font-semibold text-sm ",
                visible:false
              },
              {
                name: "content",
                element: "input-textarea",
                label:"Content",
                labelClassName:"text-gray-500 font-semibold text-sm ",
                minRows:6,
                maxRows:6,
                className:
                  "w-full rounded-md mb-4  text-gray-700 font-semibold focus:outline-none",
                visible: true,
                required: true,
              },
            ]
          },
          {
            name: "meta_keys",
            className: "grid grid-cols-2  gap-2",
            fields: [
              {
                name: "meta_description",
                element: "input-text",
                placeholder: "",
                label: "Meta Description",
                containerClassName: "w-full  col-span-2 flex flex-col",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                required: true,
                visible: true,
              },
              {
                name: "meta_keywords",
                element: "input-text",
                placeholder: "seperate keywords with comma(,)",
                label: "Meta Keywords",
                containerClassName: "w-full flex flex-col",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                required: true,
                visible: true,
              },
              {
                name: "banner_alt_text",
                element: "input-text",
                placeholder: "",
                label: "Alt text for banner Image",
                containerClassName: "w-full flex flex-col",
                labelClassName:
                "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md w-full px-3 py-1 text-gray-500 flex  tyext-sm gap-1 font-semibold focus:outline-none",
                required: true,
                visible: true,
                  
              },
            ],
          },
          {
            className:"flex flex-col gap-2 w-full",
            name:"upload",
            fields:[
              {
                element:"div",
                name:"upload",
                label:"Upload",
                className:"text-gray-500 font-semibold text-sm "
              },
              {
                name: "files",
                element: "upload",
                label: "Upload",
                accept: "image/*",
                multiple: true,
                className: "",
                required: true,
                visible: true,
                value: null,
              }
            ]
          },
        ],
      },
      {
        name: "cancel_and_submit",
        fields: [
          {
            name: "cancel",
            label: "Cancel",
            element: "button",
            className:
              "p-2 px-3  text-default-500 bg-gray-200 text-black rounded-md font-semibold",
          },
          {
            name: "submit",
            label: "Submit",
            element: "button",
            className:
              "p-2 px-3  text-default-500 bg-primary  rounded-md text-white font-semibold",
          },
        ],
        className: "flex self-end gap-2",
      },
    ],
});

const AddBlogs = () => {
    const [schema, setSchema] = useState<any>({
      ...addBlogs()
    });
    const navigate=useNavigate();
    const [loading,setLoadng]=useState(false)

    const validateData = (fields: any[]): string | false => {
      for (const field of fields) {
        if (
          field.required &&
          ((typeof field.value === "string" && field.value.trim() === "") ||
            (Array.isArray(field.value) && field.value.length === 0) ||
            field.value === null ||
            field.value === undefined)
        ) {
          return field.label;
        }
        if (field.fields) {
          const result = validateData(field.fields);
          if (result) {
            return result;
          }
        }
      }
      return false;
    };

    const getPayload = (e: any): FormData => {

      const formdata = new FormData();

      const title = e?.schema[0]?.fields[0]?.fields[0]?.value;
      const authorname = e?.schema[0]?.fields[0]?.fields[1]?.value;
      const submittedon=e?.schema[0]?.fields[0]?.fields[2]?.value
      const content = e?.schema[0]?.fields[1]?.fields[1]?.value;
      const metaDescription = e?.schema[0]?.fields[2]?.fields[0]?.value;

      const metaKeywords = e?.schema[0]?.fields[2]?.fields[1]?.value; 

      const imageAltText = e?.schema[0]?.fields[2]?.fields[2]?.value;
      const thumbnail = e?.schema[0]?.fields[3]?.fields[1]?.value;
      let filelinks = [];
      if (thumbnail && thumbnail.length > 0) {
        filelinks = thumbnail.map((f: any) => f.originFileObj);
      }
  
      formdata.append("title", title);
      formdata.append("authorname", authorname);
      formdata.append("submittedon", submittedon);
      formdata.append("content",content)
      formdata.append("metaDescription",metaDescription)
      formdata.append("metaKeywords",metaKeywords)
      formdata.append("imageAltText",imageAltText)
      if (filelinks.length > 0) {
        filelinks.forEach((file: File, i: any) => {
          formdata.append(`thumbnail`, file);
        });
      }
      return formdata;
    };

    const API_POST_Data=async ()=>{
      setLoadng(true)
      const payLoad: FormData=getPayload(schema);
      try{
        const res:any=await Utils.makeFormdataApiCall("/blog","POST",payLoad);
        
        if(res.status){
          toast.error(res.message,{position:"top-center",autoClose:AUTO_CLOSE})
          navigate("/blogs")
        }else{
          toast.error(res.message ? res.message : FAILURE_MSG,{position:"top-center",autoClose:AUTO_CLOSE})
        }
      }
      catch(error){
        toast.error(CATCH_ERROR_MSG,{position:"top-center",autoClose:AUTO_CLOSE})
        console.log("Error while sending the data.",error)
      }finally{
        setLoadng(false)
      }

    }

    const handleSelectedRecord=(e:any)=>{
      console.log(e)
        if(e.name === "cancel"){
          navigate("/blogs")
        }
        if (e.name === "submit"){
          setDetectChange(false)
          const validationResponse = validateData(schema.schema);
          if (validationResponse) {
              toast.error(`${validationResponse} cannot be empty`, {
                position: "top-center",
                autoClose: AUTO_CLOSE,
              });
          }
          else{
            API_POST_Data()
          }
        }
    }



    const [detectChange, setDetectChange] = useState(false)

    // Exit popup's custom hook
    useExitPopUp(detectChange)



   

  

  return (
    <div>
      {loading && <LoadingState/>}
      <ElementExecutor
        data={schema}
        setData={(e: any) => {
          setSchema({ ...e });
        }}
        selectedRecord={(e: any) => {
          handleSelectedRecord(e);
          if(["title", "author_name", "submitted_o", "content", "meta_description", "meta_keywords", "banner_alt_text", "files"].includes(e?.name) ){
            setDetectChange(true)
          }
        }}
      />
    </div>
  )
}

export default AddBlogs
