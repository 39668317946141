"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableElement = void 0;
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
const Button_1 = require("./Button");
const SwitchElement_1 = require("./SwitchElement");
const icons_1 = require("@ant-design/icons");
const TableElement = (props) => {
    const { thead, tbody } = props;
    const [dataSource, setDataSource] = (0, react_1.useState)([]);
    const [pagination, setPagination] = (0, react_1.useState)({
        page: props.value?.page || 1,
        pageSize: props.value?.pageSize || 10,
    });
    (0, react_1.useEffect)(() => {
        if (props.value && props.value.name === 'pagination') {
            setPagination({
                page: props.value.page || 1,
                pageSize: props.value?.pageSize || 10,
            });
        }
    }, [props.value]);
    (0, react_1.useEffect)(() => {
        if (tbody) {
            setDataSource(tbody.map((row, index) => {
                const pagnationIdx = ((pagination.page || 1) - 1) * (pagination.pageSize || 10);
                return {
                    ...row,
                    key: pagnationIdx + index + 1,
                    index: pagnationIdx + index + 1,
                    children: row.children && row.children.length > 0
                        ? row.children
                        : undefined,
                };
            }));
        }
    }, [tbody, pagination]);
    const handleDelete = (record) => {
        props.onChange && props.onChange({ name: record.key, value: record });
    };
    const handleSwitch = (record) => {
        const newDataSource = dataSource.map((item) => {
            if (item.id === record.id) {
                const updatedRecord = { ...item, value: !item.value };
                props.onChange &&
                    props.onChange({ name: record.key, value: updatedRecord });
                return updatedRecord;
            }
            return item;
        });
        setDataSource(newDataSource);
    };
    const handleTableChange = (_pagination, newFilters, _sorter, extra) => {
        void _sorter;
        if (extra.action === 'paginate') {
            setPagination({
                page: _pagination.current ?? 1,
                pageSize: _pagination.pageSize ?? 10,
            });
            if (props.onChange) {
                props.onChange({
                    name: 'pagination',
                    page: _pagination.current,
                    pageSize: _pagination.pageSize,
                });
            }
        }
        else if (extra.action === 'filter') {
            const updatedFilters = {
                dialCallStatus: newFilters.DialCallStatus,
                status: newFilters.status,
            };
            if (props.onChange) {
                console.log(props.value);
                setPagination({
                    page: 1,
                    pageSize: _pagination.pageSize ?? 10,
                });
                props.onChange({ name: 'filters', value: updatedFilters });
                // props.onChange({
                //   name: 'pagination',
                //   page: 1,
                //   pageSize: _pagination.pageSize,
                // });
            }
        }
    };
    let columns = [];
    if (thead) {
        columns = [
            ...(props.showSerialNumber || props.showSerialNumber === undefined
                ? [
                    {
                        title: '#',
                        dataIndex: 'index',
                        key: 'index',
                        // fixed: 'left',
                    },
                ]
                : []),
            ...thead.map((col) => ({
                title: col.label,
                dataIndex: col.name,
                key: col.key,
                ellipsis: col.ellipsis,
                render: col.render,
                filters: col.filters?.map((filter) => ({
                    text: filter.text,
                    value: filter.value,
                })),
                filterMultiple: true,
                sorter: col.sortable ||
                    (col.sortable === undefined && {
                        compare: (a, b) => {
                            const aValue = a[col.key];
                            const bValue = b[col.key];
                            if (typeof aValue === 'number' && typeof bValue === 'number') {
                                return aValue - bValue;
                            }
                            else if (typeof aValue === 'string' &&
                                typeof bValue === 'string') {
                                return aValue.localeCompare(bValue);
                            }
                            else {
                                return 0;
                            }
                        },
                    }),
            })),
        ];
        if (props.isDelete) {
            columns?.push({
                title: 'Delete',
                dataIndex: 'Delete',
                key: 'Delete',
                render: (_, record) => {
                    return (react_1.default.createElement(antd_1.Popconfirm, { title: "Delete the task", description: "Are you sure to delete this task?", icon: react_1.default.createElement(icons_1.QuestionCircleOutlined, { style: { color: 'red' } }), onConfirm: () => handleDelete(record), okText: "Delete", cancelText: "Cancel" },
                        react_1.default.createElement(antd_1.Tooltip, { title: "Delete" },
                            react_1.default.createElement("p", null, "Del"),
                            react_1.default.createElement(Button_1.ButtonElement, { icon: "discard" }))));
                },
            });
        }
        if (props.isStatus) {
            columns?.splice(-1, 0, {
                title: 'Status',
                dataIndex: 'Status',
                key: 'Status',
                render: (_, record) => {
                    return (react_1.default.createElement(SwitchElement_1.SwitchElement, { value: record.value, onChange: () => {
                            handleSwitch(record);
                        } }));
                },
            });
        }
    }
    // const onChangePage = (page: number, pageSize: number) => {
    //   setPagination({ page, pageSize });
    //   if (props.onChange) {
    //     props.onChange({ name: 'pagination', page, pageSize });
    //   }
    // };
    const rowSelectionConfig = props.rowSelection
        ? {
            onChange: (selectedRows) => {
                if (props.onChange) {
                    props.onChange(selectedRows);
                }
            },
        }
        : undefined;
    // console.log(filters);
    const handleRowClick = (record) => {
        if (props.onChange) {
            props.onChange({ name: 'view', value: { ...record } }); // Pass the global index
        }
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(antd_1.Table, { style: { userSelect: 'none' }, loading: props.loading, className: props.className, 
            // scroll={{ y: 500 }}
            pagination: props.pagination
                ? {
                    current: pagination.page,
                    pageSize: pagination.pageSize,
                    showTotal: (total) => `Total: ${total} items`,
                    total: props.count,
                    showSizeChanger: props.count ? props.count > 10 : 0 > 10,
                    // onChange: () => {
                    //   clickedKeyRef.current = 'pagination';
                    // },
                    // onChange: onChangePage,
                }
                : false, rowSelection: rowSelectionConfig, dataSource: dataSource, columns: columns, size: props.size, bordered: true, expandable: {
                rowExpandable: (record) => !!(record.children && record.children.length > 0),
            }, onChange: handleTableChange, onRow: props.rowClick
                ? (record) => ({
                    onClick: () => handleRowClick(record),
                })
                : undefined, rowClassName: () => (props.rowClick ? 'cursor-pointer' : '') })));
};
exports.TableElement = TableElement;
