import React, { useEffect, useState } from 'react'
import { Tooltip } from "antd";
import moment from 'moment';
import { ElementExecutor } from '@apexcura/core'
import { useNavigate } from 'react-router-dom';
import Utils from '../utils';
import { toast } from 'react-toastify';
import LoadingState from './LoadingState';
import { CONSTANTS } from '../utils/app-constants';
import Storage from '../utils/local-storage';
import { useDispatch, useSelector } from 'react-redux';
import { setBranches, setModules } from '../redux/compareUnits/compareUnitsSlice';
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;

const CompareUnits = () => {
    const [schema, setSchema] = useState<any>({
        className:
          'w-full bg-white rounded-lg py-[10px] px-[20px] flex flex-col gap-3',
        schema: [
        ]
    });
    const [dateRange, setDateRange] = useState<[string, string]>([
      moment().subtract(7, 'days').format('DD-MM-YYYY'),
      moment().format('DD-MM-YYYY'),
    ])
    const [loading,setLoading]=useState(false)
    const navigate = useNavigate()
    const dispatch=useDispatch();
    const units:any=useSelector((store:any)=>store.compareUnits)

    useEffect(() => {
        const schemaArr = [
            {
              name: 'filters',
              fields: [
                {
                  name:"date_and_search",
                  className:"flex gap-2 justify-center items-center ",
                  fields:[
                    {
                        name:"segments",
                        element:"segmented",
                        segmentedOptions:[
                          {
                              label:(<Tooltip title="Today">1D</Tooltip>),
                              value:"TD"
                          },
                          // {
                          //     label:(<Tooltip title="Yesterday">YD</Tooltip>),
                          //     value:"YD"
                          // },
                          {
                              label:(<Tooltip title="This Week">1W</Tooltip>),
                              value:"TW"
                          },
                          {
                              label:(<Tooltip title="This Month">1M</Tooltip>),
                              value:"TM"
                          },
                        ],
                        visible:true,
                        value:"TW"
                    },
                    {
                      name: 'date',
                      label: '',
                      placeholder: 'Select a date',
                      element: 'daterangepicker',
                      value: [moment().subtract(7, 'days').format('DD-MM-YYYY'), moment().format("DD-MM-YYYY")],
                      className: 'flex-1 w-[240px]',
                    },
                    
                  ]
                },
                {
                  name:"refresh_and_download",
                  className:"flex gap-2 justify-center items-center ",
                  fields:[
                      {
                          name: "dashboard",
                          label: "Dashboard",
                          element: "button",
                          visible: false,
                          className:
                            "p-2 px-3 text-default-500 bg-[#3341bb] rounded-md text-white font-semibold self-end",
                      },
                  ]
                },
                
              ],
              className: 'flex items-center justify-between gap-2',
            },
            {
              name:"modules_and_branches",
              className:"w-full grid grid-cols-2 gap-4",
              fields:[
                  {
                      name: "branches",
                      element: "single-select",
                      placeholder: "",
                      label: "Branch(es)",
                      containerClassName: "w-full flex flex-col col-span-1",
                      labelClassName:
                        "text-sm mb-1 text-gray-500 font-semibold text-start",
                      className:
                        "rounded-md  text-gray-700 font-semibold focus:outline-none",
                      required: true,
                      visible: true,
                      mode: "multiple",
                      value:[...units.branches],
                      endPoint: "/getUserBranches",
                      options:[
                        
                      ]
                  },
                  {
                      name: "modules",
                      element: "single-select",
                      placeholder: "",
                      label: "Module(s)",
                      containerClassName: "w-full flex flex-col col-span-1",
                      labelClassName:
                        "text-sm mb-1 text-gray-500 font-semibold text-start",
                      className:
                        "rounded-md  text-gray-700 font-semibold focus:outline-none",
                      required: true,
                      visible: true,
                      mode: "multiple",
                      value:[...units.modules],
                      options:[
                        {
                            label: "Patients",
                            value: "patients"
                        },
                        {
                            label: "Leads",
                            value: "leads"
                        },
                        {
                            label: "Campaigns",
                            value: "campaigns"
                        },
                        {
                          label: "Users",
                          value: "users"
                      },
                    ]
                  },
              ]
            },
            {
                className: 'mt-2',
                name: 'table',
                element: 'table',
                variant: 'plain',
                size: 'small',
                count: 0,
                view: true,
                showSerialNumber: false,
                visible: true,
                loading: false,
                pagination: false,
                value: { name: 'pagination', page: 1, pageSize: 10 },
                thead:  [
                ],
                tbody: [
                ]
            },
        ] 

        const processFields = (obj: any) => {
          if (Array.isArray(obj)) {
            obj.forEach(processFields);
          } else if (obj && typeof obj === 'object') {
            if (obj.endPoint || obj.firstEndpoint) {
              setLoading(true);
              API_GET_Options({ name: obj.name, url: obj.endPoint || obj.firstEndpoint });
            }
            Object.values(obj).forEach(processFields);
          }
        };

        schemaArr.forEach(processFields);

        setSchema((prev: any) => {
          return { ...prev, schema: schemaArr }
        })
    }, [])
  

    const API_GET_Options = async ({ name = "", url = "" }) => {

        try {
        const res = await Utils.makeApiCall(url, "GET");
        if (res.status) {
            setSchema((prev: any) => {
            const updateFields: any = (obj: any) => {
                if (Array.isArray(obj)) return obj.map(updateFields);
                if (obj && typeof obj === "object") {
                if (obj.name === name) {
                    let options = [];
                    if (name === "branches" || name==="modules") {
                    options=res.data.map((op: any) => ({ value: op.branch_id, label: op.branch_name }));
                    }
                    if ( name==="modules") {
                      options=res.data;
                      }
                    obj.options = options;
                }
                Object.values(obj).map(updateFields);
                }
            };
    
            updateFields(prev.schema);
            return { ...prev };
            });
        } else {
            toast.error(res.message, { position: "top-center", autoClose: 2000 });
        }
        } catch (error) {
          console.error("Failed to fetch schema", error);
        }
    
        setLoading(false);
    };

    useEffect(() => {
      if(dateRange[0]==="" && dateRange[1]===""){
          change_segments_value(null);
      }else{
        const first = moment(dateRange[0], 'DD-MM-YYYY');
        const second = moment(dateRange[1], 'DD-MM-YYYY');
        const diffInDays = second.diff(first, 'days');
        const today = moment().startOf('day');
  
        if (first.isSame(today, 'day') && second.isSame(today, 'day')) {
            change_segments_value("TD")
        } else if (diffInDays === 7 && second.isSame(today, 'day')) {
            change_segments_value("TW")
        } else if (diffInDays === 30 && second.isSame(today, 'day')) {
            change_segments_value("TM")
        }else{
            change_segments_value(null)
        }
  
      }
      

      const branches:string[]=units.branches.map((item:{label:string,value:string})=>item?.value);
      const modules:string[]=units.modules.map((item:{label:string,value:string})=>item?.value)
      if(branches.length>0 || modules.length>0){
        API_GET_Table_Data(modules,branches,dateRange[0],dateRange[1]);
      }
          
    }, [units.branches, units.modules,dateRange]);

    const change_table_data=(data:any)=>{
      setSchema((prev:any)=>{
        const updatedSchema=prev.schema.map((field:any)=>{
          if(field.name==="table"){
            field.thead=[
              {
                  name: "module",
                  label: "Module",
                  sortable:false,
                  render:(e:any,record:any)=>{
                    return (
                      <p className={`${record.isParent? "capitalize":"pl-4"}`}>{e}</p>
                    )
                  }
              },
              ...data.thead
            ]
            field.tbody=data.data
          }
          return field;
        })
        return {...prev,schema:updatedSchema}
      })
    }

    const change_segments_value=(val:string | null)=>{
      setSchema((prev:any)=>{
          const updatedSchema=prev.schema.map((field:any)=>{
              if(field.name==="filters"){
                field.fields=field.fields.map((ffield:any)=>{
                  if(ffield.name==="date_and_search"){
                    ffield.fields=ffield.fields.map((fffield:any)=>{
                      if(fffield.name==="segments"){
                        fffield.value=val
                      }
                      return fffield;
                    })
                  }
                  return ffield;
                })
              }
              return field;
          })
          return {...prev,schema:updatedSchema}
      })
    }

    const API_GET_Table_Data = async (modules: string[], branches: string[],startDate:string,endDate:string) => {
      setLoading(true)
      const payLoad: any = {
          "modules": modules,
          "branches": branches
      };
      try {
          const res: any = await Utils.makeApiCall(`/compareUnits?startDate=${startDate}&endDate=${endDate}`, "POST", payLoad);
          if (res.status) {
              change_table_data(res);
          } else {
              toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
          }
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
          console.error('Failed to fetch schema', error);
      }
      setLoading(false)
    }

    const handleSelectedRecord=(e:any)=>{
        if(e.name==="dashboard"){
            navigate("/dashboard")
        }
        if(e.name==="branches"){
          dispatch(setBranches(e.value))
        }
        if(e.name==="modules"){
          dispatch(setModules(e.value))
        }
        if (e.name === 'date') {
          if (e.value === '') {
              setDateRange(['', ''])
          } else {
              setDateRange(e.value)
          }
        }
        if (e.name === "segments") {
          if (e.value === "TD") {
              setDateRange([
                  moment().format('DD-MM-YYYY'),
                  moment().format('DD-MM-YYYY'),
              ]);
              setSchema((prev: any) => {
                  const updatedSchema = prev.schema.map((field: any) => {
                      if (field.name === "filters") {
                          field.fields = field.fields.map((ffield: any) => {
                              if (ffield.name === "date_and_search") {
                                  ffield.fields = ffield.fields.map((ff: any) => {
                                      if (ff.name === "date") {
                                          ff.value = [
                                              moment().format('DD-MM-YYYY'),
                                              moment().format('DD-MM-YYYY'),
                                          ];
                                      }
                                      return ff;
                                  });
                              }
                              return ffield;
                          });
                      }
                      if(field.element==="table"){
                        field.value={
                            name: 'pagination',
                            page: 1,
                            pageSize: 10,
                        }
                    }
                      return field;
                  });
                  return { ...prev, schema: updatedSchema };
              });
          }
          if (e.value === "TM") {
              setDateRange([
                  moment().subtract(30, 'days').format('DD-MM-YYYY'),
                  moment().format('DD-MM-YYYY'),
              ]);
              setSchema((prev: any) => {
                  const updatedSchema = prev.schema.map((field: any) => {
                      if (field.name === "filters") {
                          field.fields = field.fields.map((ffield: any) => {
                              if (ffield.name === "date_and_search") {
                                  ffield.fields = ffield.fields.map((ff: any) => {
                                      if (ff.name === "date") {
                                          ff.value = [
                                              moment().subtract(30, 'days').format('DD-MM-YYYY'),
                                              moment().format('DD-MM-YYYY'),
                                          ];
                                      }
                                      return ff;
                                  });
                              }
                              return ffield;
                          });
                      }
                      if(field.element==="table"){
                        field.value={
                            name: 'pagination',
                            page: 1,
                            pageSize: 10,
                        }
                    }
                      return field;
                  });
                  return { ...prev, schema: updatedSchema };
              });
          }
          if (e.value === "TW") {
              setDateRange([
                  moment().subtract(7, 'days').format('DD-MM-YYYY'),
                  moment().format('DD-MM-YYYY'),
              ]);
              setSchema((prev: any) => {
                  const updatedSchema = prev.schema.map((field: any) => {
                      if (field.name === "filters") {
                          field.fields = field.fields.map((ffield: any) => {
                              if (ffield.name === "date_and_search") {
                                  ffield.fields = ffield.fields.map((ff: any) => {
                                      if (ff.name === "date") {
                                          ff.value = [
                                              moment().subtract(7, 'days').format('DD-MM-YYYY'),
                                              moment().format('DD-MM-YYYY'),
                                          ];
                                      }
                                      return ff;
                                  });
                              }
                              return ffield;
                          });
                      }
                      if(field.element==="table"){
                        field.value={
                            name: 'pagination',
                            page: 1,
                            pageSize: 10,
                        }
                    }
                      return field;
                  });
                  return { ...prev, schema: updatedSchema };
              });
          }
        }
    }

  return (
    <div>
        {loading && <LoadingState/>}
      <ElementExecutor
        data={schema}
        selectedRecord={(e: any) => {
          handleSelectedRecord(e);
        }}
      />
    </div>
  )
}

export default CompareUnits
