import { ElementExecutor } from '@apexcura/core'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Utils from '../../utils'
import { CONSTANTS } from '../../utils/app-constants'
import { toast } from 'react-toastify'
import moment from 'moment'
import LoadingState from '../LoadingState'
import useExitPopUp from '../../hooks/useExitPopUp'
import Storage from '../../utils/local-storage'
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS

const addReleaseNotes = () => ({
    className:
        'w-full bg-white rounded-lg p-8 flex flex-col gap-3 items-center',
    schema: [
        {
            name: "heading",
            element: "div",
            className: "text-2xl font-semibold self-start",
            label: "Add Release Notes"
        },
        {
            name: 'form_fields',
            className: 'w-full grid grid-cols-2 gap-2',
            fields: [
                {
                    name: 'title',
                    element: 'input-text',
                    placeholder: '',
                    label: 'Title',
                    containerClassName: 'w-full flex flex-col col-span-2',
                    labelClassName:
                        'text-sm mb-1 text-gray-500 font-semibold text-start',
                    className:
                        'rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none',
                    required: true,
                    visible: true,
                },
                {
                    name: 'version',
                    element: 'input-text',
                    placeholder: '',
                    label: 'Version',
                    containerClassName: 'w-full flex flex-col',
                    labelClassName:
                        'text-sm mb-1 text-gray-500 font-semibold text-start',
                    className:
                        'rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none',
                    required: true,
                    visible: true,
                },
                {
                    name: 'released_on',
                    element: 'datepicker',
                    placeholder: '',
                    label: 'Released On',
                    containerClassName: 'w-full flex flex-col',
                    labelClassName:
                        'text-sm mb-1 text-gray-500 font-semibold text-start',
                    className:
                        'rounded-md w-full px-3 py-1 text-gray-500 flex  tyext-sm gap-1 font-semibold focus:outline-none',
                    required: true,
                    visible: true,
                },
                {
                    name: 'content',
                    element: 'input-textarea',
                    label: 'Release Notes',
                    labelClassName:
                        'text-gray-500 font-semibold text-sm ',
                    minRows: 8,
                    maxRows: 8,
                    containerClassName: "col-span-2",
                    className:
                        'w-full rounded-md mb-4  text-gray-700 font-semibold focus:outline-none',
                    visible: true,
                    required: true,
                },
            ],
        },
        {
            name: 'cancel_and_submit',
            fields: [
                {
                    name: 'cancel',
                    label: 'Cancel',
                    element: 'button',
                    className:
                        'p-2 px-3  text-default-500 bg-gray-200 text-black rounded-md font-semibold',
                },
                {
                    name: 'submit',
                    label: 'Submit',
                    element: 'button',
                    className:
                        'p-2 px-3  text-default-500 bg-primary  rounded-md text-white font-semibold',
                },
            ],
            className: 'flex self-end gap-2',
        },
    ],
})



const AddReleaseNotes = () => {
    const [schema, setSchema] = useState<any>({
        ...addReleaseNotes(),
    })
    const navigate = useNavigate()
    const [loading, setLoadng] = useState(false)


    useEffect(()=>{
        setSchema((prev: any)=>{

            prev?.schema?.map((f: any)=>{
                if(f.name === "form_fields"){
                    f?.fields?.map((ff: any)=>{
                        if(ff.name === "version"){
                            ff.placeholder = Storage.getItem('currentVersion') || ""
                        }
                        if(ff.name === "released_on"){
                            
                            ff.value = moment().format('DD-MM-YYYY')
                            
                        }
                    })
                }
            })

            return {...prev}
        })
    }, [])

    const validateData = (fields: any[]): string | false => {
        for (const field of fields) {
            if (
                field.required &&
                ((typeof field.value === 'string' &&
                    field.value.trim() === '') ||
                    (Array.isArray(field.value) && field.value.length === 0) ||
                    field.value === null ||
                    field.value === undefined)
            ) {
                return field.label
            }
            if (field.fields) {
                const result = validateData(field.fields)
                if (result) {
                    return result
                }
            }
        }
        return false
    }

    const getPayload = (e: any):object => {

        const  payload={
            title:"",
            version:"",
            releasedon:"",
            content:"",
        }

        e?.schema?.map((f: any)=>{
                if(f.name === "form_fields"){
                    f?.fields?.map((ff: any) => {
                        if(ff.name === "title"){
                            payload.title = ff.value
                        }
                        if(ff.name === "version"){
                            payload.version = ff.value
                        }
                        if(ff.name === "released_on"){
                            payload.releasedon = ff.value
                        }
                        if(ff.name === "content"){
                            payload.content = ff.value
                        }
                    })
                }
        })
        return payload
    }

    const API_POST_Data = async () => {
        setLoadng(true)
        const payLoad = getPayload(schema)

        try {
            const res: any = await Utils.makeFormdataApiCall(
                '/releaseNotes',
                'POST',
                payLoad
            )

            if (res.status) {
                navigate('/release-notes')
            } else {
                toast.error(res.message ? res.message : FAILURE_MSG, {
                    position: 'top-center',
                    autoClose: AUTO_CLOSE,
                })
            }
        } catch (error) {
            toast.error(CATCH_ERROR_MSG, {
                position: 'top-center',
                autoClose: AUTO_CLOSE,
            })
        } finally {
            setLoadng(false)
        }
    }

    const handleSelectedRecord = (e: any) => {
        if (e.name === 'cancel') {
            navigate('/release-notes')
        }
        if (e.name === 'submit') {
            setDetectChange(false)
            API_POST_Data()
            //   const validationResponse = validateData(schema.schema);
            //   if (validationResponse) {
            //       toast.error(`${validationResponse} cannot be empty`, {
            //         position: "top-center",
            //         autoClose: AUTO_CLOSE,
            //       });
            //   }
            //   else{
            //     API_POST_Data()
            //   }
        }
    }

    const [detectChange, setDetectChange] = useState(false)

    // Exit popup's custom hook
    useExitPopUp(detectChange)

    return (
        <div>
            {loading && <LoadingState />}
            <ElementExecutor
                data={schema}
                setData={(e: any) => {
                    setSchema({ ...e })
                }}
                selectedRecord={(e: any) => {
                    handleSelectedRecord(e)
                    if (
                        ['title', 'version', 'released_on', 'content'].includes(
                            e?.name
                        )
                    ) {
                        setDetectChange(true)
                    }
                }}
            />
        </div>
    )
}

export default AddReleaseNotes
