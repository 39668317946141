import React, { useState } from 'react'
import {Avatar,Tooltip} from "antd";
import moment from 'moment';
import { ElementExecutor } from '@apexcura/core';

const LeadsReport = () => {

    const [schema] = useState<any>({
        className:
          'w-full bg-white rounded-lg py-[10px] px-[20px] flex flex-col gap-3 divide-y',
        schema: [
          {
            name: 'filters',
            fields: [
              {
                name:"date_and_search",
                className:"flex gap-2 justify-center items-center ",
                fields:[
                  {
                      name:"segments",
                      element:"segmented",
                      segmentedOptions:[
                        {
                            label:(<Tooltip title="Today">1D</Tooltip>),
                            value:"TD"
                        },
                        // {
                        //     label:(<Tooltip title="Yesterday">YD</Tooltip>),
                        //     value:"YD"
                        // },
                        {
                            label:(<Tooltip title="This Week">1W</Tooltip>),
                            value:"TW"
                        },
                        {
                            label:(<Tooltip title="This Month">1M</Tooltip>),
                            value:"TM"
                        },
                      ],
                      visible:true,
                      value:"TW"
                  },
                  {
                    name: 'date',
                    label: '',
                    placeholder: 'Select a date',
                    element: 'daterangepicker',
                    value: [moment().subtract(7, 'days').format('DD-MM-YYYY'), moment().format("DD-MM-YYYY")],
                    className: 'flex-1 w-[240px]',
                  },
                  
                ]
              },
              
            ],
            className: 'flex items-center justify-between gap-2',
          },
          {
            name:"effeciency_table",
            className:"flex gap-2 flex-col   rounded-md",
            fields:[
                {
                    name:"effeciency",
                    label:"Efficiency",
                    element:"div",
                    className:"text-primary mt-2 font-semibold text-lg w-full"
                },
                {
                    className: 'bg-white',
                    name: "effeciency_table",
                    element: 'table',
                    loading: false,
                    variant: 'plain',
                    count:0,
                    size: 'small',
                    pagination: true, 
                    view: true,
                    visible: true,
                    thead: [
                        {
                            name:"agent", label:"Agent",key:"agent"
                        },
                      { name: 'total', label: 'Total Calls', key: 'total',
                      },
                      {
                        name: 'answered',
                        label: 'Answered ',
                        key: 'answered',
                      },
                      {name: 'missed', label: 'Missed ', key: 'missed',},
                      { name: 'return_calls', label: 'Returned ', key: 'return_calls'},
                      { name: 'effeciency', label: 'Effeciency', key: 'effeciency',},
                      {
                        name: 'min_duration',
                        label: 'Min Duration',
                        key: 'min_duration',
                      },
                      {
                        name: 'max_duration',
                        label: 'Max Duration',
                        key: 'max_duration',
                      },
                      {
                        name: 'avg_duration',
                        label: 'Avg Duration',
                        key: 'avg_duration',
                      }
                    ],
                    tbody: [
                        {
                            agent:"9701281777",
                            total:"100",
                            missed:"40",
                            answered:"60",
                            return_calls:"20",
                            effeciency:"60%",
                            min_duration:"10sec",
                            max_duration:"2min",
                            avg_duration:"1min 5sec"

                        },
                        {
                            agent:"9700092134",
                            total:"200",
                            missed:"80",
                            answered:"120",
                            return_calls:"30",
                            effeciency:"60%",
                            min_duration:"2min",
                            max_duration:"5min",
                            avg_duration:"3min 30sec"

                        },
                        {
                            agent:"6789067890",
                            total:"100",
                            missed:"40",
                            answered:"60",
                            return_calls:"20",
                            effeciency:"60%",
                            min_duration:"1min",
                            max_duration:"2min",
                            avg_duration:"1min"

                        },
                        {
                            agent:"9078690876",
                            total:"100",
                            missed:"40",
                            answered:"60",
                            return_calls:"20",
                            effeciency:"60%",
                            min_duration:"15sec",
                            max_duration:"2min 5sec",
                            avg_duration:"1min 10 sec"

                        }
                    ],
                },
            ]
          },
          {
            name:"compliance_table",
            className:"flex gap-2 flex-col  rounded-md p-2",
            fields:[
                {
                    name:"compliance",
                    label:"Compliance",
                    element:"div",
                    className:"text-primary mt-2 font-semibold text-lg w-full "
                },
                {
                    className: 'bg-white',
                    name: "compliance_table",
                    element: 'table',
                    loading: false,
                    variant: 'plain',
                    count:0,
                    size: 'small',
                    pagination: true, 
                    view: true,
                    visible: true,
                    thead: [
                        {
                            name:"agent", label:"Agent",key:"agent"
                        },
                      {
                        name: 'answered',
                        label: 'Answered ',
                        key: 'answered',
                      },
                      { name: 'effeciency', label: 'Effeciency', key: 'effeciency',},
                      {
                        name: 'avg_quality_score',
                        label: 'Avg Quality Score',
                        key: 'avg_quality_score',
                      },
                      {
                        name: 'avg_sentiment_score',
                        label: 'Avg Sentiment Score',
                        key: 'avg_sentiment_score',
                      }
                    ],
                    tbody: [
                        {
                            agent:"9701281777",
                            total:"100",
                            missed:"40",
                            answered:"60",
                            return_calls:"20",
                            effeciency:"60%",
                            min_duration:"10sec",
                            max_duration:"2min",
                            avg_duration:"1min 5sec",
                            avg_quality_score:"55",
                            avg_sentiment_score:"-0.564"

                        },
                        {
                            agent:"9700092134",
                            total:"200",
                            missed:"80",
                            answered:"120",
                            return_calls:"30",
                            effeciency:"60%",
                            min_duration:"2min",
                            max_duration:"5min",
                            avg_duration:"3min 30sec",
                            avg_quality_score:"34",
                            avg_sentiment_score:"-1"

                        },
                        {
                            agent:"6789067890",
                            total:"100",
                            missed:"40",
                            answered:"60",
                            return_calls:"20",
                            effeciency:"60%",
                            min_duration:"1min",
                            max_duration:"2min",
                            avg_duration:"1min",
                            avg_quality_score:"120",
                            avg_sentiment_score:"0.567"

                        },
                        {
                            agent:"9078690876",
                            total:"100",
                            missed:"40",
                            answered:"60",
                            return_calls:"20",
                            effeciency:"60%",
                            min_duration:"15sec",
                            max_duration:"2min 5sec",
                            avg_duration:"1min 10 sec",
                            avg_quality_score:"55",
                            avg_sentiment_score:"1"

                        }
                    ],
                },
            ]
          },
          {
            name:"outcomes_table",
            className:"flex gap-2 flex-col rounded-md",
            fields:[
                {
                    name:"outcomes",
                    label:"Outcomes",
                    element:"div",
                    className:"text-primary mt-2 font-semibold text-lg w-full "
                },
                {
                    className: '',
                    name: "outcomes_table",
                    element: 'table',
                    loading: false,
                    variant: 'plain',
                    count:0,
                    size: 'small',
                    pagination: true, 
                    view: true,
                    visible: false,
                    thead: [
                      { name: 'name', label: 'Name', key: 'name',
                      },
                      {
                        name: 'phone_number',
                        label: 'Mobile',
                        key: 'phone_number',
                      },
                      {name: 'type', label: 'Type', key: 'type', render: (e:any) => <p className={`capitalize`}>{e}</p>},
                      { name: 'source', label: 'Source', key: 'source', render: (e:any) => <p className={`capitalize`}>{e}</p> },
                      { name: 'status', label: 'Status', key: 'status',},
                      {
                        name: 'createdAt',
                        label: 'Arrived on',
                        key: 'createdAt',
                      }
                    ],
                    tbody: [],
                    modalContent: {
                      className: "flex flex-col gap-2 overflow-auto max-h-[400px] p-[10px]",
                      fields: [],
                    },
                },
                {
                    name:"outcomes",
                    fields: [
                      {
                        name:"outcomes",
                        label:"Coming Soon",
                        element:"div",
                        className:"font-semibold text-gray-400"
                      }
                    ],
                    className:"w-full h-40 bg-gray-50 rounded-lg flex items-center justify-center"
                }
            ]
          }
        ],
    });

  return (
    <div>
      <ElementExecutor
        data={schema}
      />
    </div>
  )
}

export default LeadsReport
