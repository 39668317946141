import { ElementExecutor } from '@apexcura/core';
import React, { useEffect, useState } from 'react';
import { unstable_usePrompt, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CONSTANTS } from '../../utils/app-constants';
import Utils from '../../utils';
import LoadingState from '../LoadingState';
import Storage from '../../utils/local-storage';
import useExitPopUp from '../../hooks/useExitPopUp';

const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;

const addPatients = () => ({
    className: "w-full bg-white rounded-lg p-8 flex flex-col gap-3 items-center",
    schema: [
      {
        name: "data_fields",
        className: "w-full flex flex-col gap-2",
        fields: [
          {
            name: "name_and_type",
            className: "grid grid-cols-2 flex gap-2",
            fields: [
              {
                name: "name",
                element: "input-text",
                placeholder: "",
                label: "Name",
                containerClassName: "w-full flex flex-col",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                required: true,
                visible: true,
              },
              {
                name: "mobile",
                element: "input-text",
                placeholder: "",
                label: "Mobile",
                containerClassName: "w-full flex flex-col",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                required: true,
                visible: true,
              },
              {
                name: "gender",
                element: "single-select",
                placeholder: "",
                label: "Gender",
                containerClassName: "w-full flex flex-col gap-1",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md text-gray-500 font-semibold text-sm ",
                required: true,
                visible: true,
                options: [
                    {
                        label: "Male",
                        value: "male"
                    },
                    {
                        label: "Female",
                        value: "female"
                    },
                    {
                        label: "Others",
                        value: "others"
                    }
                ]
              },
              {
                  name: "dob",
                  element: "datepicker",
                  placeholder: "",
                  label: "Date of Birth",
                  containerClassName: "w-full flex flex-col gap-1",
                  labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                  className:
                    "rounded-md w-full px-3 py-1 text-gray-500 flex tyext-sm gap-1 font-semibold focus:outline-none",
                  required: true,
                  visible: true,
              }
            ],
          },
        ],
      },
      {
        name: "cancel_and_submit",
        fields: [
          {
            name: "cancel",
            label: "Cancel",
            element: "button",
            className:
              "p-2 px-3 text-default-500 bg-gray-200 text-black rounded-md font-semibold",
          },
          {
            name: "submit",
            label: "Submit",
            element: "button",
            className:
              "p-2 px-3 text-default-500 bg-[#3341bb] rounded-md text-white font-semibold",
          },
        ],
        className: "flex self-end gap-2",
      },
    ],
});

const AddPatients = () => {
    const [schema, setSchema] = useState<any>({
      ...addPatients()
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const org_id = Storage.getItem("details")?.["org_id"];

    // useEffect(()=>{
    //   const schema = addPatients();
    //   setSchema((prev:any)=> ({...prev, ...schema}))
    // }, [])

    const validateData = (fields: any[]): string | false => {
        for (const field of fields) {
            if (
                field.required &&
                ((typeof field.value === "string" && field.value.trim() === "") ||
                    (Array.isArray(field.value) && field.value.length === 0) ||
                    field.value === null ||
                    field.value === undefined)
            ) {
                return field.label;
            }
            if (field.fields) {
                const result = validateData(field.fields);
                if (result) {
                    return result;
                }
            }
        }
        return false;
    };

    const API_POST_Data = async () => {
        setLoading(true);
        const payLoad = getPayload(schema);
        try {
            const res: any = await Utils.makeApiCall("/patient", "POST", payLoad);
            if (res.status) {
                toast.success(res.message, { position: "top-center", autoClose: AUTO_CLOSE });
                navigate("/patients");
            } else {
                toast.error(res.message ? res.message : FAILURE_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
            }
        } catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
            console.error("Error while sending the data.", error);
        } finally {
            setLoading(false);
        }
    };

    const getPayload = (e: any): object => {
        const payload = {
            name: "",
            phno: "",
            gender: "",
            date_of_birth: "",
            org_id: org_id
        };
        payload.name = e?.schema[0]?.fields[0]?.fields[0]?.value;
        payload.phno = e?.schema[0]?.fields[0]?.fields[1]?.value;
        payload.gender = e?.schema[0]?.fields[0]?.fields[2]?.value?.value;
        payload.date_of_birth = e?.schema[0]?.fields[0]?.fields[3]?.value;

        return payload;
    };

    const handleSelectedRecord = (e: any) => {
        if (e.name === "cancel") {
            navigate("/patients");
        }
        if (e.name === "submit") {
          setDetectChange(false)
            const validationResponse = validateData(schema.schema);
            if (validationResponse) {
                toast.error(`${validationResponse} cannot be empty`, {
                    position: "top-center",
                    autoClose: AUTO_CLOSE,
                });
            } else {
                API_POST_Data();
            }
        }
    };

    const [detectChange, setDetectChange] = useState(false)

    // Exit popup's custom hook
    useExitPopUp(detectChange)

    return (
        <div>
            {loading && <LoadingState />}
            <ElementExecutor
                data={schema}
                setData={(e: any) => {
                    setSchema({ ...e });
                }}
                selectedRecord={(e: any) => {
                    handleSelectedRecord(e);
                    if(["name", "mobile", "gender", "dob"].includes(e?.name) ){
                      setDetectChange(true)
                    }
                }}
            />
        </div>
    );
};

export default AddPatients;
