import { ElementExecutor } from '@apexcura/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Utils from '../../utils';
import { CONSTANTS } from '../../utils/app-constants';
import { toast } from 'react-toastify';
import LoadingState from '../LoadingState';
import { Tooltip,Popconfirm, Switch } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Storage from '../../utils/local-storage';
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;
const { BASE_URL } = CONSTANTS;
const Branches = () => {

  const org_id = Storage.getItem("details")?.["org_id"];

  const [statusModified, setStatusModified] = useState<number>(0)

  const [schema, setSchema] = useState<any>({
    className:
      "w-full bg-white rounded-lg py-[10px] px-[20px] flex flex-col gap-3",
    schema: [
      {
        name: "filters",
        fields: [
          // {
          //   name:"date_and_search",
          //   className:"flex gap-2 justify-center items-center ",
          //   fields:[
          //       {
          //           name: 'search',
          //           label: '',
          //           placeholder: 'Search by name, mobile..',
          //           element: 'input-text',
          //           value: null,
          //           className: '',
          //       },

          //   ]
          // },
          {
            name: "btn_add",
            label: "Add",
            element: "button",
            icon: "add",
            className: "add-button",
            iconsClassName: "size-4",
          },
        ],
        className: "flex justify-end items-center gap-2",
      },
      {
        name: "branch_data_modal",
        className: "",
        containerClassName: "",
        width: 700,
        element: "modal",
        visible: false,
        fields: [
            {
                name: "branch_data_modal_head",
                className: "flex justify-between items-center p-4 bg-[#f2f2f2]",
                fields: [
                    {
                        name: "modal_title",
                        label: "Branch Details",
                        element: "div",
                        className: "text-[#3341BB] font-medium text-lg",
                    },
                    {
                        name: "close_modal",
                        element: "button",
                        label: "X",
                        className:
                            "!w-[35px] !h-[35px] rounded-full bg-primary text-xl text-white flex justify-center items-center",
                    },
                ]
            },
            {
                name: "branch_data_modal_body",
                className: "w-full border-1 grid grid-cols-12 gap-y-3 overflow-x-scroll rounded-md   p-6",
                fields: [
                  {
                    name: "branch_name",
                    label: "",
                    className: "flex gap-1 col-span-4",
                    fields: [
                      {
                        name: "branch_name_label",
                        label: "Branch Name: ",
                        element: "div",
                        className: "text-gray-800 font-medium text-sm",
                      },
                      {
                        name: "branch_name_value",
                        label: "N/A",
                        element: "div",
                        className: "text-gray-600 text-sm",
                      },
                    ]
                  },
                  {
                    name: "phone_number",
                    label: "",
                    className: "flex gap-1 col-span-4",
                    fields: [
                      {
                        name: "phone_number_label",
                        label: "Phone Number: ",
                        element: "div",
                        className: "text-gray-800 font-medium text-sm",
                      },
                      {
                        name: "phone_number_value",
                        label: "N/A",
                        element: "div",
                        className: "text-gray-600 text-sm",
                      },
                    ]
                  },
                  {
                    name: "email",
                    label: "",
                    className: "flex gap-1 col-span-4",
                    fields: [
                      {
                        name: "email_label",
                        label: "Email: ",
                        element: "div",
                        className: "text-gray-800 font-medium text-sm",
                      },
                      {
                        name: "email_value",
                        label: "Kondapur",
                        element: "div",
                        className: "text-gray-600 text-sm",
                      },
                    ]
                  },
                  {
                    name: "address",
                    label: "",
                    className: "flex gap-1 col-span-4",
                    fields: [
                      {
                        name: "address_label",
                        label: "Address: ",
                        element: "div",
                        className: "text-gray-800 font-medium text-sm",
                      },
                      {
                        name: "address_value",
                        label: "N/A",
                        element: "div",
                        className: "text-gray-600 text-sm",
                      },
                    ]
                  },
                  {
                    name: "city",
                    label: "",
                    className: "flex gap-1 col-span-4",
                    fields: [
                      {
                        name: "city_label",
                        label: "City: ",
                        element: "div",
                        className: "text-gray-800 font-medium text-sm",
                      },
                      {
                        name: "city_value",
                        label: "N/A",
                        element: "div",
                        className: "text-gray-600 text-sm",
                      },
                    ]
                  },
                  {
                    name: "zipcode",
                    label: "",
                    className: "flex gap-1 col-span-4",
                    fields: [
                      {
                        name: "zipcode_label",
                        label: "Zip Code: ",
                        element: "div",
                        className: "text-gray-800 font-medium text-sm",
                      },
                      {
                        name: "zipcode_value",
                        label: "N/A",
                        element: "div",
                        className: "text-gray-600 text-sm",
                      },
                    ]
                  },
                  {
                    name: "state",
                    label: "",
                    className: "flex gap-1 col-span-4",
                    fields: [
                      {
                        name: "state_label",
                        label: "State: ",
                        element: "div",
                        className: "text-gray-800 font-medium text-sm",
                      },
                      {
                        name: "state_value",
                        label: "N/A",
                        element: "div",
                        className: "text-gray-600 text-sm",
                      },
                    ]
                  },
                  {
                    name: "country",
                    label: "",
                    className: "flex gap-1 col-span-4",
                    fields: [
                      {
                        name: "country_label",
                        label: "Country: ",
                        element: "div",
                        className: "text-gray-800 font-medium text-sm",
                      },
                      {
                        name: "country_value",
                        label: "N/A",
                        element: "div",
                        className: "text-gray-600 text-sm",
                      },
                    ]
                  },
                ]
                
            }

        ]

      },
      {
        className: "mt-2",
        name: "table",
        element: "table",
        variant: "plain",
        size: "small",
        count: 0,
        visible: true,
        loading: true,
        pagination: true,
        thead: [
          { name: "branch_name", label: "Branch Name", key: "branch_name" , render: (e: any, record: any) => record?.branch_name || "N/A" },
          { name: "email", label: "Email", key: "email" , render: (e: any, record: any) => record?.email || "N/A"},
          { name: "phone_number", label: "Mobile", key: "phone_number" , render: (e: any, record: any) => record?.phone_number || "N/A" },
          { name: "address", label: "Address", key: "address" , render: (e: any, record: any) => record?.address || "N/A" },
          { name: "city", label: "City", key: "city" , render: (e: any, record: any) => record?.city || "N/A" },
          // { name: "zipcode", label: "Zipcode", key: "zipcode" , render: (e: any, record: any) => record?.zipcode || "N/A" },
          { name: "status", 
            label: "Active", 
            key: "status" , 
            render: (e: any, record: any) => {
              
              return(
                <Switch size="small" defaultChecked={record?.status ? true : false} onChange={()=>handleBranchStatus(record?.branch_id || "", record?.status ? false : true)}/>
              )
            }
          },
          {
            name: "actions",
            label: "",
            key: "actions",
            sortable:false,
            render: (e: any, record: any) => {
              return (
                <div className='flex gap-4 justify-center items-center'>

                  <div
                    onKeyDown={() =>
                      handleSelectedRecord({ name: "view", value: record })
                    }
                    tabIndex={0}
                    role="button"
                    onClick={() =>
                      handleSelectedRecord({ name: "view", value: record })
                    }
                  >
                    
                     <Tooltip title="view">
                      <img
                        src={
                          `${BASE_URL}/api/public/assets/images/eye.svg`
                        }
                        alt="view the record"
                        className='w-4 h-4'
                      />
                    </Tooltip>
                  </div>

                  <div
                    onKeyDown={() =>
                      handleSelectedRecord({ name: "edit", value: record })
                    }
                    tabIndex={0}
                    role="button"
                    onClick={() =>
                      handleSelectedRecord({ name: "edit", value: record })
                    }
                  >

                     <Tooltip title="Edit">
                      <img
                        src={
                          `${BASE_URL}/api/public/assets/images/edit.svg`
                        }
                        alt="Edit the record"
                        className='w-4 h-4'
                      />
                    </Tooltip>
                  </div>
                  
                </div>
              );
            },
          },
          
        ],
        tbody: [
        ],
        modalContent: {
          className: "",
          fields: [],
        },
      },
    ],
});
const navigate=useNavigate();
const [dateRange, setDateRange] = useState<[string, string]>([
  moment().subtract(7, 'days').format('DD-MM-YYYY'),
  moment().format('DD-MM-YYYY'),
])
const [loading,setLoadng]=useState(false);

const [searchText, setSearchText] = useState('');

useEffect(() => {
    const id = setTimeout(() => {
        API_GET_Data({
            startDate: dateRange[0],
            endDate: dateRange[1],
            searctext: searchText,
        })
    }, 300)

    return () => {
        clearTimeout(id)
    }
}, [searchText])

const API_GET_Data = async ({
  page = 1,
  limit = 10,
  startDate = '',
  endDate = '',
  searctext = '',
} = {}) => {
setSchema((prev: any) => Utils.handleLoading(prev, true))
  try {
      const res = await Utils.makeApiCall(
          `/branches?org_id=${org_id}&branch_id=`,
          'GET'
      )

      if (res.status) 
      {
        console.log(res);
        
          setSchema((prev: any) => {
              const ps = prev.schema
              ps.map((s: any) => {
                  if (s.element === 'table') {
                      s.count = res.total
                      s['tbody'] = res.data.map((acc: any) => {
                          const submittedon = acc['submittedon']
                              ? moment(acc['submittedon']).format(
                                  'DD-MM-YYYY'
                              )
                              : '';
                          return {
                              ...acc,
                              submittedon,
                          
                          }
                      })
                      s['loading'] = false
                  }
                  return s
              })
              return { ...prev, schema: ps }
          })
      } 
      else {
          toast.error(res.message ? res.message : FAILURE_MSG, {
              position: 'top-center',
              autoClose: AUTO_CLOSE,
          })
      }
  } catch (error) {
      toast.error(CATCH_ERROR_MSG, {
          position: 'top-center',
          autoClose: AUTO_CLOSE,
      })
      console.error('Failed to fetch schema', error)
  }

  setSchema((prev: any) => Utils.handleLoading(prev, false))
}


const handleSelectedRecord=(e:any)=>{

  if(e.name === "btn_add"){
        navigate("/branches/add")
  }
  if(e.name === "view"){
    handleModalData(e.value || {}, true)
    
  }
  if(e.name === "close_modal"){
    handleModalData({}, false)
  }
  if (e.name === "edit") {
      navigate(`/branches/edit/${e?.value?.org_id}/${e?.value?.branch_id}`);
  }      
  if(e.name === "search"){
      setSearchText(e.value)
    }
  // if (e.name === 'date') {
  //     if (e.value === '') {
  //         setDateRange(['', ''])
  //         setSchema((prevState: any) => {
  //             const updatedSchema = prevState.schema.map((s: any) => {
  //                 if (s.element === 'table') {
  //                     s.value = {
  //                         name: 'pagination',
  //                         page: 1,
  //                         pageSize: 10,
  //                     }
  //                 }
  //                 return s
  //             })
  //             return { ...prevState, schema: updatedSchema }
  //         })
  //         API_GET_Data({
  //             startDate: '',
  //             endDate: '',
  //             searctext: searchText,
  //         })
  //     } else {
  //         setDateRange(e.value)
  //         API_GET_Data({
  //             startDate: e.value[0],
  //             endDate: e.value[1],
  //             searctext: searchText,
  //         })
  //     }
  // }
  if (e.name === 'table') {
    if (e.value?.name === 'pagination') {
        const { page, pageSize } = e.value
        API_GET_Data({
            page: page,
            limit: pageSize,
            startDate: dateRange[0],
            endDate: dateRange[1],
            searctext: searchText,
        })
    }
}
}


const handleModalData = (data: any = {}, visible: boolean ) =>{
  setSchema((prev: any)=>{

    prev?.schema?.map((f: any)=>{
      if(f?.name === "branch_data_modal"){
        f.visible = visible

        f?.fields?.map((ff: any)=>{

          if(ff.name === "branch_data_modal_body"){

            ff?.fields?.map((fff: any)=>{
              fff?.fields?.map((ffff: any)=>{
                if(ffff.name === `${fff?.name}_value`){
                  ffff.label = data?.[`${fff?.name}`] || 'N/A'
                }
              })
            })
            
          }

        })
      }
    })

    return {...prev}
  })
}


const handleBranchStatus = async (branch_id: any, checked: any) =>{
  const status = checked ? "active" : "inactive"
  try{
    const res = await Utils.makeApiCall(
      `/branch/${branch_id}/status`,
      'PUT',
      {status: status}
    )
    if(res.status){
      console.log(res);
      toast.success(res.message,{position:"top-center",autoClose:AUTO_CLOSE})
      API_GET_Data();
      setStatusModified((val: number)=> val+1);
    }else{
      toast.error(res.message ? res.message : FAILURE_MSG,{position:"top-center",autoClose:AUTO_CLOSE})
    }
  }
  catch(err){
    toast.error(CATCH_ERROR_MSG, {
      position: 'top-center',
      autoClose: AUTO_CLOSE,
  })
    console.error('Failed to fetch schema', err)
  }

}

return (
<div>
  {loading && <LoadingState/>}
  <ElementExecutor
    data={schema}
    selectedRecord={handleSelectedRecord}
  />
</div>
)
}

export default Branches
