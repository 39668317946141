import { ElementExecutor } from '@apexcura/core';
import React, { useEffect, useState } from 'react'
import { unstable_usePrompt, useNavigate } from 'react-router-dom';
import LoadingState from '../LoadingState';
import Utils from '../../utils';
import { toast } from 'react-toastify';
import { CONSTANTS } from '../../utils/app-constants';
import Storage from '../../utils/local-storage';
import useExitPopUp from '../../hooks/useExitPopUp';
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;
const { BASE_URL } = CONSTANTS;
const whatsappSchema = ()=>({
    name: "whatsapp_schema",
    className: "p-6 flex flex-col gap-2 bg-[#e1e5f5]",
    fields: [
        // {
        //     name: "heading",
        //     label: "Update Whatsapp Template",
        //     element: "div",
        //     className: "w-full col-span-full flex text-lg font-semibold",
        // },
        {
            name: "message_template",
            className: "bg-white flex flex-col gap-4 w-[65%] p-4 rounded-lg",
            fields: [
                {
                    name: "message_template_1",
                    classsName:"flex flex-col gap-6 w-full",
                    fields:[
                      {
                        name: "message_template_text",
                        label: "Message Name and Language",
                        element: "div",
                        className: "font-semibold mb-3",
                      }, 
                      {
                        name:"message_template_fields",
                        className:"grid grid-cols-3 gap-2",
                        fields:[
                          {
                            name: "template_name",
                            element: "input-text",
                            placeholder: "",
                            disabled:true,
                            label: "Name Your Message",
                            containerClassName: " flex flex-col col-span-2",
                            labelClassName:
                              "text-sm mb-1 text-gray-500 font-semibold text-start",
                            className:
                              "rounded-md px-3 py-1  font-semibold focus:outline-none ",
                            required: true,
                            visible: true,
                          },
                          {
                            name: "template_language",
                            element: "single-select",
                            placeholder: "",
                            label: "Select Language",
                            containerClassName: " flex flex-col col-span-1",
                            labelClassName:
                              "text-sm mb-1 text-gray-500 font-semibold text-start",
                            className:
                              "rounded-md  text-gray-700 font-semibold focus:outline-none ",
                            required: true,
                            visible: true,
                            disabled: true,
                            options:[
                                {
                                    label:"English",
                                    value:"en_US",
                                },
                            ],
                            value:{
                              label:"English",
                              value:"en_US",
                            },
                          },
                        ]
                      }
                    ]
                },
                {
                  name: "campaigns_category",
                  className: "flex flex-col gap-2",
                  fields: [
                    {
                      name: "content_text",
                      label: "Campaign Category",
                      element: "div",
                      className: "font-semibold",
                    },
                    {
                      name: "campaign_category_type",
                      element: "single-select",
                      placeholder: "",
                      label: "Category",
                      containerClassName: " flex flex-col w-full",
                      labelClassName:
                        "text-sm mb-1 text-gray-500 font-semibold text-start",
                      className:
                        "rounded-md  text-gray-700 font-semibold focus:outline-none ",
                      required: false,
                      visible: true,
                      options:[
                          {
                              label:"MARKETING",
                              value:"MARKETING",
                          },
                          {
                            label:"UTILITY",
                            value:"UTILITY",
                          },
                          {
                            label:"AUTHENTICATION",
                            value:"AUTHENTICATION",
                          },
                      ],
                      value:{
                        // label:"MARKETING",
                        // value:"MARKETING",
                      },
                    },
                    
                  ]
                },
                {
                  name: "content",
                  classsName:"flex flex-col w-full",
                  fields:[
                    {
                      name: "content_text",
                      label: "Content",
                      element: "div",
                      className: "font-semibold",
                    },
                    {
                      name:"content_fields",
                      className:"flex flex-col gap-4",
                      fields:[
                        {
                          name:"content_header",
                          className:"w-full flex flex-col gap-2",
                          fields:[
                            {
                              name: "header_content_type",
                              element: "single-select",
                              placeholder: "",
                              label: "Header",
                              containerClassName: " flex flex-col w-full",
                              labelClassName:
                                "text-sm mb-1 text-gray-500 font-semibold text-start",
                              className:
                                "rounded-md  text-gray-700 font-semibold focus:outline-none ",
                              required: false,
                              visible: true,
                              options:[
                                  {
                                      label:"Text",
                                      value:"text",
                                  },
                              ],
                              value:{
                                label:"Text",
                                value:"text",
                              },
                            },
                            {
                              name: "header_content_name",
                              element: "input-text",
                              placeholder: "Enter Text In English",
                              label: "",
                              containerClassName: " flex flex-col w-full",
                              labelClassName:
                                "text-sm mb-1 text-gray-500 font-semibold text-start",
                              className:
                                "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none ",
                              required: false,
                              visible: true,
                            },
                            {
                              name: "btn_add_header",
                              label: "Add Variable",
                              element: "button",
                              className: "py-2 px-4 text-black  hover:bg-gray-200  text-xs rounded-md self-end ",
                              loading:false,
                              
                            },
                            {
                              name: "variable_header",
                              element: "input-text",
                              placeholder: "Enter Content for {{1}}",
                              label: "{{1}}",
                              containerClassName: " flex gap-2 items-center w-full",
                              labelClassName:
                                "text-sm mb-1 text-gray-500 font-semibold text-start",
                              className:
                                "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none ",
                              required: false,
                              visible: false,
                            },
                          ]
                        },
                        {
                          name:"content_body",
                          className:"w-full flex flex-col gap-2",
                          fields:[
                            {
                              name: "body_content_name",
                              element: "input-textarea",
                              placeholder: "Enter Text In English",
                              minRows:6,
                              maxRows:6,
                              label: "Body",
                              containerClassName: " flex flex-col w-full",
                              labelClassName:
                                "text-sm mb-1 text-gray-500 font-semibold text-start",
                              className:
                                "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none ",
                              required: true,
                              visible: true,
                            },
                            {
                              name: "btn_add_body",
                              label: "Add Variable",
                              element: "button",
                              icon: `${BASE_URL}/api/public/assets/images/add.svg`,
                              className: "py-2 px-4 text-black flex hover:bg-gray-200 items-center gap-2 text-xs rounded-md self-end ",
                              iconsClassName: "size-4 text-black",
                              isSVGStylesOverride: false,
                            },
                            
                          ]
                        },
                        {
                          name: "footer_content_name",
                          element: "input-text",
                          placeholder: "",
                          label: "Footer",
                          containerClassName: " flex flex-col w-full",
                          labelClassName:
                            "text-sm mb-1 text-gray-500 font-semibold text-start",
                          className:
                            "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none ",
                          required: false,
                          visible: true,
                        },
                      ]
                    }
                  ]
                },
                {
                  name:"buttons",
                  className:"flex felx-col",
                  visible:false,
                  fields:[
                    {
                      name: "buttons_text",
                      label: "Buttons",
                      element: "div",
                      className: "font-semibold",
                    },
                  ]
                },
                {
                    name: "cancel_and_submit",
                    fields: [
                      {
                        name: "cancel",
                        label: "Cancel",
                        element: "button",
                        className:
                          "p-2 px-3  text-default-500 bg-gray-200 text-black rounded-md font-semibold",
                      },
                      {
                        name: "submit",
                        label: "Submit",
                        element: "button",
                        className:
                          "p-2 px-3  text-default-500 bg-[#3341bb]  rounded-md text-white font-semibold",
                      },
                    ],
                    className: "flex self-end gap-2",
                },
            ],
        },
        {
          name: "preview_container",
          className: "flex flex-col w-[25%] fixed top-auto left-[71%] rounded-md bg-white radius-md drop-shadow-sm",
          fields: [
            {
              name: "preview_container",
              className: "",
              label: "",
              element: "div",
              fields: [
                {
                  name: "preview_title",
                  label: "Template Preview",
                  className: "bg-[#f1f4f7] text-md  p-2 rounded-t-md",
                  element: "div",
                },
                {
                  name: 'preview_body',
                  className: 'p-4 flex bg-[#ede6de] min-h-[30vh] max-h-[60vh] overflow-y-scroll rounded-md rounded-ss-none',
                  label: '',
                  fields: [
                    {
                      name: "arrow",
                      element: "div",
                      className: " right-0 w-0 h-0 border-t-[10px] border-t-white border-l-[10px] border-l-transparent"
                    },
                    {
                      name: 'preview_msg',
                      className: "bg-white w-fit h-fit  max-w-[80%] px-3 py-2 rounded-md  rounded-ss-none",
                      fields: [
                        {
                          name: "title",
                          label: "Title will be displayed here",
                          element: "div",
                          className: "text-md font-medium text-gray-800",
                        },
                        {
                          name: "body",
                          label: "Body Text",
                          element: "div",
                          className: "text-sm text-gray-600",
                        },
                        {
                          name: "footer",
                          label: "Footer text",
                          element: "div",
                          className: "text-xs text-gray-400",
                        },
                      ]
                    }
                  
                  ]
                },
                
              ]
            }
          ]
        }
    ],
});
const EditTemplate = () => {

    const [schema, setSchema] = useState<any>({
        className: "bg-white rounded-lg",
        schema: [
            {
                name: "tabs",
                element: "tabs",
                containerClassName: "p-0 m-0 px-4",
                options: [
                    {
                        key: "whatsapp",
                        label: "Update WhatsApp Template",
                    },
                ],
            },
            {
                name: "add_form",
                fields: [{...whatsappSchema()}],
            },
        ],
    });
    const [loading,setLoadng]=useState(false)
    const [variableCount, setVariableCount] = useState(0);
    const navigate = useNavigate();
    const org_id = Storage.getItem("details")?.["org_id"];

    const [title, setTitle] = useState('')
    const [headerText, setHeaderText] = useState("")
    const [headerVariable, setHeaderVariable] = useState("")
    const [bodyText, setBodyText] = useState("")
    const [bodyVariables, setBodyVariables] = useState<any>({})
    const [bodyTrigger, setBodyTrigger] = useState(0)
    const [footerText, setFooterText] = useState("")

    const tid = location.pathname.split('/')[3];    

    const GET_TEMPLATE_DATA = async() =>{
        console.log(tid);
        
        setSchema((prev: any) => Utils.handleTabsLoading(prev, true))
        
        try {
            const res = await Utils.makeApiCall(`/templates?id=${tid}`, "GET");
            if (res.status) {
                console.log(res?.data);

                const data = res?.data?.templates?.[0]

                setSchema((prev: any)=>{

                    const addForm = prev.schema.find((field: any) => field.name === "add_form");

                    if (addForm) {
                        const whatsappSchema = addForm.fields.find((subField: any) => subField.name === "whatsapp_schema");
                        if (whatsappSchema) {
                          const messageTemplate = whatsappSchema.fields.find((formField: any) => formField.name === "message_template");
                          if (messageTemplate) {

                            const categoryTemplate = messageTemplate.fields.find((field: any) => field.name === "campaigns_category")?.fields?.find((field: any)=> field.name === "campaign_category_type")
                            categoryTemplate.value = {label: `${data?.category}`, value: `${data?.category}`}

                            const messageTemplateFields = messageTemplate.fields.find((field: any) => field.name === "message_template_1")
                              ?.fields.find((field: any) => field.name === "message_template_fields");
                            if (messageTemplateFields) {
                              const temp_name = messageTemplateFields.fields.find((field: any) => field.name === "template_name");
                              temp_name.value = data?.name
                              const title = data?.name 
                                
                              setTitle(title?.replace(/_/g, ' ')?.split(' ')?.map((word: any) => word.charAt(0).toUpperCase() + word.slice(1)).join(' '))
                            }
                    
                            const content = messageTemplate.fields.find((field: any) => field.name === "content");
                            if (content) {
                              const contentFields = content.fields.find((field: any) => field.name === "content_fields");
                              if (contentFields) {

                                data?.components?.map((dd: any)=>{
                                    if(dd?.type === "HEADER"){
                                        const headerContent = contentFields.fields.find((field: any) => field.name === "content_header");
                                        if (headerContent) {
                                        const header_text = headerContent.fields.find((field: any) => field.name === "header_content_name")
                                        header_text.value = dd?.text
                                        setHeaderText(dd?.text)
                                        if(dd?.example?.header_text[0]?.length > 0){
                              
                                            headerContent?.fields?.map((field: any)=>{
                                                if(field.name === "variable_header"){
                                                    field.visible = true
                                                    field.value = dd?.example?.header_text[0]
                                                    setHeaderVariable(dd?.example?.header_text[0])
                                                }
                                                if(field.name === "btn_add_header"){
                                                    field.loading = true
                                                }
                                            })
                                        }   
                                        
                                        }
                                    }

                                    else if(dd?.type === "BODY"){

                                        const bodyContent = contentFields.fields.find((field: any) => field.name === "content_body");
                                        if (bodyContent) {
                                        const body_text = bodyContent.fields.find((field: any) => field.name === "body_content_name")
                                        body_text.value = dd?.text
                                        setBodyText(dd?.text)
                                            dd?.example?.body_text[0]?.map((variable: any, index: any)=>{
                                                if(!bodyContent?.fields?.find((field: any)=> field.name === `variable_${index + 1}`)){
                                                    setVariableCount((prev: any) => prev+1)
                                                    bodyContent.fields = [
                                                        ...bodyContent.fields,
                                                        {
                                                            name: `variable_${index + 1}`,
                                                            element: "input-text",
                                                            placeholder: `Enter Content for {{${index + 1}}}`,
                                                            label: `{{${index + 1}}}`,
                                                            containerClassName: "flex gap-2 items-center w-full",
                                                            labelClassName: "text-sm mb-1 text-gray-500 font-semibold text-start",
                                                            className: "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                                                            required: true,
                                                            visible: true,
                                                            value: variable || ""
                                                        }
                                                        
                                                    ]

                                                  }
                                                  
                                                  setBodyVariables((vars: any)=>{
                                                    vars[`${index + 1}`] = variable
                                                    return vars
                                                  })
                                            })
                                        }
                                    }
                                    else if(dd?.type === "FOOTER"){
                                        const footer_text = contentFields.fields.find((field: any) => field.name === "footer_content_name")
                                        footer_text.value = dd?.text
                                        setFooterText(dd?.text)
                                    }
                                })
                  
                              }
                            }
                          }
                        }
                      }
                    
                    

                    return {...prev}
                })
                
            } else {
              toast.error(res.message ? res.message : FAILURE_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
            }
            setSchema((prev: any) => Utils.handleTabsLoading(prev, false))
          } catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
            console.error("Failed to fetch schema", error);
          }

          
    }

    useEffect(()=>{
        GET_TEMPLATE_DATA()
    }, [])


    const validateData = (fields: any[]): string | false => {
        for (const field of fields) {
          if (
            field.required &&
            ((typeof field.value === "string" && field.value.trim() === "") ||
              (Array.isArray(field.value) && field.value.length === 0) ||
              field.value === null ||
              field.value === undefined)
          ) {
            return field.label;
          }
          if (field.fields) {
            const result = validateData(field.fields);
            if (result) {
              return result;
            }
          }
        }
        return false;
    };

    const API_POST_Data=async ()=>{
        setLoadng(true)
        const payLoad=getPayload(schema);
        try{
          const res:any=await Utils.makeApiCall(`/templates/${tid}`,"POST",payLoad);
          if(res.status){
            toast.success(res.message,{position:"top-center",autoClose:AUTO_CLOSE})
            navigate("/templates")
          }else{
            toast.error(res.message ? res.message : FAILURE_MSG,{position:"top-center",autoClose:AUTO_CLOSE})
          }
        }
        catch(error){
          toast.error(CATCH_ERROR_MSG,{position:"top-center",autoClose:AUTO_CLOSE})
          console.log("Error while sending the data.",error)
        }finally{
          setLoadng(false)
        }
  
    }
  
    const getPayload = (schema: any): object => {
      const payload:any = {
        name: "",
        language: "",
        category: "MARKETING",
        components: [
          {
            type: "HEADER",
            format: "TEXT",
            text: "",
            example: {
              header_text: []
            }
          },
          {
            type: "BODY",
            text: "",
            example: {
              body_text: [[]]
            }
          },
          {
            type: "FOOTER",
            text: ""
          }
        ]
      };
    
      const addForm = schema.schema.find((field: any) => field.name === "add_form");
      if (addForm) {
        const whatsappSchema = addForm.fields.find((subField: any) => subField.name === "whatsapp_schema");
        if (whatsappSchema) {
          const messageTemplate = whatsappSchema.fields.find((formField: any) => formField.name === "message_template");
          if (messageTemplate) {

            const categoryTemplate = messageTemplate.fields.find((field: any) => field.name === "campaigns_category")?.fields?.find((field: any)=> field.name === "campaign_category_type")
            if(categoryTemplate) {
              payload.category = categoryTemplate?.value?.value
            }

            const messageTemplateFields = messageTemplate.fields.find((field: any) => field.name === "message_template_1")
              ?.fields.find((field: any) => field.name === "message_template_fields");
            if (messageTemplateFields) {
              payload.name = messageTemplateFields.fields.find((field: any) => field.name === "template_name")?.value || "";
              payload.language = messageTemplateFields.fields.find((field: any) => field.name === "template_language")?.value?.value || "";
            }
    
            const content = messageTemplate.fields.find((field: any) => field.name === "content");
            if (content) {
              const contentFields = content.fields.find((field: any) => field.name === "content_fields");
              if (contentFields) {

                const headerContent = contentFields.fields.find((field: any) => field.name === "content_header");
                if (headerContent) {
                  payload.components[0].text = headerContent.fields.find((field: any) => field.name === "header_content_name")?.value || "";
                  payload.components[0].example.header_text.push(headerContent.fields.find((field: any) => field.name === "variable_header")?.value || "")
                }
    
                const bodyContent = contentFields.fields.find((field: any) => field.name === "content_body");
                if (bodyContent) {
                  payload.components[1].text = bodyContent.fields.find((field: any) => field.name === "body_content_name")?.value || "";
                  payload.components[1].example.body_text[0] = bodyContent.fields
                    .filter((field: any) => field.name.startsWith("variable_"))
                    .map((field: any) => field.value || "");
                }
  
                if(contentFields.fields.find((field: any) => field.name === "footer_content_name")?.value){
                  payload.components[2].text = contentFields.fields.find((field: any) => field.name === "footer_content_name")?.value || "";
                } else{
                  payload.components.pop()
                }
              }
            }
          }
        }
      }
    
      return payload;
    }; 

    const handleSelect = (e: any) => {
      
      if(e.name==="submit"){
        setDetectChange(false)
        const validation=validateData(schema.schema);
        if(validation){
          toast.error(`${validation} cannot be empty`,{position:"top-center",autoClose:AUTO_CLOSE})
        }
        else{
          API_POST_Data(); 
        }
      }
      if (e.name === "cancel") {
          navigate("/templates")
      }
      if (e.name === "template_name") {
          let templateName = e.value;
          const transformValue = (value: string) => {
            return value
                .toLowerCase()
                .replace(/ /g, '_') 
                .replace(/[^a-z0-9_]/g, '')
          };
          templateName = transformValue(templateName);
          setSchema((prevSchema: { schema: any[]; }) => ({
              ...prevSchema,
              schema: prevSchema.schema.map((field: { name: string; fields: any[]; }) => 
                  field.name === "add_form" 
                      ? {
                          ...field, 
                          fields: field.fields.map((subField: { name: string; fields: any[]; }) => 
                              subField.name === "whatsapp_schema" 
                                  ? {
                                      ...subField,
                                      fields: subField.fields.map((f: { name: string; fields: any[]; }) => 
                                          f.name === "message_template" 
                                              ? {
                                                  ...f,
                                                  fields: f.fields.map((mf: { name: string; fields: any[]; }) => 
                                                      mf.name === "message_template_1" 
                                                          ? {
                                                              ...mf,
                                                              fields: mf.fields.map((mtf: { name: string; fields: any[]; }) => 
                                                                  mtf.name === "message_template_fields" 
                                                                      ? {
                                                                          ...mtf,
                                                                          fields: mtf.fields.map((field: { name: string; }) => 
                                                                              field.name === "template_name" 
                                                                                  ? { 
                                                                                      ...field, 
                                                                                      value: templateName,
                                                                                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                          handleSelect({ 
                                                                                              name: "template_name", 
                                                                                              value: transformValue(e.target.value)
                                                                                          });
                                                                                      }
                                                                                  }
                                                                                  : field
                                                                          )
                                                                      }
                                                                      : mtf
                                                              )
                                                          }
                                                          : mf
                                                  )
                                              }
                                              : f
                                      )
                                  }
                                  : subField
                          )
                      }
                      : field
              )
          }));
      }
      if(e.name === "btn_add_header"){
        setSchema((prev: { schema: any[]; }) => {
          const updatedSchema = prev.schema.map((field: { name: string; fields: any[]; }) => {
            if (field.name === "add_form") {
              field.fields = field.fields.map((subField: { name: string; fields: any[]; }) => {
                if (subField.name === "whatsapp_schema") {
                  subField.fields = subField.fields.map((formField: { name: string; fields: any[]; }) => {
                    if (formField.name === "message_template") {
                      formField.fields = formField.fields.map((templateField: { name: string; fields: any[]; }) => {
                        if (templateField.name === "content") {
                          templateField.fields = templateField.fields.map((contentField: { name: string; fields: any[]; }) => {
                            if (contentField.name === "content_fields") {
                              contentField.fields = contentField.fields.map((headerField: { name: string; fields: any[]; }) => {
                                if (headerField.name === "content_header") {
                                  headerField.fields = headerField.fields.map((variableField: { name: string; visible: boolean; loading: boolean; value: string; required:boolean; }) => {
                                    if (variableField.name === "variable_header") {
                                      variableField.visible = true;
                                      variableField.required = true;
                                    }
                                    if (variableField.name === "btn_add_header") {
                                      variableField.loading = true;
                                    }
                                    if (variableField.name === "header_content_name") {
                                      variableField.value = (variableField.value || "") + " {{1}}";
                                    }
                                    return variableField;
                                  });
                                }
                                return headerField;
                              });
                            }
                            return contentField;
                          });
                        }
                        return templateField;
                      });
                    }
                    return formField;
                  });
                }
                return subField;
              });
            }
            return field;
          });
          return { ...prev, schema: updatedSchema };
        });
      }
      if(e.name==="header_content_name"){
        setHeaderText(e.value)
        if(e.value.includes("{{1}}")){
          setSchema((prev: { schema: any[]; }) => {
            const updatedSchema = prev.schema.map((field: { name: string; fields: any[]; }) => {
              if (field.name === "add_form") {
                field.fields = field.fields.map((subField: { name: string; fields: any[]; }) => {
                  if (subField.name === "whatsapp_schema") {
                    subField.fields = subField.fields.map((formField: { name: string; fields: any[]; }) => {
                      if (formField.name === "message_template") {
                        formField.fields = formField.fields.map((templateField: { name: string; fields: any[]; }) => {
                          if (templateField.name === "content") {
                            templateField.fields = templateField.fields.map((contentField: { name: string; fields: any[]; }) => {
                              if (contentField.name === "content_fields") {
                                contentField.fields = contentField.fields.map((headerField: { name: string; fields: any[]; }) => {
                                  if (headerField.name === "content_header") {
                                    headerField.fields = headerField.fields.map((variableField: { name: string; visible: boolean; loading: boolean; value: string; }) => {
                                      if (variableField.name === "header_content_name"){
                                        let t = e.value 
                                        t = t.replace(/{{(?!1\b)\d+}}/g, "")
                                        variableField.value = t
                                      }
                                      if (variableField.name === "variable_header") {
                                        variableField.visible = true;
                                      }
                                      if (variableField.name === "btn_add_header") {
                                        variableField.loading = true;
                                      }
                                      return variableField;
                                    });
                                  }
                                  return headerField;
                                });
                              }
                              return contentField;
                            });
                          }
                          return templateField;
                        });
                      }
                      return formField;
                    });
                  }
                  return subField;
                });
              }
              return field;
            });
            return { ...prev, schema: updatedSchema };
          });
        }
        else{
          setSchema((prev: { schema: any[]; }) => {
            const updatedSchema = prev.schema.map((field: { name: string; fields: any[]; }) => {
              if (field.name === "add_form") {
                field.fields = field.fields.map((subField: { name: string; fields: any[]; }) => {
                  if (subField.name === "whatsapp_schema") {
                    subField.fields = subField.fields.map((formField: { name: string; fields: any[]; }) => {
                      if (formField.name === "message_template") {
                        formField.fields = formField.fields.map((templateField: { name: string; fields: any[]; }) => {
                          if (templateField.name === "content") {
                            templateField.fields = templateField.fields.map((contentField: { name: string; fields: any[]; }) => {
                              if (contentField.name === "content_fields") {
                                contentField.fields = contentField.fields.map((headerField: { name: string; fields: any[]; }) => {
                                  if (headerField.name === "content_header") {
                                    headerField.fields = headerField.fields.map((variableField: { name: string; visible: boolean; loading: boolean; value: string; }) => {
                                      if (variableField.name === "variable_header") {
                                        variableField.visible = false;
                                      }
                                      if (variableField.name === "btn_add_header") {
                                        variableField.loading = false;
                                      }
                                      return variableField;
                                    });
                                  }
                                  return headerField;
                                });
                              }
                              return contentField;
                            });
                          }
                          return templateField;
                        });
                      }
                      return formField;
                    });
                  }
                  return subField;
                });
              }
              return field;
            });
            return { ...prev, schema: updatedSchema };
          });
        }
      }
      if(e.name === "variable_header"){
        setHeaderVariable((text: any) => {
          return e.value
        })
      }
      if(e.name === "btn_add_body") {
        setSchema((prev: { schema: any[]; }) => {
            const updatedSchema = prev.schema.map((field: { name: string; fields: any[]; }) => {
                if (field.name === "add_form") {
                    field.fields = field.fields.map((subField: { name: string; fields: any[]; }) => {
                        if (subField.name === "whatsapp_schema") {
                            subField.fields = subField.fields.map((formField: { name: string; fields: any[]; }) => {
                                if (formField.name === "message_template") {
                                    formField.fields = formField.fields.map((templateField: { name: string; fields: any[]; }) => {
                                        if (templateField.name === "content") {
                                            templateField.fields = templateField.fields.map((contentField: { name: string; fields: any[]; }) => {
                                                if (contentField.name === "content_fields") {
                                                    contentField.fields = contentField.fields.map((bodyField: { name: string; fields: any[]; }) => {
                                                        if (bodyField.name === "content_body") {

                                                            if(!bodyField?.fields?.find((field: any)=> field.name === `variable_${variableCount + 1}`)){
                                                                // setVariableCount((prev: any) => prev+1)
                                                                bodyField.fields = [
                                                                    ...bodyField.fields,
                                                                    {
                                                                        name: `variable_${variableCount + 1}`,
                                                                        element: "input-text",
                                                                        placeholder: `Enter Content for {{${variableCount + 1}}}`,
                                                                        label: `{{${variableCount + 1}}}`,
                                                                        containerClassName: "flex gap-2 items-center w-full",
                                                                        labelClassName: "text-sm mb-1 text-gray-500 font-semibold text-start",
                                                                        className: "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                                                                        required: true,
                                                                        visible: true
                                                                    }
                                                                    
                                                                ]

                                                                const bodyFieldContent = bodyField.fields.find(f => f.name === "body_content_name");
                                                                if (bodyFieldContent) {
                                                                  bodyFieldContent.value = (bodyFieldContent.value || "") + ` {{${variableCount + 1}}}`;
                                                                  setBodyVariables((vars: any)=>{
                                                                    vars[`${variableCount + 1}`] = " "
                                                                    console.log(vars);
                                                                    
                                                                    return vars
                                                                  })
                                                                  setBodyTrigger((n: any) => {return n + 1  })
                                                                  setBodyText(bodyFieldContent.value)
                                                            }
                                                            }
                                                            
                                                            // bodyField.fields = [
                                                            //     ...bodyField.fields,
                                                            //     {
                                                            //         name: `variable_${variableCount + 1}`,
                                                            //         element: "input-text",
                                                            //         placeholder: `Enter Content for {{${variableCount + 1}}}`,
                                                            //         label: `{{${variableCount + 1}}}`,
                                                            //         containerClassName: "flex gap-2 items-center w-full",
                                                            //         labelClassName: "text-sm mb-1 text-gray-500 font-semibold text-start",
                                                            //         className: "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                                                            //         required: true,
                                                            //         visible: true,
                                                            //     }
                                                            // ];
                                                            // Concatenate `{{length}}` to the `body_content_name` value
                                                            
                                                        }
                                                        return bodyField;
                                                    });
                                                }
                                                return contentField;
                                            });
                                        }
                                        return templateField;
                                    });
                                }
                                return formField;
                            });
                        }
                        return subField;
                    });
                }
                return field;
            });
            return { ...prev, schema: updatedSchema };
        });
        setVariableCount((prevCount) => prevCount + 1);
      }
      if (e.name === "body_content_name") {
        setBodyText(e.value)
        if (e.value.length === 0) {
          setSchema((prev: { schema: any[] }) => {
            const updatedSchema = JSON.parse(JSON.stringify(prev.schema)); // Deep clone
      
            const addForm = updatedSchema.find((field: any) => field.name === "add_form");
            if (addForm) {
              const whatsappSchema = addForm.fields.find((subField: any) => subField.name === "whatsapp_schema");
              if (whatsappSchema) {
                const messageTemplate = whatsappSchema.fields.find((formField: any) => formField.name === "message_template");
                if (messageTemplate) {
                  const content = messageTemplate.fields.find((templateField: any) => templateField.name === "content");
                  if (content) {
                    const contentFields = content.fields.find((contentField: any) => contentField.name === "content_fields");
                    if (contentFields) {
                      const contentBody = contentFields.fields.find((headerField: any) => headerField.name === "content_body");
                      if (contentBody) {
                       
                        contentBody.fields = contentBody.fields.slice(0, 2);
                        
                      }
                    }
                  }
                }
              }
            }
      
            return { ...prev, schema: updatedSchema };
          });
          setVariableCount(0);
        }
      }
      if(e.name.includes("variable_")){
        const val = e?.name?.split("_")[1]
        setBodyVariables((vars: any)=>{
          vars[`${val}`] = e?.value
          console.log(vars);
          
          return vars
        })
        setBodyTrigger((n: any)=> { return n+1})
      }
      if(e.name === "footer_content_name"){
        setFooterText(e.value)
      }
    }
    
    useEffect(()=>{

      setSchema((prev: any)=>{

        prev?.schema?.map((f: any)=>{
            if(f.name === "add_form"){
              f?.fields.map((ff: any)=>{
                if(ff.name === "whatsapp_schema"){
                  ff?.fields.map((fff: any)=>{
                    if(fff.name === "preview_container"){
                        fff?.fields?.map((ffff: any)=>{
                          if(ffff.name === "preview_container"){
                            ffff?.fields?.map((d: any)=>{
       
                              if(d.name === "preview_body"){
                                  d?.fields?.map((g: any)=>{

                                    if(g.name === "preview_msg"){
                                      g?.fields?.map((t: any)=>{

                                    if(t.name === "title"){
                                      const text = headerText?.replace("{{1}}", `${headerVariable}`)
                                      t.label = text
                                    }
                                    if(t.name === "body"){
                                      let text = bodyText
                                      for (const key in bodyVariables) {
                                        text = text?.replace(`{{${key}}}`, `${bodyVariables[key]}`)
                                    }
                                      t.label = text
                                      console.log(text);
                                      
                                    }
                                    if(t.name === "footer"){
                                      t.label = footerText
                                    }

                                  })
                                }

                                })
                              }
                            })
                          }
                        })
                    }
                  })
                }
              })
            }
        })

        return {...prev}
      })
      

    }, [headerText, bodyText, footerText, title, bodyTrigger, headerVariable])


    const [detectChange, setDetectChange] = useState(false)

    // Exit popup's custom hook
    useExitPopUp(detectChange)



    return (
        <div>
             {loading && <LoadingState/>}
            <ElementExecutor
                data={schema}
                selectedRecord={(e: any) => {
                    handleSelect(e);
                    if(["template_name", "template_language", "campaign_category_type", "header_content_type", "header_content_name", "btn_add_header", "variable_header", "body_content_name", "btn_add_body", "footer_content_name"].includes(e?.name) ){
                      setDetectChange(true)
                    }
                }}
            />
        </div>
    )
}

export default EditTemplate