import { ElementExecutor } from '@apexcura/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Utils from '../../utils';
import { CONSTANTS } from '../../utils/app-constants';
import { CallStatus } from '../../utils/types';
import LoadingState from '../LoadingState';
import Storage from '../../utils/local-storage';
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;

export const recent_interactions:any=[
    {
        name:"Call",
        id:1,
        type:"Ambulance Request",
        call_Status:"Open",
        date:"12-08-2024 13:14",
    },
    {
        name:"Lead",
        date:"12-08-2024 12:35",
        type:"Enquiry",
        id:2,
        source:"Phone"
    },
    {
        name:"Call",
        type:"Missed Call",
        call_Status:"Open",
        id:3,
        date:"12-08-2024 12:30",
    },
    {
        name:"Chat",
        type:"Website",
        date:"11-08-2024 10:21",
        id:4
    },
    {
        name:"Lead",
        date:"11-08-2024 12:24",
        type:"Enquiry",
        source:"Phone",
        id:5
    },
    {
        name:"Chat",
        type:"Whatsapp",
        date:"11-08-2024 18:36",
        id:6,
    },
    {
        name:"Chat",
        type:"Website",
        date:"08-08-2024 08:11",
        id:7
    },
    {
        name:"Lead",
        date:"07-08-2024 12:41",
        type:"Enquiry",
        source:"Chatbot",
        id:8
    },
    {
        name:"Call",
        type:"Missed Call",
        call_Status:"Open",
        id:9,
        date:"06-08-2024 13:45",
    },
    {
        name:"Chat",
        type:"Website",
        date:"15-08-2024 07:58",
        id:10
    }
]

const colors:any = {
    "Call Back Request": "bg-[#29AAE1]/5 text-[#29AAE1]",
    "Ambulance Request": "bg-[#BF0E0E]/5 text-[#BF0E0E]",
    "Call Was Successful": "bg-[#4DA723]/5 text-[#4DA723]",
    "Client Hungup": "bg-[#F59F0D]/5 text-[#F59F0D]",
    "Missed Call": "bg-[#F9A07A]/5 text-[#F37748]"
};

const ViewCalls = () => {
    const CallStatusStyles: any = {
        "None": "bg-[#29AAE1]/10 text-[#29AAE1]",
        "Open": "bg-fuchsia-500/5 text-fuchsia-500",
        "Pending": "bg-yellow-500/10 text-yellow-500",
        "Closed/Call-back done": "bg-green-500/5 text-green-500",
        "Callback": "bg-fuchsia-500/5 text-fuchsia-500",
        "Closed": "bg-green-500/5 text-green-500",
    };

    const params = useParams();
    const id = params?.id || "";

    const [loading, setLoading] = useState<boolean>(false);

    const [commentInput, setCommentInput] = useState("")
    const [commentStatus, setCommmentStatus] = useState<{ value: string, label: string }>({ value: '', label: '' })


    const commentsSchema = () => (
        {
            name: 'comments_modal_container',
            className: 'w-full col-span-2',
            containerClassName: 'flex flex-col bg-white shadow-lg h-[60vh] rounded-lg ',
            fields: [
                {
                    name: 'modal_title',
                    className: 'bg-gray-50 rounded-lg flex justify-between items-center p-2 pl-4 w-full',
                    fields: [
                        {
                            name: 'modal_title_key',
                            className: 'font-semibold text-lg',
                            element: 'div',
                            label: 'Comments'
                        },
                    ]
                },
                {
                    name: 'modal_body_container',
                    visible: true,
                    className: 'flex flex-col overflow-auto bg-white h-[40vh] divide-y',
                    fields: [
                        {
                            name: "empty",
                            label: "No comments",
                            element: "div",
                            className: "flex items-center justify-center w-full h-[40vh]"
                        }
                    ]
                },
                {
                    name: 'modal_comment_container',
                    className: 'mt-auto p-4 bg-gray-50 rounded-lg flex flex-row gap-2',
                    visible: true,
                    fields: [
                        {
                            name: 'lead_status_dropdown',
                            className: "rounded-md text-gray-700 font-semibold",
                            element: "single-select",
                            containerClassName: "flex flex-col basis-1/6",
                            labelClassName:
                                "text-sm mb-1 text-gray-500 font-semibold text-start",
                            required: true,
                            placeholder: "Status",
                            options: [
                                {
                                    label: "None",
                                    value: CallStatus.none,
                                },
                                {
                                    label: "Open",
                                    value: CallStatus.open,
                                },
                                {
                                    label: "Pending",
                                    value: CallStatus.pending,
                                },
                                {
                                    label: "Closed",
                                    value: CallStatus.closecallback,
                                },
                            ],
                        },
                        {
                            name: 'lead_comment_input',
                            element: 'input-textarea',
                            minRows: 1,
                            maxRows: 5,
                            placeholder: "Enter your comment here",
                            containerClassName: 'basis-4/6 bg-white rounded-lg overflow-visible '
                        },
                        {
                            name: 'comment_submit',
                            element: 'button',
                            label: 'Comment',
                            className: 'basis-1/6 mt-auto h-[35px] bg-primary rounded-lg text-white'
                        }
                    ]
                }
            ]
        }
    )

    const [schema, setSchema] = useState<any>({
        className: "w-full bg-white rounded-lg py-[10px] px-[20px] flex flex-col gap-3",
        schema: [
            {
                name: "view_calls_header_title",
                className: "text-lg font-semibold text-primary",
                label: "Call Details",
                element: "div"
            },
            {
                name: 'view_calls_header_data',
                className:
                    'grid grid-cols-3 gap-2 py-2 text-sm',

                fields: [
                    {
                        name: "data_pair",
                        className: "flex gap-1",
                        fields: [
                            {
                                name: "calls_from_label",
                                className: " font-medium text-gray-700",
                                label: "From: ",
                                element: "div",
                            },
                            {
                                name: "call_from_value",
                                className: " font-bold text-gray-600 capitalize",
                                label: " N/A",
                                element: "div",
                            },
                        ]
                    },
                    {
                        name: "data_pair",
                        className: "flex gap-1",
                        fields: [
                            {
                                name: "call_to_label",
                                className: " font-medium text-gray-700",
                                label: "To: ",
                                element: "div",
                            },
                            {
                                name: "call_to_value",
                                className: " font-bold text-gray-600 capitalize",
                                label: " N/A",
                                element: "div",
                            },
                        ]
                    },
                    {
                        name: "data_pair",
                        className: "flex gap-1",
                        fields: [
                            {
                                name: "direction_label",
                                className: " font-medium text-gray-700",
                                label: "Direction: ",
                                element: "div",
                            },
                            {
                                name: "direction_value",
                                className: " font-bold text-gray-600 capitalize",
                                label: " N/A",
                                element: "div",
                            },
                        ]
                    },
                    {
                        name: "data_pair",
                        className: "flex gap-1",
                        fields: [
                            {
                                name: "calls_status_label",
                                className: " font-medium text-gray-700",
                                label: "Call Type: ",
                                element: "div",
                            },
                            {
                                name: "calls_status_value",
                                className: " font-bold text-gray-600 capitalize",
                                label: " N/A",
                                element: "div",
                            },
                        ]
                    },
                    {
                        name: "data_pair",
                        className: "flex gap-1",
                        fields: [
                            {
                                name: "call_date_label",
                                className: " font-medium text-gray-700",
                                label: "Date: ",
                                element: "div",
                            },
                            {
                                name: "call_date_value",
                                className: " font-bold text-gray-600 capitalize",
                                label: " N/A",
                                element: "div",
                            },
                        ]
                    },
                    {
                        name: "data_pair",
                        className: "flex gap-1",
                        fields: [
                            {
                                name: "call_duration_label",
                                className: " font-medium text-gray-700",
                                label: "Duration: ",
                                element: "div",
                            },
                            {
                                name: "call_duration_value",
                                className: " font-bold text-gray-600 capitalize",
                                label: " N/A",
                                element: "div",
                            },
                        ]
                    },
                ],
            },
            {
                name:"comments_and_recent_interactions",
                className:"grid grid-cols-3 items-center gap-2 w-full",
                fields:[
                    {...commentsSchema()},
                    {
                        name:"recent_interactions",
                        className:"w-full bg-gray-50 border-[1px] col-span-1 p-1 flex flex-col gap-0 h-[60vh] rounded-md",
                        fields:[
                            {
                                name:"recent_interactions_text",
                                label:"Recent Interactions",
                                className:"text-lg font-semibold pt-1 pl-1 text-primary",
                                element:"div"
                            },
                            {
                                name:"recent_interactions_cards",
                                className:" overflow-y-scroll gap-1 h-full",
                                fields:[]
                            }
                        ]
                        
                    }
                ]
                
            }
        ],
    });

    useEffect(() => {
        if (id) {
            API_GET_GetAllComments()
            API_GET_CALL_DATA()
        }

    }, [id])


    const API_GET_CALL_DATA = async () => {
        setLoading(true);
        // return
        try {
            const res = await Utils.makeApiCall(
                `/ivrCallDetails/${id}`,
                'GET'
            )
            if (res.status && res.data) {
                setSchema((prev: any) => {

                    const updatedSchema:any=prev?.schema?.map((sec: any) => {
                        if (sec?.name === "view_calls_header_data") {
                            sec.fields=sec?.fields?.map((f: any) => {
                                if (f.name === "data_pair") {
                                    f.fields=f?.fields?.map((ff: any) => {
                                        if (ff.name === "call_from_value") {
                                            ff.label = `${res?.data?.Name} (${res?.data?.From})` || "N/A"
                                            // ff.label = res?.data?.From || "N/A"
                                        }
                                        if (ff.name === "call_to_value") {
                                            ff.label = res?.data?.To || "N/A"
                                        }
                                        if (ff.name === "direction_value") {
                                            ff.label = res?.data?.Direction || "N/A"

                                        }
                                        if (ff.name === "calls_status_value") {
                                            ff.label = res?.data?.DialCallStatus || "N/A"
                                        }
                                        if (ff.name === "call_date_value") {
                                            let date = res?.data?.StartTime || "N/A"
                                            date = moment(date).format("DD-MM-YYYY HH:mm")
                                            ff.label = date || "N/A"
                                        }
                                        if (ff.name === "call_duration_value") {
                                            ff.label = `${res?.data?.DialCallDuration}s` || "N/A"
                                        }
                                        return ff;
                                    })
                                }
                                return f;
                            })
                        }
                        if(sec.name==="comments_and_recent_interactions"){
                            sec.fields=sec.fields.map((item:any)=>{
                                if(item.name==="recent_interactions"){
                                    item.fields=item.fields.map((ffield:any)=>{
                                        if(ffield.name==="recent_interactions_cards"){
                                            ffield.fields=recent_interactions.map((field: { id: any; name: string; status: string | number; call_Status: any; date: any; type: any; }) => ({
                                                name: `interaction_${field.id}`,
                                                
                                                className: 'm-1 p-2 flex flex-col gap-[2px] border-[1px] border-gray-200 rounded-md drop-shadow-sm bg-white',
                                                fields: [
                                                    {
                                                        name: "interaction_header",
                                                        
                                                        className: 'flex justify-between items-center',
                                                        fields: [
                                                            {
                                                                name: "interaction_name",
                                                                element: "div",
                                                                className: 'text-sm font-medium text-black ',
                                                                label: field.name
                                                            },
                                                            {
                                                                name: "interaction_status",
                                                                element: "div",
                                                                className: `p-1 px-2 text-xs rounded-md text-gray-600`,
                                                                label: field.name === 'Call' ? field.date : field.date,
                                                                visible: field.name === 'Call' || field.date
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        name: "interaction_type",
                                                        element: "div",
                                                        className: `text-[10px] p-1 px-2 w-fit font-medium ${field.name === 'Call' ? colors[field.type] :field.name === 'Lead'? 'bg-[#F59F0D]/5 text-[#F59F0D]' :"bg-[#29AAE1]/5 text-[#29AAE1]" }`,
                                                        label: `${field.type}`
                                                    }
                                                ]
                                            }))
                                        }
                                        return ffield;
                                    })
                                }
                                return item;
                            })
                        }
                        
                        return sec;
                    })

                    return { ...prev,schema:updatedSchema }
                })

            } else {
                toast.error(res.message ? res.message : FAILURE_MSG, {
                    position: 'top-center',
                    autoClose: 2000,
                })
            }

        } catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
            console.error('Failed to fetch schema', error)
        }
        setLoading(false);
    }


    const API_GET_GetAllComments = async () => {
        setLoading(true);

        try {
            const res = await Utils.makeApiCall(
                `/calls/getComments?callId=${id}`,
                'GET'
            )
            if (res.status) {
                if (res?.data?.length !== 0) {
                    setSchema((prev: any) => {
                        prev?.schema?.map((ss: any) => {
                            if(ss.name==="comments_and_recent_interactions"){
                                ss.fields.map((ssf:any)=>{
                                    if (ssf.name === "comments_modal_container") {
                                        ssf.fields.map((ff: any) => {
                                            if (ff.name === "modal_body_container") {
                                                ff.fields = res.data.map((comment: any) => {
                                                    const name = comment?.fullname || '--'
                                                    const allInitials = name?.split(' ').map((e: string) => e[0]).join('').toUpperCase() || '--'
                                                    const iconInitials = allInitials.length >= 2 ? allInitials.substring(0, 2) : allInitials;
        
                                                    return {
                                                        name: comment['_id'],
                                                        className: 'flex flex-col',
                                                        fields: [
                                                            {
                                                                name: 'modal_content',
                                                                className: 'flex w-full p-2 gap-1',
                                                                fields: [
                                                                    {
                                                                        name: 'modal_icon',
                                                                        className: 'basis-[80px] flex justify-center',
                                                                        fields: [
                                                                            {
                                                                                name: 'modal_element_icon',
                                                                                className: `bg-primary/10 text-primary rounded-full w-[50px] h-[50px] flex items-center justify-center text-2xl font-bold`,
                                                                                element: 'div',
                                                                                label: iconInitials
                                                                            }
                                                                        ]
                                                                    },
                                                                    {
                                                                        name: 'modal_body',
                                                                        className: ' w-full flex flex-col',
                                                                        fields: [
                                                                            {
                                                                                name: 'modal_element_title',
                                                                                className: 'flex flex-row justify-between w-full',
                                                                                fields: [
                                                                                    {
                                                                                        name: 'modal_element_name',
                                                                                        className: 'flex gap-2 items-center justify-center',
                                                                                        fields: [
                                                                                            {
                                                                                                name: 'user_name',
                                                                                                className: 'font-bold text-lg',
                                                                                                element: 'div',
                                                                                                label: name || '--'
                                                                                            },
                                                                                            {
                                                                                                name: 'time',
                                                                                                className: 'text-xs',
                                                                                                element: 'div',
                                                                                                // label: `${moment(comment?.createdAt).format('DD-MM-YYYY hh:mm a')} (${moment(comment?.createdAt).fromNow()})`
                                                                                                label: `${moment(comment?.createdAt).fromNow()}`
                                                                                            },
                                                                                        ]
                                                                                    },
                                                                                    {
                                                                                        name: 'modal_element_group',
                                                                                        className: 'flex flex-row gap-3 items-center',
                                                                                        fields: [
                                                                                            {
                                                                                                name: 'modal_element_status',
                                                                                                className: `p-1 px-2 text-xs rounded-md  ${CallStatusStyles[comment.status]}`,
                                                                                                element: 'div',
                                                                                                label: comment?.status || '--',
                                                                                                visible: comment?.status ? true : false
                                                                                            }
                                                                                        ]
                                                                                    }
                                                                                ]
                                                                            },
                                                                            {
                                                                                name: 'modal_element_text',
                                                                                element: 'div',
                                                                                className: '',
                                                                                label: comment?.text || '--'
                                                                            }
                                                                        ]
                                                                    }
        
                                                                ]
                                                            }
                                                        ]
        
                                                    }
                                                });
                                            }
                                            return ff;
                                        })
                                    }
                                    return ssf;
                                })
                            }
                            
                            return ss;
                        })
                        return { ...prev };
                    })
                }


            } else {
                toast.error(res.message ? res.message : FAILURE_MSG, {
                    position: 'top-center',
                    autoClose: 2000,
                })
            }
        } catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
            console.error('Failed to fetch schema', error)
        }
        setLoading(false);
    }

    const API_POST_AddComent = async () => {
        setLoading(true);

        if (!commentInput) {
            toast.error("Please enter your comment.", { position: "top-center", autoClose: AUTO_CLOSE })
            setLoading(false);
            return;
        }
        if (commentStatus['value'].length === 0) {
            toast.error("Please select your status.", { position: "top-center", autoClose: AUTO_CLOSE })
            setLoading(false);
            return;
        }

        const payload = {
            text: commentInput,
            userId:Storage.getItem('details')['_id'],
            callId: id,
            status: commentStatus['value']
        }


        try {
            const res = await Utils.makeApiCall(
                `/calls/addComment`,
                'POST',
                payload
            )

            if (res.status) {
                toast.success(res.message, {
                    position: 'top-center',
                    autoClose: 2000,
                })
                API_GET_GetAllComments();
                resetForm(true);
            } else {
                toast.error(res.message ? res.message : FAILURE_MSG, {
                    position: 'top-center',
                    autoClose: 2000,
                })
            }
        } catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
            console.error('Failed to fetch schema', error)
        }
        setLoading(false);
    }

    const resetForm = (visible: boolean) => {
        setSchema((prev: any) => {
            prev.schema.map((ss: any) => {
                if(ss.name==="comments_and_recent_interactions"){
                    ss.fields=ss.fields.map((item:any)=>{
                        if (item.name === "comments_modal_container") {
                            item.visible = visible;
                            item.fields.map((f: any) => {
                                if (f.name === "modal_body_container") {
                                    if (!visible) {
                                        f.fields = [{
                                            name: "empty",
                                            label: "No comments",
                                            element: "div",
                                            className: "flex items-center justify-center w-full h-[200px]"
                                        }];
                                    }
                                } else if (f.name === "modal_comment_container") {
                                    f.fields = f.fields.map((ff: any) => {
                                        ff.value = null;
                                        return ff;
                                    });
                                }
                            })
                        }
                        return item;
                    })
                }
                
                return ss;
            });
            return { ...prev };
        });
    }



    const handleSelectedRecord = (e: any) => {

        if (e.name === "comment_submit") {
            API_POST_AddComent()
        }
        if (e.name === "lead_status_dropdown") {
            setCommmentStatus(e?.value || { value: '', label: '' })
        }
        if (e.name === "lead_comment_input") {
            setCommentInput(e?.value || "")
        }


    }

    return (
        <div className="bg-[#EFF1F9]">
            {loading && <LoadingState />}
            <ElementExecutor
                data={schema}
                selectedRecord={(e: any) => {
                    handleSelectedRecord(e);
                }}
            />
        </div>
    )
}

export default ViewCalls
