import React, { useEffect, useState } from 'react'
import {Avatar,Tooltip} from "antd";
import moment from 'moment';
import { ElementExecutor } from '@apexcura/core';
import { CONSTANTS } from '../../utils/app-constants';
import { toast } from 'react-toastify';
import Utils from '../../utils';
import Storage from '../../utils/local-storage';
import LoadingState from '../LoadingState';
import axios from 'axios';
const { BASE_URL } = CONSTANTS;
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS

const CallsInsights = () => {
    const [schema, setSchema] = useState<any>({
        className:
          'w-full bg-white rounded-lg py-[10px] px-[20px] flex flex-col gap-3',
        schema: [
          {
            name: 'filters',
            fields: [
              {
                name:"date_and_search",
                className:"flex gap-2 justify-center items-center ",
                fields:[
                  {
                      name:"segments",
                      element:"segmented",
                      segmentedOptions:[
                        {
                            label:(<Tooltip title="Today">1D</Tooltip>),
                            value:"TD"
                        },
                        {
                            label:(<Tooltip title="This Week">1W</Tooltip>),
                            value:"TW"
                        },
                        {
                            label:(<Tooltip title="This Month">1M</Tooltip>),
                            value:"TM"
                        },
                      ],
                      visible:true,
                      value:"TM"
                  },
                  {
                    name: 'date',
                    label: '',
                    placeholder: 'Select a date',
                    allowClear:false,
                    element: 'daterangepicker',
                    value: [moment().subtract(30, 'days').format('DD-MM-YYYY'), moment().format("DD-MM-YYYY")],
                    className: 'flex-1 w-[240px]',
                  },
                ]
              },
            ],
            className: 'flex items-center justify-between gap-2',
          },
          {
            name:"anomolies_and_insights",
            className:"grid grid-cols-2 gap-2 items-start h-[calc(100vh-150px)]",
            fields:[
                {
                    name:"anomalies_table",
                    className:"flex gap-2 flex-col col-span-1 rounded-md p-2 h-full overflow-hidden",
                    fields:[
                        {
                            name:"anomalies",
                            label:"Anomalies",
                            element:"div",
                            className:"text-primary font-semibold text-xl w-full bg-[#F2F2F2] rounded-lg p-2"
                        },
                        {
                            name:"anomalies_cards",
                            className:"flex items-center justify-center",
                            fields:[
                                {
                                    name:"coming_soon",
                                    label:"Coming Soon..",
                                    className:"text-xl font-semibold",
                                    element:"div",
                                }
                            ]
                        }
                    ]
                },
                {
                    name:"actionable_insights_table",
                    className:"flex gap-2 flex-col col-span-1 rounded-md p-2 h-fit overflow-hidden",
                    fields:[
                        {
                            name:"actionable_insights",
                            label:"Actionable Insights",
                            element:"div",
                            className:"text-primary font-semibold text-xl w-full bg-[#F2F2F2] rounded-lg p-2"
                        },
                        {
                            name:"actionable_insights_cards",
                            className:"grid grid-cols-2 gap-2 h-full overflow-y-auto [&::-webkit-scrollbar]:hidden",
                            fields:[
                                {
                                    name: `call_completion_card`,
                                    className:
                                        'rounded-md cursor-pointer border-2 border-black hover:shadow-lg flex flex-col  gap-2 items-center bg-white px-[16px] py-[12px]',
                                    element: 'clickable-widget',
                                    fields: [
                                        {
                                            name: 'bot_effeciency_card_icon',
                                            className: 'cursor-default ',
                                            element: 'image',
                                            imgClassName:"size-8",
                                            img:`${BASE_URL}/api/public/assets/images/dashboard-bot.svg`,
                                            visible: true,
                                        },
                                        {
                                            name: 'bot_effeciency_card_text',
                                            className: 'text-lg text-black font-medium',
                                            element: 'div',
                                            label: 'Call Completion Rate',
                                            visible: true,
                                        }
                                    ],
                                },
                                {
                                    name: `call_frequency_card`,
                                    className:
                                        'rounded-md cursor-pointer border-2 border-black hover:shadow-lg flex flex-col  gap-2 items-center bg-white px-[16px] py-[12px]',
                                    element: 'clickable-widget',
                                    fields: [
                                        {
                                            name: 'call_frequency_card_icon',
                                            className: 'cursor-default ',
                                            element: 'image',
                                            imgClassName:"size-8",
                                            img:`${BASE_URL}/api/public/assets/images/dashboard-duration.svg`,
                                            visible: true,
                                        },
                                        {
                                            name: 'call_frequency_card_text',
                                            className: 'text-lg text-black font-medium',
                                            element: 'div',
                                            label: 'Caller Frequency',
                                            visible: true,
                                        }
                                    ],
                                },
                                {
                                    name: `peak_hour_card`,
                                    className:
                                        'rounded-md cursor-pointer border-2 border-black hover:shadow-lg flex flex-col  gap-2 items-center bg-white px-[16px] py-[12px]',
                                    element: 'clickable-widget',
                                    fields: [
                                        {
                                            name: 'peak_hour_icon',
                                            className: 'cursor-default ',
                                            element: 'image',
                                            imgClassName:"size-8",
                                            img:`${BASE_URL}/api/public/assets/images/dashboard-hangup.svg`,
                                            visible: true,
                                        },
                                        {
                                            name: 'peak_hour_text',
                                            className: 'text-lg text-black font-medium',
                                            element: 'div',
                                            label: 'Peak Hour',
                                            visible: true,
                                        }
                                    ],
                                },
                            ]
                        }
                    ]
                },
            ]
          },
          {
            name: 'table_modal',
            containerClassName:"!w-[80%] !h-[80%] ",
            className: 'rounded-lg !w-full',
            visible: false,
            element: "modal",
            fields: [
                {
                    name: 'modal_title',
                    className: 'w-full bg-[#F2F2F2] p-2 pl-4 flex items-center rounded-t-lg justify-between',
                    fields: [
                        {
                            name: 'modal_title_key',
                            element: 'div',
                            label: '',
                            className: 'font-semibold text-lg text-primary',
                        },
                        {
                            name: 'modal_close',
                            element: 'button',
                            label: 'X',
                            className: 'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
                        },
                    ],
                },
                {
                    name:"cards_and_chats",
                    className:"flex gap-2 items-center  w-full",
                    fields:[
                        {
                            name:"cards",
                            className:"grid grid-cols-3 w-[100%] gap-3 h-[70%] max-h-[80vh] overflow-y-scroll p-2",
                            fields:[
        
                            ]
                        },
                    ]
                }
            ],
          },
        ],
    });
    const [loading,setLoading]=useState(false);
    const [dateRange, setDateRange] = useState<[string, string]>([
        moment().subtract(30, 'days').format('DD-MM-YYYY'),
        moment().format('DD-MM-YYYY'),
    ])
    const details = Storage.getItem("details");

    useEffect(()=>{

        const first = moment(dateRange[0], 'DD-MM-YYYY');
        const second = moment(dateRange[1], 'DD-MM-YYYY');
        const diffInDays = second.diff(first, 'days');
        const today = moment().startOf('day');
    
        if (first.isSame(today, 'day') && second.isSame(today, 'day')) {
            change_segments_value("TD")
        } else if (diffInDays === 7 && second.isSame(today, 'day')) {
            change_segments_value("TW")
        } else if (diffInDays === 30 && second.isSame(today, 'day')) {
            change_segments_value("TM")
        }else{
            change_segments_value(null)
        }
    },[dateRange])

    const API_GET_CALL_INSIGHTS=async ({  startDate = "", endDate = "",name="" })=>{
        setLoading(true)
        try {
            const res: any = await Utils.makeApiCall(`/getCallsActionableInsights?start_date=${moment(startDate, "DD-MM-YYYY").format("YYYY-MM-DD")}&end_date=${moment(endDate, "DD-MM-YYYY").format("YYYY-MM-DD")}`,"GET");
            if (res?.status) {
                change_modal_data(res.data,name)
            } else {
                toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
            }
        } catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
            console.error('Failed to fetch schema', error);
        }
        setLoading(false)
    }

    const change_modal_data = (data: any, label: any) => {
        if (!data?.kpis || data.kpis.length === 0) {
            toast.error("No insights in the given dates.", { autoClose: 800, position: "top-center" });
            return;
        }

        const cardData:any=data.kpis.filter((item:any) =>item.insight===label) || [];
        if (!cardData || cardData.length === 0) {
            toast.error("No insights in the given dates.", { autoClose: 800, position: "top-center" });
            return;
        }
        
        setSchema((prev: any) => {
            const updatedSchema = prev.schema.map((item: any) => {
                if (item?.name === 'table_modal') {
                    item.visible = true;
                    item.fields = (item.fields || []).map((field: any) => {
                        if (field?.name === "cards_and_chats") {
                            field.fields = (field.fields || []).map((ffield: any) => {
                                if (ffield?.name === "cards") {
                                    ffield.fields = cardData.map((insight: any, index: any) => {
                                        const cardClassName = "cursor-default border-2 rounded-lg p-2";
                                        if (insight?.insight === "peak hour" && label === "peak hour") {
                                            return {
                                                name: `insight-item-${index}`,
                                                className: cardClassName,
                                                fields: [
                                                    {
                                                        name: "time_and_date",
                                                        visible:false,
                                                        className: "flex justify-between items-center gap-2 border-b border-b-[1px] border-gray-300 pb-2",
                                                        fields: [
                                                            {
                                                                name: `chat-name-${index}`,
                                                                className: 'text-sm font-semibold text-black',
                                                                element: "div",
                                                                label: `${moment(insight?.date || "", "YYYY-MM-DD").format("DD-MM-YYYY")}`,
                                                            },
                                                            {
                                                                name: `chat-date-${index}`,
                                                                className: 'text-[10px] text-gray-500',
                                                                element: "div",
                                                                label: `${insight?.time_interval || ""}`,
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        name: `chat-desc-${index}`,
                                                        className: 'text-lg text-black font-medium',
                                                        element: "div",
                                                        label: <p>The busiest hour on <span className='font-bold'>{moment(insight?.date || "", "YYYY-MM-DD").format("DD-MM-YYYY")}</span> <br/> was {insight?.time_interval || ""}  with <span className='font-bold text-primary'>{insight?.total_calls || 0}</span> total calls.</p>,
                                                    },
                                                ]
                                            };
                                        } else if (insight?.insight === "caller frequency" && label === "caller frequency") {
                                            return {
                                                name: `insight-item-${index}`,
                                                className: cardClassName,
                                                fields: [
                                                    {
                                                        name: "number_and_date",
                                                        visible:false,
                                                        className: "flex justify-between items-center gap-2 border-b border-b-[1px] border-gray-300 pb-2",
                                                        fields: [
                                                            {
                                                                name: `chat-name-${index}`,
                                                                className: 'text-sm font-semibold text-black',
                                                                element: "div",
                                                                label: `${insight?.number || ""}`,
                                                            },
                                                            {
                                                                name: `chat-date-${index}`,
                                                                className: 'text-[10px] text-gray-500',
                                                                element: "div",
                                                                label: `${moment(insight?.date || "", "YYYY-MM-DD").format("DD-MM-YYYY")}`,
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        name: `chat-desc-${index}`,
                                                        className: 'text-lg text-black font-medium',
                                                        element: "div",
                                                        label: <p>On {moment(insight?.date || "", "YYYY-MM-DD").format("DD-MM-YYYY") || ""}, the number <span className='font-bold'>{insight?.number || ""}</span> called <span className='font-bold text-primary'>{insight?.frequency || 0}</span> times.</p>,
                                                    },
                                                ]
                                            };
                                        } else if (insight?.insight === "call completion rate" && label === "call completion rate") {
                                            return {
                                                name: `insight-item-${index}`,
                                                className: cardClassName,
                                                fields: [
                                                    {
                                                        name: `chat-desc-${index}`,
                                                        className: 'text-lg text-black font-medium',
                                                        element: "div",
                                                        label: <p className=''>Call Completion Rate on<br/> <span className='font-bold '>{moment(insight?.date || "", "YYYY-MM-DD").format("DD-MM-YYYY") || ""}</span> is <span className='font-bold text-primary'>{insight?.rate || "0%"}</span></p>,
                                                    },
                                                    // {
                                                    //     name: `chat-date-${index}`,
                                                    //     className: 'text-[10px] text-gray-500 self-end',
                                                    //     element: "div",
                                                    //     label: `${moment(insight?.date || "", "YYYY-MM-DD").format("DD-MM-YYYY")}`,
                                                    // },
                                                ]
                                            };
                                        }
                                        return null;  
                                    }).filter(Boolean);
                                }
                                return ffield;
                            });
                        }
                        if (field?.name === "modal_title") {
                            field.fields = (field.fields || []).map((ffield: any) => {
                                if (ffield?.name === "modal_title_key") {
                                    ffield.label = `Insights for ${label}`;
                                }
                                return ffield;
                            });
                        }
                        return field;
                    });
                }
                return item;
            });
            return { ...prev, schema: updatedSchema };
        });
    };


    const handleModalClose = () => {
        setSchema((prev: any) => {
            const updatedSchema = prev.schema.map((item: any) => {
                if (item.name === 'table_modal') {
                    item.visible = false;
                }
                return item;
            });
            return { ...prev, schema: updatedSchema };
        });
    }

    const change_segments_value=(val:string | null)=>{
        setSchema((prev:any)=>{
            const updatedSchema=prev.schema.map((field:any)=>{
                if(field.name==="filters"){
                   field.fields=field.fields.map((ffield:any)=>{
                    if(ffield.name==="date_and_search"){
                        ffield.fields=ffield.fields.map((fffield:any)=>{
                            if(fffield.name==="segments"){
                                fffield.value=val;
                            }
                            return fffield;
                        })
                    }
                    return ffield;
                   })
                }
                return field;
            })
            return {...prev,schema:updatedSchema}
        })
    }

    const handleSelectedRecord = (e: any) => {

        if(e.name==="modal_close"){
            handleModalClose();
        }
        if (e.name === "segments") {
            // console.log(e)
            if (e.value === "TD") {
                setDateRange([
                    moment().format('DD-MM-YYYY'),
                    moment().format('DD-MM-YYYY'),
                ]);
                setSchema((prev: any) => {
                    const updatedSchema = prev.schema.map((field: any) => {
                        if (field.name === "filters") {
                            field.fields = field.fields.map((ffield: any) => {
                                if (ffield.name === "date_and_search") {
                                    ffield.fields = ffield.fields.map((ff: any) => {
                                        if (ff.name === "date") {
                                            ff.value = [
                                                moment().format('DD-MM-YYYY'),
                                                moment().format('DD-MM-YYYY'),
                                            ];
                                        }
                                        return ff;
                                    });
                                }
                                return ffield;
                            });
                        }
                        return field;
                    });
                    return { ...prev, schema: updatedSchema };
                });
            }
            if (e.value === "TM") {
                setDateRange([
                    moment().subtract(30, 'days').format('DD-MM-YYYY'),
                    moment().format('DD-MM-YYYY'),
                ]);
                setSchema((prev: any) => {
                    const updatedSchema = prev.schema.map((field: any) => {
                        if (field.name === "filters") {
                            field.fields = field.fields.map((ffield: any) => {
                                if (ffield.name === "date_and_search") {
                                    ffield.fields = ffield.fields.map((ff: any) => {
                                        if (ff.name === "date") {
                                            ff.value = [
                                                moment().subtract(30, 'days').format('DD-MM-YYYY'),
                                                moment().format('DD-MM-YYYY'),
                                            ];
                                        }
                                        return ff;
                                    });
                                }
                                return ffield;
                            });
                        }
                        return field;
                    });
                    return { ...prev, schema: updatedSchema };
                });
            }
            if (e.value === "TW") {
                setDateRange([
                    moment().subtract(7, 'days').format('DD-MM-YYYY'),
                    moment().format('DD-MM-YYYY'),
                ]);
                setSchema((prev: any) => {
                    const updatedSchema = prev.schema.map((field: any) => {
                        if (field.name === "filters") {
                            field.fields = field.fields.map((ffield: any) => {
                                if (ffield.name === "date_and_search") {
                                    ffield.fields = ffield.fields.map((ff: any) => {
                                        if (ff.name === "date") {
                                            ff.value = [
                                                moment().subtract(7, 'days').format('DD-MM-YYYY'),
                                                moment().format('DD-MM-YYYY'),
                                            ];
                                        }
                                        return ff;
                                    });
                                }
                                return ffield;
                            });
                        }
                        return field;
                    });
                    return { ...prev, schema: updatedSchema };
                });
            }
        }
        if(e.name=== "date"){
            setDateRange(e.value)
        }
        if(e.name==="peak_hour_card"){
            API_GET_CALL_INSIGHTS({startDate:dateRange[0],endDate:dateRange[1],name:"peak hour"})
        }
        if(e.name==="call_completion_card"){
            API_GET_CALL_INSIGHTS({startDate:dateRange[0],endDate:dateRange[1],name:"call completion rate"})
        }
        if(e.name==="call_frequency_card"){
            API_GET_CALL_INSIGHTS({startDate:dateRange[0],endDate:dateRange[1],name:"caller frequency"})
        }
    }
    
  return (
    <div className=''>
        {loading && <LoadingState/>}
      <ElementExecutor
        data={schema}
        selectedRecord={(e: any) => {
          handleSelectedRecord(e);
        }}/>
    </div>
  )
}

export default CallsInsights
