import { ElementExecutor } from '@apexcura/core'
import React, { useEffect, useState } from 'react'
import Utils from '../../utils'
import { CONSTANTS } from "../../utils/app-constants";
import { toast } from 'react-toastify';
import moment from 'moment'
import { useNavigate } from 'react-router-dom';
import LoadingState from '../LoadingState';

const VISIBLE_SLOTS=35
const { AUTO_CLOSE } = CONSTANTS

const schemaObj = ()=>{
    return{
        className:'flex flex-col gap-5',
        schema:[
            {
                name:'filter-section',
                className:'grid grid-cols-4 justify-between',
                fields:[
                    {
                        name:'date-select',
                        element:'datepicker',
                        label:"Date",
                        enabled_dates: "from_today"
                    },
                    {
                        name:'branch-select',
                        element:'single-select',
                        label:"Branch",
                        containerClassName:'w-[80%]',
                        className:'w-[100%]',
                        options:[],
                    },
                    {
                        name:'doctor-filter',
                        element:'single-select',
                        label:"Doctor",
                        containerClassName:'w-[80%]',
                        className:'w-[100%]',
                        options:[
                            {
                                label:"Hi",
                                value:'Hi'
                            }
                        ]
                    },
                    {
                        name:'department-filter',
                        element:'single-select',
                        label:"Department",
                        containerClassName:'w-[80%]',
                        className:'w-[100%]',
                        options:[
                        ]
                    }
                ]
            },
            {
                name:'appointment-section',
                className:'flex flex-col gap-2 max-h-[50vh] bg-white overflow-y-scroll',
                fields:[

                ]
            },
            {
                name:'add-patient-section',
                className:'flex flex-col gap-2 bg-white p-4',
                fields:[]
            },
            {
                name:'book-appointment-button',
                element:'button',
                className:'bg-primary text-white rounded-md self-end p-2',
                label:'Book Appointment'
            }
        ]
    }
}

export const AddAppointments = () => {
    const [loading,setLoading] = useState(false)
    const [schema,setSchema] = useState<any>(schemaObj())
    const [doctorsList,setDoctorsList] = useState<any[]>([])
    const [departmentsList,setDepartmentsList] = useState<any>([])
    const [patientId,setPatientId] = useState<string>('')
    const [branchId,setBranchId] = useState<string>('')
    const [patientsList,setPaitentsList] = useState<any>([])
    const [currentSlot,setCurrentSlot] = useState<any>()
    const [currentDoctors,setCurrentDoctors] = useState<any[]>([])
    const [currentDate,setCurrentDate] = useState(new Date().toLocaleDateString('en-GB').split('/').join('-'))

    const navigate=useNavigate()

    useEffect(()=>{
        if(!branchId) return
        (async()=>{
            await Get_Departments_List()
            const patients = await Get_Patients_List()
            setPaitentsList(patients)
            
        })()
    },[branchId])

    useEffect(()=>{
        resetPatientForm()
    },[patientsList])

    useEffect(()=>{
        (async()=>{
            const branches = await Get_User_Branches()
            setBranchId(branches[0].branch_id)
            setSchema((prev:any)=>{
                return{
                    ...prev,
                    schema:prev.schema.map((field:any)=>{
                        if(field.name === 'filter-section'){
                            return{
                                ...field,
                                fields:field.fields.map((f:any)=>{
                                    if(f.name === 'branch-select'){
                                        return{
                                            ...f,
                                            options:branches.map((branch:any)=>{
                                                return{
                                                    label:branch.branch_name,
                                                    value:branch.branch_id
                                                }
                                            }),
                                            value:{
                                                label:branches[0].branch_name,
                                                value:branches[0].branch_id
                                            }
                                        }
                                    }
                                    return f
                                })
                            }
                        }
                        return field
                    })
                }
            })
        })()
    },[])

    useEffect(()=>{
        if(!currentSlot) return
        setSchema((prev:any)=>{
            return{
                ...prev,
                schema:prev.schema.map((field:any)=>{
                    if(field.name === 'appointment-section'){
                        return{
                            ...field,
                            fields : field.fields.map((ff:any)=>{
                                if(ff.name === 'slots-container'){
                                    return{
                                        ...ff,
                                        fields:ff.fields.map((f:any)=>{
                                            if(f.name === 'slot-button-grid'){
                                                return{
                                                    ...f,
                                                    fields:f.fields.map((ff:any)=>{
                                                        if(ff.doctorId === currentSlot.doctorId && 
                                                            ff.startTime === currentSlot.startTime && 
                                                            ff.endTime === currentSlot.endTime &&
                                                            ff.date === currentSlot.date){
                                                            ff.className = 'slot-button-active'
                                                        }else{
                                                            if(ff.className!=='hidden' && ff.name!=='expand-slots-button'){
                                                                ff.className = 'slot-button'
                                                            }
                                                        }
                                                        return ff
                                                    })
                                                }
                                            }
                                            return f
                                        })
                                    }
                                }
                                return ff
                            })
                        }
                    }
                    return field
                })
            }
        })
    },[currentSlot])

    useEffect(()=>{
        if(!departmentsList) return
        (async()=>{
            const docs = await Get_Doctors_List('')
            setCurrentDoctors(docs)
        })()
        setSchema((prev:any)=>{
            return{
                ...prev,
                schema:prev.schema.map((field:any)=>{
                    if(field.name === 'filter-section'){
                        return{
                            ...field,
                            fields:field.fields.map((f:any)=>{
                                if(f.name === 'department-filter'){
                                    f.options = [{
                                        label:"All",
                                        value:"ALL"
                                    },...departmentsList.map((doctor:any)=>{
                                        return {
                                            label:doctor.name,
                                            value:doctor.id
                                        }
                                    })]
                                        f.value = {
                                            label:'All',
                                            value:"ALL"
                                        }
                                    
                                    
                                }
                                if(f.name === 'date-select'){
                                    f.value = new Date().toLocaleDateString('en-GB').replace(/\//g, '-')
                                }
                                return f;
                            })
                        }
                    }
                    return field
                })
            }
        })
    },[departmentsList])

    useEffect(()=>{
        setSchema((prev:any)=>{
            return{
                ...prev,
                schema:prev.schema.map((field:any)=>{
                    if(field.name === 'filter-section'){
                        return{
                            ...field,
                            fields:field.fields.map((f:any)=>{
                                if(f.name === 'doctor-filter'){
                                    f.options = [{
                                        label:'All',
                                        value:"ALL"
                                    },...doctorsList.map((doctor:any)=>{
                                        return {
                                            label:doctor.name,
                                            value:doctor.id
                                        }
                                    })]
                                    if(doctorsList.length!=0){
                                        f.value = {
                                            label:'All',
                                            value:'ALL'
                                        }
                                    }
                                    
                                }
                                return f;
                            })
                        }
                    }
                    return field
                })
            }
        })
    },[doctorsList])

    useEffect(()=>{
        (async()=>await updateSlots())()
    },[currentDoctors,currentDate])

    const updateSlots = async()=>{
        setLoading(true)
        const fields = await Promise.all(currentDoctors.map(async(doctor:any)=>{
            let num = 0
            const slots = await Get_Available_Slots(doctor.id)
            return{
                className:'flex flex-col p-4 border-b-2 border-gray-200 rounded-sm',
                name:'slots-container',
                doctorId:doctor.id,
                fields:[
                    {
                        className:'grid grid-cols-3 pl-2 pr-4 pb-2 items-center',
                        fields:[
                            {
                                element:'div',
                                label:<span><span className='text-[#00796B]'>{doctor.name}</span><span className='text-[#4B5A61]'> - {doctor.department?.dept_name}</span></span>,
                                className:'text-xl'
                            },
                            {
                                element:'div',
                                label:<span><span>Available Slots: </span><span className='font-semibold'>{slots.length}</span></span>,
                                className:'text-md justify-self-center'
                            },
                            {
                                element:'div',
                                label:<span><span>Slot Size: </span><span className='font-semibold'>{doctor.slotSize} minutes</span></span> ,
                                className:'text-md justify-self-end'
                            }
                        ]
                    },
                    slots.availableSlots.length!=0?{
                        name:'slot-button-grid',
                        className:'grid grid-cols-18 bg-white min-h-[30px] justify-items-center',
                        fields:[...slots.availableSlots.map((duration:any)=>{
                            const defaultStyle = 'slot-button'
                            const style = currentDoctors.length==1?defaultStyle:num<VISIBLE_SLOTS?defaultStyle:'hidden'
                            num++
                            return {
                                name:'slot-button',
                                element:'button',
                                label:duration.startTime,
                                doctorId:doctor.id,
                                className:style,
                                date:slots.date,
                                duration:doctor.slotSize,
                                startTime:duration.startTime,
                                endTime:duration.endTime
                            }
                        }),currentDoctors.length!==1 && slots.availableSlots.length>VISIBLE_SLOTS?{
                            name:'expand-slots-button',
                            element:'button',
                            doctorId:doctor.id,
                            label:'View All',
                            className:'font-semibold text-primary justify-self-end underline underline-offset-2',
                            status:'view'
                        }:{}]
                    }:{
                        element:'div',
                        className:'p-4',
                        label:'No available slots'
                    }
                ]

            }
            
        }))
        setLoading(false)
        setSchema((prev:any)=>{
            return{
                ...prev,
                schema:prev.schema.map((field:any)=>{
                    if(field.name === 'appointment-section'){
                        return{
                            ...field,
                            fields:fields
                        }
                    }
                    return field
                })
            }
        })
    }
    
    const patientDetailsForm = (patients:any)=>{
        const optionsList = patients.map((patient:any)=>{
            return{
                label:`${patient.name}     ${patient.phoneNumber}`,
                value:patient.id
            }
        })
        return [
            {
                element:'div',
                label:"Patient Details",
                className:'font-bold'
            },
            {
                name:'patient-select',
                element:'single-select',
                options:optionsList,
                containerClassName:'w-[80%]',
                className:'w-[100%]',
                label:'Select Patient',
                dropdownRender: (items:any)=>{
                    return(
                        <>
                        {items}
                        <div className='flex justify-center items-center gap-2 border-t-2 p-2'>
                            <button className='text-primary' onClick={openPatientForm}>+ Add Patient</button>
                        </div>
                        </>
                    )
                }
            },
            {
                name:'form-section',
                className:'grid grid-cols-5 gap-2',
                visible:false,
                fields:[
                    {
                        name:'mobile-input',
                        element:'input-number',
                        options:optionsList,
                        prefix:'+91',
                        containerClassName:'w-[80%]',
                        className:'w-[100%]',
                        label:'Mobile'
                    },
                    {
                        name:'name-input',
                        element:'input-text',
                        label:'Name',
                        containerClassName:'w-[80%]'
                    },
                    {
                        name:'dob-input',
                        element:'datepicker',
                        label:'Date of Birth',
                        placeholder:'Select date',
                        containerClassName:'w-[80]',
                        value:null
                    },
                    {
                        name:'gender-input',
                        element:'single-select',
                        containerClassName:'w-[80%]',
                        className:'w-full',
                        label:'Gender',
                        options:[
                            {
                                label:"Male",
                                value:'Male'
                            },
                            {
                                label:"Female",
                                value:'Female'
                            },
                            {
                                label:"Others",
                                value:'Others'
                            }
                        ]
                    },
                    {
                        name:'age-input',
                        element:'input-number',
                        containerClassName:'w-[30%]',
                        className:'w-full',
                        label:'Age',
                    }
                ]
            }
        ]
    }

    const openPatientForm = ()=>{
        setPatientId('')
        setSchema((prev:any)=>{
            return{
                ...prev,
                schema:prev.schema.map((field:any)=>{
                    if(field.name === 'add-patient-section'){
                        return{
                            ...field,
                            fields:field.fields.map((f:any)=>{
                                if(f.name === 'patient-select'){
                                    f.value = []
                                }
                                if(f.name === 'form-section'){
                                    f.visible = true
                                }
                                return f
                            })
                        }
                    }
                    return field
                })
            }
        })
    }

    const resetPatientForm = ()=>{
        setSchema((prev:any)=>{
            return{
                ...prev,
                schema:prev.schema.map((field:any)=>{
                    if(field.name === 'add-patient-section'){
                        return {
                            ...field,
                            fields:patientDetailsForm(patientsList)
                        }
                    }
                    return field
                })
            }
        })
    }

    const handleShowPopup = async() =>{
        let name='',phoneNumber='',dob='',gender='',age=''
        const patient = patientsList.find((patient:any)=>patientId === patient.id)
        if(patient){
            name = patient.name
            phoneNumber = patient.phoneNumber
            dob = patient.birthDate
            gender = patient.gender
            if(patient.birthDate){
                dob = new Date(patient.birthDate).toLocaleDateString('en-GB').replace(/\//g, '-')
                const birthDay = new Date(patient.birthDate); // Parse the date string into a Date object
                const today = new Date(); // Get the current date
                const tempAge = today.getFullYear() - birthDay.getFullYear(); // Calculate the year difference
                const monthDifference = today.getMonth() - birthDay.getMonth();
                const dayDifference = today.getDate() - birthDay.getDate();
        
                if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
                    age = (tempAge - 1).toString();
                } else {
                    age = tempAge.toString();
                }
            }
        }else{
            const patientDetails = findPatientDetails()
            name = patientDetails.name
            phoneNumber = patientDetails.mobile
            dob = patientDetails.dob
            gender = patientDetails.gender
            age = patientDetails.age
        }

        const doctor = currentDoctors.find((doctor:any)=>currentSlot.doctorId === doctor.id)
        
        const appointmentPopup = {
          name:"appointment_popup",
          element:"modal",
          containerClassName:'w-[20%]',
          visible:true,
          fields: [
            {
                name: 'modal_title',
                className:
                    'w-full bg-[#F2F2F2] p-2 pl-4 flex items-center rounded-t-lg justify-between',
                fields: [
                    {
                        name: 'modal_title_key',
                        element: 'div',
                        label: 'Appointment details',
                        className:
                            'font-semibold text-lg text-primary',
                    },
                    {
                        name: 'modal_close',
                        element: 'button',
                        label: 'X',
                        className:
                            'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
                    },
                ],
            },
            {
                name: 'modal_body',
                className:
                    'flex flex-col max-h-[400px] p-4 overflow-auto gap-6',
                fields: [
                  {
                    className:'flex flex-col gap-6 w-[50%]',
                    fields:[
                        {
                            className:'grid grid-cols-4',
                            fields:[
                                {
                                    element:'div',
                                    label:'Date: ',
                                    className:'text-[#69757B]'
                                },
                                {
                                    element:'div',
                                    label:`${currentSlot.date}`,
                                    className:'col-span-3 text-[#37474F]'
                                },
                                {
                                    element:'div',
                                    label:'Time: ',
                                    className:'text-[#69757B]'
                                },
                                {
                                    element:'div',
                                    label:`${currentSlot.startTime}-${currentSlot.endTime} (${currentSlot.duration} minutes)`,
                                    className:'col-span-3 text-[#37474F]'
                                },
                            ]
                        },
                        {
                            className:'grid grid-cols-4',
                            fields:[
                                {
                                    element:'div',
                                    label:'Doctor: ',
                                    className:'text-[#69757B]'
                                },
                                {
                                    element:'div',
                                    label:`${doctor.name}`,
                                    className:'col-span-3 text-[#37474F]'
                                },
                                {
                                    element:'div',
                                    label:'Dept: ',
                                    className:'text-[#69757B]'
                                },
                                {
                                    element:'div',
                                    label:`${doctor.department.dept_name}`,
                                    className:'col-span-3 text-[#37474F]'
                                },
                            ]
                        },
                        {
                            className:'grid grid-cols-4',
                            fields:[
                                {
                                    element:'div',
                                    label:'Patient: ',
                                    className:'text-[#69757B]'
                                },
                                {
                                    element:'div',
                                    label:`${name}`,
                                    className:'col-span-3 text-[#37474F]'
                                },
                                phoneNumber?{
                                    element:'div',
                                    label:'Mobile: ',
                                    className:'text-[#69757B]'
                                }:{},
                                phoneNumber?{
                                    element:'div',
                                    label:`${phoneNumber}`,
                                    className:'col-span-3 text-[#37474F]'
                                }:{},
                                dob?{
                                    element:'div',
                                    label:'DOB: ',
                                    className:'text-[#69757B]'
                                }:{},
                                dob?{
                                    element:'div',
                                    label:`${dob}`,
                                    className:'col-span-3 text-[#37474F]'
                                }:{},
                                age?{
                                    element:'div',
                                    label:'Age: ',
                                    className:'text-[#69757B]'
                                }:{},
                                age?{
                                    element:'div',
                                    label:`${age}`,
                                    className:'col-span-3 text-[#37474F]'
                                }:{},
                                gender?{
                                    element:'div',
                                    label:'Gender: ',
                                    className:'text-[#69757B]'
                                }:{},
                                gender?{
                                    element:'div',
                                    label:`${gender}`,
                                    className:'col-span-3 text-[#37474F]'
                                }:{},
                            ]
                        }
                    ]
                  },
                  {
                    className:'flex items-center gap-2 justify-between border-t-2 pt-4 pl-2 pr-2 gap-6',
                    fields:[
                        {
                            element:'button',
                            name:'cancel-appointment',
                            label:"Cancel",
                            className:"px-2 py-2 bg-[#FFF1F5] text-[#E41B47] border-2 border-[#F396AA] rounded-md w-[70%]",
                        },
                        {
                            element:'button',
                            name:'confirm-appointment',
                            label:"Confirm Appointment",
                            className:"px-2 py-2 bg-primary text-white rounded-md w-[70%]",
                        }
                    ]
                  },
                ],
            },
        ],
        }
        setSchema((prev:any)=>{
          
          return{
            ...prev,
            schema:[...prev.schema, appointmentPopup]
          }
        })
      }
    
    const handleClosePopup = async()=>{
        setSchema((prev:any)=>{
            const filteredSchema = prev.schema.filter((field: any) => field.name !== "appointment_popup");
            return{
            ...prev,
            schema:filteredSchema
            }
        })
    }

    const findPatientDetails = ()=>{
        let ret = {mobile:'',name:'',dob:'',age:'',gender:''}
        schema.schema.map((field:any)=>{
            if(field.name === 'add-patient-section'){
                field.fields.map((f:any)=>{
                    if(f.name === 'form-section'){
                        f.fields.map((ff:any)=>{
                            if(ff.name === 'mobile-input'){
                                ret = {...ret,mobile:ff.value}
                            }
                            if(ff.name === 'name-input'){
                                ret = {...ret,name:ff.value}
                            }
                            if(ff.name === 'dop-input'){
                                ret = {...ret,dob:ff.value}
                            }
                            if(ff.name === 'gender-input'){
                                ret = {...ret,gender:ff.value}
                            }
                            if(ff.name === 'age-input'){
                                ret = {...ret,age:ff.value}
                            }
                        })
                    }
                })
            }
        })
        return ret
    }

    const isValidAppointment = ()=>{
        if(!currentSlot){
            toast.error('Please select a valid slot', { position: "top-center", autoClose: AUTO_CLOSE });
            return false
        }
        const {name,mobile} = findPatientDetails()
        if(!patientId && !(name && mobile)){
            toast.error('Please select a valid patient', { position: "top-center", autoClose: AUTO_CLOSE });
             return false
        }

        return true
    }

    const Get_Departments_List = async()=>{
        const response = await Utils.makeApiCall(`/departmentsDropdown?branch_id=${branchId}`,'GET')
        const list = response.data.map((entry:any)=>{
            return{
                id:entry._id,
                name:entry.dept_name
            }
        })
        setDepartmentsList(list)
        console.log(response)
    }

    const Get_Doctors_List = async(departmentId:string)=>{
        const response = await Utils.makeApiCall(`/doctors?departmentId=${departmentId}&branchId=${branchId}`,'GET')
        const list = response.data.map((entry:any)=>{
            return{
                id:entry._id,
                name:entry.name,
                department:entry.department_id,
                slotSize:entry.slot_size,
            }   
        })
        setDoctorsList(list)
        return list
    }

    const Get_Patients_List = async()=>{
        const response = await Utils.makeApiCall(`/patient?branchId=${branchId}&download=true`,'GET')
        const list = response.data.map((entry:any)=>{
            return{
                id:entry._id,
                name:entry.name[0]?.text,
                phoneNumber:entry?.telecom[0]?.value,
                gender:entry.gender,
                birthDate:entry.birthDate || entry.date_of_birth
            }
        })
        return list
    }

    const Get_Available_Slots = async(doctorId:string)=>{
        const response = await Utils.makeApiCall(`/appointments/getAvailableSlots?doctorId=${doctorId}&date=${currentDate}`,'GET')
        return response
    }

    const Get_User_Branches = async()=>{
        const response = await Utils.makeApiCall(`/getUserBranches`,'GET')
        return response.data
    }

    const Post_Appointment_Add = async()=>{
        const {name,mobile,dob,gender,age} = findPatientDetails()
        const response = await Utils.makeApiCall(`/appointments`,'POST',{
            doctorId:currentSlot.doctorId,
            startTime:currentSlot.startTime,
            endTime:currentSlot.endTime,
            patientId:patientId,
            name:name,
            mobile:mobile,
            dob:dob,
            gender:gender,
            age:age,
            branchId:branchId,
            date:currentSlot.date
        })
        return response
    }

    const handleSelectedRecord = async(e:any)=>{
        console.log(e)
        if(e.name === 'doctor-filter'){
            if(e.value.value === 'ALL'){
                setCurrentDoctors(doctorsList)
            }else{
                setCurrentDoctors(doctorsList.filter((doc)=>{
                    return doc.id === e.value.value
                }))
            }
        }
        if(e.name === 'branch-select'){
            setBranchId(e.value.value)
        }
        if(e.name === 'department-filter'){
            if(e.value.value === 'ALL'){ 
                const docs = await Get_Doctors_List('')
                setCurrentDoctors(docs)
            }
            else{
                const docs = await Get_Doctors_List(e.value.value)
                setCurrentDoctors(docs)
                setSchema((prev:any)=>{
                    return{
                        ...prev,
                        schema:prev.schema.map((field:any)=>{
                            if(field.name === 'filter-section'){
                                return{
                                    ...field,
                                    fields:field.fields.map((f:any)=>{
                                        if(f.name === 'doctor-filter'){
                                            return{
                                                ...f,
                                                value:{
                                                    label:'',
                                                    value:''
                                                }
                                            }
                                        }
                                        return f
                                    })
                                }
                            }
                            return field
                        })
                    }
                })
            }
        }
        if(e.name === 'date-select'){
            setCurrentDate(e.value)
            if(!e.value){
                setCurrentDate(new Date().toLocaleDateString('en-GB').split('/').join('-'))
            }
        }
        if(e.name === 'slot-button'){
            setCurrentSlot({
                doctorId:e.doctorId,
                date:e.date,
                startTime:e.startTime,
                endTime:e.endTime,
                duration:e.duration
            })
        }
        if(e.name === 'modal_close' || e.name === 'cancel-appointment'){
            handleClosePopup()
        }
        if(e.name === 'confirm-appointment'){
            if(!isValidAppointment()) {
                return
            }
            const res = await Post_Appointment_Add()
            if(!res.error){
                // handleClosePopup()
                // updateSlots()
                // resetPatientForm()
                navigate('/appointments')
                toast.success(res.message, { position: "top-center", autoClose: AUTO_CLOSE });
            }
        }
        if(e.name === 'patient-select'){
            setPatientId(e.value.value)
            const patient = patientsList.find((patient:any)=>{
                return patient.id === e.value.value
            })
            let age:string;
            if(!patient.birthDate){
                age = ''
            }else{
                const birthDate = new Date(patient.birthDate); // Parse the date string into a Date object
                const today = new Date(); // Get the current date
                const tempAge = today.getFullYear() - birthDate.getFullYear(); // Calculate the year difference
                const monthDifference = today.getMonth() - birthDate.getMonth();
                const dayDifference = today.getDate() - birthDate.getDate();
    
                if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
                    age = (tempAge - 1).toString();
                } else {
                    age = tempAge.toString();
                }
            }
            
            setSchema((prev:any)=>{
                return{
                    ...prev,
                        schema:prev.schema.map((field:any)=>{
                        if(field.name === 'add-patient-section'){
                            return {
                                ...field,
                                fields:field.fields.map((f:any)=>{
                                    if(f.name === 'form-section'){
                                        f.visible = false
                                    }
                                    return f
                                })
                            }
                        }
                        return field
                    })
                }
            })
        }
        if(e.name === 'add-new-patient-button'){
            navigate('/patients/add')
        }
        if(e.name === 'book-appointment-button'){
            // const response = await Post_Appointment_Add()
            // if(!response.error){
            //     await updateSlots()
            //     resetPatientDetailsForm()
            // }
            if(!isValidAppointment()) {
                return
            }
            handleShowPopup()
        }
        if(e.name === 'expand-slots-button'){
            const status = e.status
            setSchema((prev:any)=>{
                return{
                    ...prev,
                    schema:prev.schema.map((field:any)=>{
                        if(field.name === 'appointment-section'){
                            return{
                                ...field,
                                fields : field.fields.map((ff:any)=>{
                                    if(ff.name === 'slots-container' && ff.doctorId === e.doctorId){
                                        return{
                                            ...ff,
                                            fields:ff.fields.map((f:any)=>{
                                                if(f.name === 'slot-button-grid' && e.status === 'view'){
                                                    return{
                                                        ...f,
                                                        fields:f.fields.map((ff:any)=>{
                                                            if(ff.className === 'hidden'){
                                                                ff.className = 'slot-button'
                                                            }
                                                            if(ff.name === 'expand-slots-button'){
                                                                ff.status = 'hide'
                                                                ff.label = 'Hide'
                                                            }
                                                            return ff
                                                        })
                                                    }
                                                }
                                                if(f.name === 'slot-button-grid' && e.status === 'hide'){
                                                    let num = 0;
                                                    return{
                                                        ...f,
                                                        fields:f.fields.map((ff:any)=>{
                                                            if(num<VISIBLE_SLOTS) {
                                                                num++ 
                                                                return ff
                                                            }
                                                            
                                                            if(ff.name === 'expand-slots-button'){
                                                                ff.status = 'view'
                                                                ff.label = 'View All'
                                                            }else{
                                                                ff.className = 'hidden'
                                                            }
                                                            num++
                                                        
                                                            return ff
                                                        })
                                                    }
                                                }
                                                return f
                                            })
                                        }
                                    }
                                    return ff
                                })
                            }
                        }
                        return field
                    })
                }
            })
        }
    }
    return (
        <div>
            {loading && <LoadingState/>}
            <ElementExecutor data={schema} selectedRecord={handleSelectedRecord}/>
        </div>
    )
}