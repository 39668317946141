import { ElementExecutor } from '@apexcura/core';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CONSTANTS } from '../../utils/app-constants';
import Utils from '../../utils';
import LoadingState from '../LoadingState';
import Storage from '../../utils/local-storage';
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;

const AddUsers = () => {
    const [schema, setSchema] = useState<any>({
      className: "w-full bg-white rounded-lg p-8 flex flex-col gap-3 items-center",
      schema: []
    });
    const navigate=useNavigate();
    const [loading,setLoading]=useState(false)
    const params = useParams();
    const userId = params?.userId || "";
    const { pathname } = useLocation();
    const slugs = pathname.split("/");
    const isView = slugs.includes("view");

    const org_id = Storage.getItem("details")?.["org_id"];

    useEffect(() => {
      const schemaArr =  [
        {
          name: "data_fields",
          className: "w-full flex flex-col gap-4",
          fields: [
            {
              name:"name_email_password_fields",
              className:"grid grid-cols-3 gap-2 ",
              fields:[
                {
                  name: "fullname",
                  element: "input-text",
                  placeholder: "",
                  label: "Name",
                  containerClassName: "w-full flex flex-col",
                  labelClassName:
                    "text-sm mb-1 text-gray-500 font-semibold text-start",
                  className:
                    "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                  required: true,
                  visible: true,
                },
                {
                  name: "email",
                  element: "input-text",
                  placeholder: "",
                  label: "Email",
                  containerClassName: "w-full flex flex-col ",
                  labelClassName:
                    "text-sm mb-1 text-gray-500 font-semibold text-start",
                  className:
                    "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                  required: true,
                  visible: true,
                },
                {
                  name: "password",
                  element: "input-password",
                  placeholder: "",
                  label: "Password",
                  containerClassName: "w-full flex flex-col ",
                  labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                  className:
                    "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                  required: true,
                  visible: true,
                  
                },
                {
                  name: "phonenumber",
                  element: "input-number",
                  placeholder: "",
                  label: "Mobile",
                  containerClassName: "w-full flex flex-col",
                  labelClassName:
                    "text-sm mb-1 text-gray-500 font-semibold text-start",
                  className:
                    "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                  required: true,
                  visible: true,
                },
                {
                  name: "designation",
                  element: "single-select",
                  placeholder: "",
                  label: "Designation",
                  containerClassName: "w-full flex flex-col col-span-2",
                  labelClassName:
                    "text-sm mb-1 text-gray-500 font-semibold text-start",
                  className:
                    "rounded-md  text-gray-700 font-semibold focus:outline-none",
                  required: true,
                  visible: true,
                  endPoint:"/user-designations",
                  options:[]
                },
                {
                  name: "branches",
                  element: "single-select",
                  placeholder: "",
                  label: "Branch(es)",
                  containerClassName: "w-full flex flex-col col-span-full",
                  labelClassName:
                    "text-sm mb-1 text-gray-500 font-semibold text-start",
                  className:
                    "rounded-md  text-gray-700 font-semibold focus:outline-none",
                  required: true,
                  visible: true,
                  mode: "multiple",
                  value:null,
                  endPoint: "/getUserBranches",
                  options:[]
                },
                {
                  name: "access_policies",
                  element: "single-select",
                  placeholder: "",
                  label: "Permission(s)",
                  containerClassName: "w-full flex flex-col col-span-full",
                  labelClassName:
                    "text-sm mb-1 text-gray-500 font-semibold text-start",
                  className:
                    "rounded-md  text-gray-700 font-semibold focus:outline-none",
                  required: true,
                  visible: true,
                  mode: "multiple",
                  value:null,
                  endPoint: "/getAllPoliciesToAssignUsers",
                  options:[]
                },
              ]
            },
            {
              name:"access_policies_permissions",
              className:"flex flex-col gap-2",
              visible: false,
              fields:[
                {
                  name:"access_policies_label",
                  label:"Policy Permissions",
                  className:"text-sm mb-1 text-gray-500 font-semibold text-start",
                  element:"div"
                },
                {
                  className: "",
                  name: "table",
                  element: "table",
                  variant: "plain",
                  size: "small",
                  count: 0,
                  visible: true,
                  loading: false,
                  thead: [
                      { name: "page_name", label: "Page", key: "page_name" },
                      { name: "readonly", label: "Read Only", key: "readonly" },               
                      { name: "view", label: "View", key: "view" },               
                      { name: "add", label: "Add", key: "add" },               
                      { name: "edit", label: "Edit", key: "edit" },               
                      { name: "delete", label: "Delete", key: "delete" },               
                      { name: "download", label: "Download", key: "download" },               
                  ],
                  tbody: [
                    
                  ],
                },
              ]
            }
          ],
        },
        {
          name: "cancel_and_submit",
          fields: [
            {
              name: "cancel",
              label: "Cancel",
              element: "button",
              className:
                "p-2 px-3 text-default-500 bg-gray-200 text-black rounded-md font-semibold",
            },
            {
              name: "submit",
              label: "Submit",
              element: "button",
              visible: !isView,
              className:
                "p-2 px-3 text-default-500 bg-[#3341bb] rounded-md text-white font-semibold",
            },
          ],
          className: "flex self-end gap-2 mt-4",
        },
      ];
      const processFields = (obj: any) => {
        if (Array.isArray(obj)) {
          obj.forEach(processFields);
        } else if (obj && typeof obj === 'object') {
          if (obj.endPoint || obj.firstEndpoint) {
            API_GET_Options({ name: obj.name, url: obj.endPoint || obj.firstEndpoint });
          }
          Object.values(obj).forEach(processFields);
        }
      };
      schemaArr.forEach(processFields);
      setSchema((prev: any) => {
        return { ...prev, schema: schemaArr }
      })
    }, [])

    const API_GET_Options = async ({ name = "", url = "" }) => {
      setLoading(true);
      try {
        const res = await Utils.makeApiCall(url, "GET");
        if (res.status) {
          setSchema((prev: any) => {
            const updateFields: any = (obj: any) => {
              if (Array.isArray(obj)) return obj.map(updateFields);
              if (obj && typeof obj === "object") {
                if (obj.name === name) {
                  let options = [];
                  if (name === "access_policies") {
                    options = res.data.map((op: any, index: number) => ({ ...op, key: index, page_name: op.label, value: op.role_id, label: op.role }));
                  } else if (name === "branches") {
                    options = res.data.map((op: any) => ({ value: op.branch_id, label: op.branch_name }));
                  }else if (name === "designation") {
                    options = res.data.map((op:any)=>({label:op.label,value:JSON.stringify(op.value)}))
                  } else {
                    options = res.data.map((op: any) => ({ value: op.id, label: op.name }));
                  }
                  obj.options = options;
                }
                Object.values(obj).map(updateFields);
              }
            };
  
            updateFields(prev.schema);
            return { ...prev };
          });
        } else {
          toast.error(res.message, { position: "top-center", autoClose: 2000 });
        }
      } catch (error) {
        console.error("Failed to fetch schema", error);
      }
  
      setLoading(false);
    };
    

    useEffect(() => {
      if (userId) {
          API_GET_Details(userId);
      }
  }, [userId]);

    const API_GET_Details=async (userId:string)=>{
      setLoading(true);
      try {
        const res = await Utils.makeApiCall(`/user-management/${userId}/${org_id}`,'GET')
        if (res.status) 
        {
          handleGetDetails(res.data);
        }
        else {
            toast.error(res.message ? res.message : FAILURE_MSG, {
                position: 'top-center',
                autoClose: AUTO_CLOSE,
            })
        }
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, {
              position: 'top-center',
              autoClose: AUTO_CLOSE,
          })
          console.error('Failed to fetch schema', error)
      }
      setLoading(false);
    }
    const handleGetDetails=(data:any)=>{
      let access_policies:any = [];
      const dap = data?.access_policies || [];
      const branches = data?.branches || [];
      setSchema((prev:any)=>{
        const updatedSchema=prev.schema.map((field:any)=>{
            if(field.name==="data_fields"){
              field.fields=field.fields.map((ffield:any)=>{
                if(ffield.name==="name_email_password_fields"){
                  ffield.className="grid grid-cols-3 gap-2";
                  ffield.fields=ffield.fields.map((fffield:any)=>{
                    if(fffield.name==="access_policies"){
                      fffield.value=dap.map((ee:any)=>({value: ee.role_id, label: ee.role}))
                      access_policies = fffield.options;
                      fffield.containerClassName= "w-full flex flex-col col-span-3 "
                    } else if(fffield.name==="branches"){
                      fffield.value=branches.map((ee:any)=>({value: ee.branch_id, label: ee.branch_name}))
                      fffield.containerClassName= "w-full flex flex-col col-span-3 "
                    } else if(fffield.name==="password"){
                      fffield.visible=false
                      fffield.required=false
                    } else if(fffield.name==="designation"){
                      fffield.visible=true
                      fffield.required=true
                      fffield.containerClassName="w-full flex flex-col col-span-full"
                      fffield.value={value:data?.designation_id,label: data?.designation_name}
                    } else {
                      fffield.value=data[fffield.name]
                    }
                    fffield.disabled = isView;
                    return fffield;
                  })
                }
                return ffield;
              })
            }
          return field;
        })
        const policies = dap.map((op:any)=>{
          const policy = access_policies.find((oo:any)=> oo.role_id === op.role_id);
          if (policy){
            return policy;
          }
        }).filter(Boolean);
        handlePreview(policies);
        return {...prev,schema:updatedSchema}
      })
    }

    // console.log(schema)

    const API_POST_Data=async ()=>{
      setLoading(true)
      const payLoad=getPayload(schema);
      try{
        let res:any={};
        if(userId){
           res=await Utils.makeApiCall(`/user-management/${userId}`,"PUT",payLoad);
        }else{
           res=await Utils.makeApiCall("/user-management","POST",payLoad);
        }
        if(res.status){
          toast.success(res.message,{position:"top-center",autoClose:AUTO_CLOSE})
          navigate("/users")
        }else{
          toast.error(res.message ? res.message : FAILURE_MSG,{position:"top-center",autoClose:AUTO_CLOSE})
        }
      }
      catch(error){
        toast.error(CATCH_ERROR_MSG,{position:"top-center",autoClose:AUTO_CLOSE})
        console.log("Error while sending the data.",error)
      }finally{
        setLoading(false)
      }

    }

    const getPayload=(e:any):object =>{
      const payload:any={
        org_id,
        fullname:"",
        phonenumber:"",
        email:"",
        designation_id:"",
        access_policies:[],
        branches: []
      };
      const processFields = (obj: any) => {
        if (obj["fields"] && obj["fields"].length) {
          obj.fields.forEach(processFields);
        } else {
          if (["fullname", "phonenumber", "email"].includes(obj.name)){
            payload[obj.name] = obj.value;
          } else if (obj.name==="designation"){
            payload["designation_id"]=obj.value.value;
          } else if (obj.name === "password"){
            if(!userId){
              payload[obj.name]=obj.value;
            }
          } else if (["branches", "access_policies"].includes(obj.name)){
            payload[obj.name] = obj?.value?.map((ee:any)=> ee.value);
          }
        }
      };
      e.schema.forEach(processFields);      
      return payload;
    }
  
    const handleSelectedRecord = (e: any) => { 
      console.log(e)     
      if (e.name === "cancel") {
        navigate("/users");
      }
      if (e.name === "access_policies") {
        handlePreview(e.value);
      }
      if(e.name==="submit"){
        const validationResponse = Utils.handleValidation(schema.schema);
          if (validationResponse) {
              toast.error(`${validationResponse} cannot be empty`, {
                position: "top-center",
                autoClose: AUTO_CLOSE,
              });
          }
          else{
            API_POST_Data();
          }
      }
    };

    function handlePreview(e: any) {
      const routeAccessMap = new Map<string, any>();
    
      // Iterate through the records and store only the highest priority access
      e.forEach((r: any) => {
        const existingEntry = routeAccessMap.get(r?.route);
        if (!existingEntry || r?.is_full_access) {
          routeAccessMap.set(r?.route, {
            route: r?.route,
            page_name: r?.page_name,
            is_full_access: r?.is_full_access,
            readonly: r?.readonly ? "✔" : "",
            view: r?.view ? "✔" : "",
            add: r?.add ? "✔" : "",
            edit: r?.edit ? "✔" : "",
            download: r?.download ? "✔" : "",
            delete: r?.delete ? "✔" : "",
          });
        }
      });
    
      // Convert the map back to an array
      const PERMS = Array.from(routeAccessMap.values());
      setSchema((prev: any) => {
        const updatedSchema = prev.schema.map((field: any) => {
          if (field.name === "data_fields") {
            field.fields = field.fields.map((subfield: any) => {
              if (subfield.name === "access_policies_permissions") {
                if (PERMS?.length) {
                  if (subfield.fields) {
                    subfield.fields = subfield.fields.map((ffield: any) => {
                      if (ffield.name === "table") {
                        ffield.tbody = PERMS;
                      }
                      // console.log(ffield);
                      return ffield;
                    });
                  }
                  subfield.visible = true;
                } else {
                  subfield.visible = false;
                }
              }
              return subfield;
            });
          }
          return field;
        });
        return { ...prev, schema: updatedSchema };
      });
    }
  return (
    <div>
       {loading && <LoadingState/>}
      <ElementExecutor
            data={schema}
            setData={(e:any)=>setSchema({...e})}
            selectedRecord={handleSelectedRecord}
        />
    </div>
  )
}

export default AddUsers
