import { ElementExecutor } from '@apexcura/core';
import React, { useEffect, useState } from 'react'
import { unstable_usePrompt, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CONSTANTS } from '../utils/app-constants';
import Utils from '../utils';
import LoadingState from './LoadingState';
import Storage from '../utils/local-storage';
import useExitPopUp from '../hooks/useExitPopUp';
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;

const addLeads=() =>( {
    className: "w-full bg-white rounded-lg p-8 flex flex-col gap-3 items-center",
    schema: [
      {
        name: "contact_fields",
        className: "w-full flex flex-col gap-2",
        fields: [
          {
            name: "name_and_type",
            className: "grid grid-cols-2  gap-2",
            fields: [
              {
                name: "name",
                element: "input-text",
                placeholder: "",
                label: "Name",
                containerClassName: "w-full flex flex-col",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                required: true,
                visible: true,
              },
              {
                name: "mobile",
                element: "input-text",
                placeholder: "",
                label: "Mobile",
                containerClassName: "w-full flex flex-col",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                required: true,
                visible: true,
              },
              {
                name: "type",
                element: "single-select",
                placeholder: "",
                label: "Type",
                containerClassName: "w-full flex flex-col",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md  text-gray-700 font-semibold focus:outline-none",
                required: true,
                visible: true,
                disabled:true,
                options:[
                    {
                        label:"Emergency",
                        value:"emergency",
                    },
                    {
                        label:"Enquiry",
                        value:"enquiry",
                    },
                    {
                        label:"Walk In",
                        value:"walk in"
                    }
                ],
                value:{
                  label:"Walk In",
                  value:"walk in"
              }
              },
              {
                  name: "source",
                  element: "single-select",
                  placeholder: "",
                  label: "Source",
                  containerClassName: "w-full flex flex-col",
                  labelClassName:
                    "text-sm mb-1 text-gray-500 font-semibold text-start",
                  className:
                    "rounded-md  text-gray-700 font-semibold focus:outline-none",
                  required: true,
                  visible: true,
                  disabled:true,
                  options:[
                      {
                          label:"Phone",
                          value:"phone",
                      },
                      {
                          label:"Chatbot",
                          value:"chatbot",
                      },
                      {
                          label:"Whatsapp",
                          value:"whatsapp"
                      },
                      {
                          label:"Operator",
                          value:"operator"
                      }
                  ],
                  value:{
                      label:"Operator",
                      value:"operator"
                  }
              },
            ],
          },
          {
            name: "comments",
            element: "input-textarea",
            minRows:6,
            maxRows:6,
            placeholder: "",
            label: "Comments",
            containerClassName: "flex flex-col w-full gap-2",
            labelClassName: "text-sm text-gray-500 font-semibold text-start",
            className:
              "w-full rounded-md mb-4 px-3 py-2 text-gray-700 font-semibold focus:outline-none",
            visible: true,
          },
        ],
      },
      {
        name: "cancel_and_submit",
        fields: [
          {
            name: "cancel",
            label: "Cancel",
            element: "button",
            className:
              "p-2 px-3  text-default-500 bg-gray-200 text-black rounded-md font-semibold",
          },
          {
            name: "submit",
            label: "Submit",
            element: "button",
            className:
              "p-2 px-3  text-default-500 bg-[#3341bb]  rounded-md text-white font-semibold",
          },
        ],
        className: "flex self-end gap-2",
      },
    ],
});
const AddLeads = () => {

    const [schema, setSchema] = useState<any>({...addLeads()});
    const navigate=useNavigate();
    const [loading,setLoadng]=useState(false)

    const org_id = Storage.getItem("details")?.["org_id"];

    const validateData = (fields: any[]): string | false => {
      for (const field of fields) {
        if (
          field.required &&
          ((typeof field.value === "string" && field.value.trim() === "") ||
            (Array.isArray(field.value) && field.value.length === 0) ||
            field.value === null ||
            field.value === undefined)
        ) {
          return field.label;
        }
        if (field.fields) {
          const result = validateData(field.fields);
          if (result) {
            return result;
          }
        }
      }
      return false;
    };
  

    const API_POST_Data=async ()=>{
      setLoadng(true)
      const payLoad=getPayload(schema);
      try{
        const res:any=await Utils.makeApiCall("/leads/add","POST",payLoad);
        if(res.status){
          toast.success(res.message,{position:"top-center",autoClose:AUTO_CLOSE})
          navigate("/leads")
        }else{
          toast.error(res.message ? res.message : FAILURE_MSG,{position:"top-center",autoClose:AUTO_CLOSE})
        }
      }
      catch(error){
        toast.error(CATCH_ERROR_MSG,{position:"top-center",autoClose:AUTO_CLOSE})
        console.log("Error while sending the data.",error)
      }finally{
        setLoadng(false)
      }

    }

    const getPayload=(e:any):object =>{
      const  payload={
        org_id:org_id,
        name:"",
        phone_number:"",
        type:"",
        source:"",
        comments:""
      }
      payload.name=e?.schema[0]?.fields[0]?.fields[0]?.value;
      payload.phone_number=e?.schema[0]?.fields[0]?.fields[1]?.value;
      payload.type=e?.schema[0]?.fields[0]?.fields[2]?.value?.value;
      payload.source=e?.schema[0]?.fields[0]?.fields[3]?.value?.value;
      payload.comments=e?.schema[0]?.fields[1]?.value;
      return payload;
    }

    const handleSelectedRecord=(e:any)=>{
        if(e.name === "cancel"){
          navigate("/leads");
        }
        if (e.name === "submit"){
          setDetectChange(false)
          const validationResponse = validateData(schema.schema);
          if (validationResponse) {
              toast.error(`${validationResponse} cannot be empty`, {
                position: "top-center",
                autoClose: AUTO_CLOSE,
              });
          }
          else{
            API_POST_Data()
          }
        }
    }

    const [detectChange, setDetectChange] = useState(false)

    // Exit popup's custom hook
    useExitPopUp(detectChange)

  return (
    <div>
      {loading && <LoadingState/>}
      <ElementExecutor
        data={schema}
        setData={(e: any) => {
          setSchema({ ...e });
        }}
        selectedRecord={(e: any) => {
          handleSelectedRecord(e);
          if([ "name", "mobile", "type", "source", "comments"].includes(e?.name) ){
            setDetectChange(true)
          }
        }}
      />
    </div>
  )
}

export default AddLeads
