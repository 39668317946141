import { combineReducers } from '@reduxjs/toolkit';
import uiSchemaReducer from './uiSchema/uiSchemaSlice';
import campaignReducer from './campaigns/campaignSlice';
import leadReducer from './leads/leadSlice';
import authReducer from './auth/authSlice';
import compareUnitsReducer from './compareUnits/compareUnitsSlice'

const rootReducer = combineReducers({
  uiSchema: uiSchemaReducer,
  campaign: campaignReducer,
  auth: authReducer,
  lead: leadReducer,
  compareUnits:compareUnitsReducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
