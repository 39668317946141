import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompareUnitsInitState } from "./types";

const initialState: ICompareUnitsInitState = {
    modules: [],
    branches: []
}

const compareUnitsSlice=createSlice({
    name: "CompareUnits",
    initialState,
    reducers:{
        setModules:(state,action:PayloadAction<string[]>)=>{
            state.modules=action.payload
        },
        setBranches:(state,action:PayloadAction<string[]>)=>{
            state.branches=action.payload
        }
    }
})

export const {setModules,setBranches}=compareUnitsSlice.actions
export default compareUnitsSlice.reducer;