"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Sidebar = void 0;
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
const icons_1 = require("@ant-design/icons");
const icons_2 = require("../constants/icons");
const { Sider } = antd_1.Layout;
const getLevelKeys = (items1) => {
    const key = {};
    const func = (items2, level = 1) => {
        items2 &&
            items2.forEach((item) => {
                if (item.key) {
                    key[item.key] = level;
                }
                if (item.submenu) {
                    func(item.submenu, level + 1);
                }
            });
    };
    func(items1);
    return key;
};
const Sidebar = (props) => {
    const [collapsed, setCollapsed] = (0, react_1.useState)(false);
    const [selectedKey, setSelectedKey] = (0, react_1.useState)('');
    const [stateOpenKeys, setStateOpenKeys] = (0, react_1.useState)([]);
    (0, react_1.useEffect)(() => {
        if (props.value) {
            setSelectedKey(props.value);
        }
    }, [props.value]);
    function getItem(label, key, icon, children, selected, isCount, count, isNewMenuItem) {
        // Badge applied to the icon
        const iconWithStyles = icon ? (selected ? (react_1.default.createElement("span", { style: {
                filter: 'brightness(1.2) contrast(1.5) saturate(2.5) hue-rotate(200deg) sepia(1) saturate(10) hue-rotate(-160deg)',
            } }, icon)) : (react_1.default.createElement("span", null, icon))) : ('');
        const iconElement = isCount ? (react_1.default.createElement(antd_1.Badge, { size: "small", count: count },
            react_1.default.createElement("span", null, iconWithStyles))) : (iconWithStyles);
        const labelElement = react_1.default.createElement("span", null, label);
        const newBadgeElement = isNewMenuItem ? (react_1.default.createElement("div", { style: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            } },
            labelElement,
            react_1.default.createElement(antd_1.Badge, { count: 'NEW', showZero: true, color: "#3b82f6", className: "absolute right-0" }))) : (labelElement);
        return {
            key,
            icon: iconElement ? iconElement : '',
            children,
            label: newBadgeElement,
        };
    }
    const handleClick = (e) => {
        setSelectedKey(e.key);
        if (props.onChange) {
            props.onChange(e.key);
        }
        if (e.keyPath.length <= 1) {
            setStateOpenKeys([]);
        }
    };
    const renderMenuItems = (items) => {
        return items.map((eachItem) => {
            const isSelected = selectedKey === eachItem.key;
            const icon = eachItem.icon ? (react_1.default.createElement("div", { className: props.iconsClassName }, icons_2.icons[eachItem.icon])) : ('');
            const children = eachItem.submenu?.length
                ? renderMenuItems(eachItem.submenu)
                : undefined;
            return getItem(eachItem.label, eachItem.key, icon && icon, children, isSelected, eachItem.isCount, eachItem.count, eachItem.isNewMenuItem);
        });
    };
    const levelKeys = getLevelKeys((props.items || ''));
    const onOpenChange = (openKeys) => {
        const currentOpenKey = openKeys.find((key) => !stateOpenKeys.includes(key));
        // console.log(openKeys);
        if (currentOpenKey !== undefined) {
            const repeatIndex = openKeys
                .filter((key) => key !== currentOpenKey)
                .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
            setStateOpenKeys(openKeys
                .filter((_, index) => index !== repeatIndex)
                .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]));
        }
        else {
            setStateOpenKeys(openKeys);
        }
    };
    return (react_1.default.createElement(antd_1.Layout, { style: { minHeight: '100vh' } },
        react_1.default.createElement(Sider, { className: "overflow-unset", theme: "light", collapsible: true, collapsed: collapsed, trigger: null, onCollapse: (value) => setCollapsed(value) },
            react_1.default.createElement("div", { className: "custom-header" },
                react_1.default.createElement("button", { onClick: () => setCollapsed(!collapsed), className: "size-7 top-20 z-10 -right-3 rounded-full absolute text-white bg-blue-500 items-center toggle-button" }, collapsed ? react_1.default.createElement(icons_1.RightOutlined, null) : react_1.default.createElement(icons_1.LeftOutlined, null))),
            react_1.default.createElement("span", { className: props.imgClassName },
                react_1.default.createElement("img", { src: props.img, alt: "img" })),
            react_1.default.createElement("div", { className: "overflow-auto h-full pb-24" },
                react_1.default.createElement(antd_1.Menu, { theme: "light", selectedKeys: [selectedKey], mode: "inline", items: renderMenuItems(props.items || []), onClick: handleClick, inlineCollapsed: collapsed, onOpenChange: onOpenChange, openKeys: stateOpenKeys })))));
};
exports.Sidebar = Sidebar;
exports.default = exports.Sidebar;
