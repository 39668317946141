import React, { useEffect, useState } from 'react'
import {Avatar,Tooltip} from "antd";
import moment from 'moment';
import { ElementExecutor } from '@apexcura/core';
import { CONSTANTS } from '../../utils/app-constants';
import { toast } from 'react-toastify';
import Utils from '../../utils';
import Storage from '../../utils/local-storage';
import LoadingState from '../LoadingState';
const { BASE_URL } = CONSTANTS;
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS

const LeadsInsights = () => {

  const [schema, setSchema] = useState<any>({
        className:
          'w-full bg-white rounded-lg py-[10px] px-[20px] flex flex-col gap-3',
        schema: [
          {
            name: 'filters',
            fields: [
              {
                name:"date_and_search",
                className:"flex gap-2 justify-center items-center ",
                fields:[
                  {
                      name:"segments",
                      element:"segmented",
                      segmentedOptions:[
                        {
                            label:(<Tooltip title="Today">1D</Tooltip>),
                            value:"TD"
                        },
                        {
                            label:(<Tooltip title="This Week">1W</Tooltip>),
                            value:"TW"
                        },
                        {
                            label:(<Tooltip title="This Month">1M</Tooltip>),
                            value:"TM"
                        },
                      ],
                      visible:true,
                      value:"TM"
                  },
                  {
                    name: 'date',
                    label: '',
                    placeholder: 'Select a date',
                    element: 'daterangepicker',
                    value: [moment().subtract(30, 'days').format('DD-MM-YYYY'), moment().format("DD-MM-YYYY")],
                    className: 'flex-1 w-[240px]',
                  },
                ]
              },
            ],
            className: 'flex items-center justify-between gap-2',
          },
          {
            name:"anomolies_and_insights",
            className:"grid grid-cols-2 gap-2 items-start h-[calc(100vh-150px)]",
            fields:[
                {
                    name:"anomalies_table",
                    className:"flex gap-2 flex-col col-span-1 rounded-md p-2 h-full overflow-hidden",
                    fields:[
                        {
                            name:"anomalies",
                            label:"Anomalies",
                            element:"div",
                            className:"text-primary text-lg font-semibold rounded-md w-full bg-[#F2F2F2] p-2"
                        },
                        {
                            name:"anomalies_cards",
                            className:"grid grid-cols-2 gap-2 h-full overflow-y-auto",
                            fields:[]
                        }
                    ]
                  },
                  {
                    name:"actionable_insights_table",
                    className:"flex gap-2 flex-col col-span-1 rounded-md p-2 h-full overflow-hidden",
                    fields:[
                        {
                            name:"actionable_insights",
                            label:"Actionable Insights",
                            element:"div",
                            className:"text-primary text-lg font-semibold rounded-md w-full bg-[#F2F2F2] p-2"
                        },
                        {
                            name:"actionable_insights_cards",
                            className:"grid grid-cols-2 gap-2 h-full overflow-y-auto",
                            fields:[]
                        }
                    ]
                  },
            ]
          },
          {
            name: 'table_modal',
            containerClassName:"!w-[80%]",
            className: 'rounded-lg',
            visible: false,
            element: "modal",
            fields: [
                {
                    name: 'modal_title',
                    className: 'w-full bg-[#F2F2F2] p-2 pl-4 flex items-center rounded-t-lg justify-between',
                    fields: [
                        {
                            name: 'modal_title_key',
                            element: 'div',
                            label: 'Details',
                            className: 'font-semibold text-lg text-primary',
                        },
                        {
                            name: 'modal_close',
                            element: 'button',
                            label: 'X',
                            className: 'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
                            onClick: () => handleModalClose(),
                        },
                    ],
                },
                {
                    name: 'modal_header',
                    className: 'grid grid-cols-4 gap-2 shadow-sm p-2 px-4',
                    fields: [
                        {
                            name: "card1",
                            className: 'flex justify-start gap-2',
                            fields: [
                                {
                                    name: `key`,
                                    element: 'div',
                                    label: "Name:",
                                    className: 'font-medium ',
                                },
                                {
                                    name: `_val`,
                                    element: 'div',
                                    label: `John Doe`,
                                    className: 'font-semibold',
                                },
                            ],
                        },
                        {
                            name: "card2",
                            className: 'flex justify-start gap-2',
                            fields: [
                                {
                                    name: `phno_key`,
                                    element: 'div',
                                    label: "Phone Number:",
                                    className: 'font-medium',
                                },
                                {
                                    name: `phno_val`,
                                    element: 'div',
                                    label: '1234567890',
                                    className: 'font-semibold',
                                },
                            ],
                        },
                        {
                            name: "card3",
                            className: 'flex justify-start gap-2',
                            fields: [
                                {
                                    name: `gender_key`,
                                    element: 'div',
                                    label: "Gender:",
                                    className: 'font-medium',
                                },
                                {
                                    name: `gender_val`,
                                    element: 'div',
                                    label: 'Male',
                                    className: 'font-semibold',
                                },
                            ],
                        },
                        {
                            name: "card4",
                            className: 'flex justify-start gap-2',
                            fields: [
                                {
                                    name: `age_key`,
                                    element: 'div',
                                    label: "Age:",
                                    className: 'font-medium',
                                },
                                {
                                    name: `age_val`,
                                    element: 'div',
                                    label: '35',
                                    className: 'font-semibold',
                                },
                            ],
                        },
                        {
                            name: "card5",
                            className: 'flex justify-start gap-2',
                            fields: [
                                {
                                    name: `chat_start_key`,
                                    element: 'div',
                                    label: "Call Start:",
                                    className: 'font-medium',
                                },
                                {
                                    name: `chat_start_val`,
                                    element: 'div',
                                    label: '2023-10-08 10:00 AM',
                                    className: 'font-semibold',
                                },
                            ],
                        },
                        {
                            name: "card6",
                            className: 'flex justify-start gap-2',
                            fields: [
                                {
                                    name: `chat_end_key`,
                                    element: 'div',
                                    label: "Call End:",
                                    className: 'font-medium',
                                },
                                {
                                    name: `chat_end_val`,
                                    element: 'div',
                                    label: '2023-10-08 10:30 AM',
                                    className: 'font-semibold',
                                },
                            ],
                        },
                        {
                            name: "card7",
                            className: 'flex justify-start gap-2',
                            fields: [
                                {
                                    name: `chat_duration_key`,
                                    element: 'div',
                                    label: "Call Duration:",
                                    className: 'font-medium',
                                },
                                {
                                    name: `chat_duration_val`,
                                    element: 'div',
                                    label: '30 minutes',
                                    className: 'font-semibold',
                                },
                            ],
                        },
                    ]
                    
                },

            ],
          },
        ],
    });
    const [loading,setLoading]=useState(false);
    const [dateRange, setDateRange] = useState<[string, string]>([
        moment().subtract(30, 'days').format('DD-MM-YYYY'),
        moment().format('DD-MM-YYYY'),
    ])
    const details = Storage.getItem("details");

    const API_GET_Insights_Data=async ({  startDate = "", endDate = "" })=>{
        setLoading(true)
        try {
            const res: any = await Utils.makeApiCall(`/getInsightsByDateAndOrgId?org_id=${details?.["org_id"]}&start_date=${startDate}&end_date=${endDate}`, "GET");
            if (res.status) {
                if(res.data.length===0){
                    change_insights_with_no_data();
                } else{
                    change_insights_data(res.data);
                }

            } else {
                toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
            }
        } catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
            console.error('Failed to fetch schema', error);
        }
        setLoading(false)
    }

    const change_insights_data = (data: any) => {
        setSchema((prev: any) => {
            const updatedSchema = prev.schema.map((item: any) => {
                if (item.name === "anomolies_and_insights") {
                    item.fields = item.fields.map((field: any) => {
                        if (field.name === "anomalies_table" || field.name === "actionable_insights_table") {
                            const isAnomalies = field.name === "anomalies_table";
                            field.fields = field.fields.map((subfield: any) => {
                                if (subfield.name === "anomalies_cards" || subfield.name === "actionable_insights_cards") {
                                    subfield.fields = data.map((insight: any, index: number) => ({
                                        name: `${isAnomalies ? 'anomaly' : 'insight'}-item-${index}`,
                                        className: `hover:shadow-md cursor-pointer border ${isAnomalies ? 'border-[#E41B47] bg-[#FFF4F7]' : 'border-[#34D399] bg-[#ECFDF5]'} rounded-lg p-[5px] flex flex-col items-center justify-center gap-2`,
                                        element: "clickable-widget",
                                        fields: [
                                            {
                                                name: 'icon',
                                                className: 'size-7',
                                                element: 'image',
                                                img: `${BASE_URL}/api/public/assets/images/${isAnomalies?"smiley-sad.svg":"smiley.svg"}`,
                                                visible: true,
                                            },
                                            {
                                                name: "value_and_status",
                                                className: "flex justify-between items-center gap-2",
                                                fields: [
                                                    {
                                                        name: `${isAnomalies ? 'anomaly' : 'insight'}-status-${index}`,
                                                        className: `text-base font-semibold font-semibold ${isAnomalies ? 'text-[#E41B47]' : 'text-[#059669]'}`,
                                                        element: "div",
                                                        label: `${insight.status}`,
                                                        visible: false
                                                    },
                                                    {
                                                        name: `${isAnomalies ? 'anomaly' : 'insight'}-title-${index}`,
                                                        className: 'text-xs cursor-pointer text-black',
                                                        element: 'div',
                                                        label: `${insight.title}`
                                                    },
                                                ]
                                            }
                                        ]
                                    }));
                                }
                                return subfield;
                            });
                        }
                        return field;
                    });
                }
                return item;
            });
            return { ...prev, schema: updatedSchema };
        });
    }

    const change_insights_with_no_data = () => {
        setSchema((prev: any) => {
            const updatedSchema = prev.schema.map((item: any) => {
                if (item.name === "anomolies_and_insights") {
                    item.fields = item.fields.map((field: any) => {
                        if (field.name === "anomalies_table" || field.name === "actionable_insights_table") {
                            const isAnomalies = field.name === "anomalies_table";
                            field.fields = field.fields.map((subfield: any) => {
                                if (subfield.name === "anomalies_cards" || subfield.name === "actionable_insights_cards") {
                                    subfield.fields = [{
                                        name: `${isAnomalies ? 'anomaly' : 'insight'}-indicator`,
                                        className: `border rounded-lg p-[5px] flex flex-col items-center justify-center gap-2 h-full`,
                                        fields: [
                                            {
                                                name: "value_indicator",
                                                className: "flex justify-between items-center gap-2",
                                                fields: [
                                                    {
                                                        name: `${isAnomalies ? 'anomaly' : 'insight'}-title_indicator`,
                                                        className: 'text-base text-gray-500',
                                                        element: 'div',
                                                        label: `No ${isAnomalies ? 'Anomalies' : 'Insights'} Found`
                                                    },
                                                ]
                                            }
                                        ]
                                    }];
                                }
                                return subfield;
                            });
                        }
                        return field;
                    });
                }
                return item;
            });
            return { ...prev, schema: updatedSchema };
        });
    }

    const handleModalClose = () => {
        setSchema((prev: any) => {
            const updatedSchema = prev.schema.map((item: any) => {
                if (item.name === 'table_modal') {
                    item.visible = false;
                }
                return item;
            });
            return { ...prev, schema: updatedSchema };
        });
    }

    const handleModalOpen = () => {
        setSchema((prev: any) => {
            const updatedSchema = prev.schema.map((item: any) => {
                if (item.name === 'table_modal') {
                    item.visible = true;
                }
                return item;
            });
            return { ...prev, schema: updatedSchema };
        });
    }
    
    useEffect(()=>{
        API_GET_Insights_Data({ startDate: dateRange[0], endDate: dateRange[1] })
    },[dateRange])

    const handleSelectedRecord = (e: any) => {
        if( e.name.includes("insight-item") || e.name.includes("anomaly-item") ){
            handleModalOpen();
        }
        if(e.name==="modal_close"){
            handleModalClose();
        }
    }
    
  return (
    <div>
        {loading && <LoadingState/>}
      <ElementExecutor
        data={schema}
        selectedRecord={(e: any) => {
          handleSelectedRecord(e);
        }}/>
    </div>
  )
}

export default LeadsInsights
