import { ElementExecutor } from '@apexcura/core'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Utils from '../../utils'
import { CONSTANTS } from '../../utils/app-constants'
import { useDispatch } from 'react-redux'
import { setActiveMenu, setTopbar } from '../../redux/uiSchema/uiSchemaSlice'
import { Item } from '../../utils/types'
import Storage from '../../utils/local-storage'
import { Container } from 'postcss'
import { truncate } from 'fs'
import LoadingState from '../LoadingState'
const { BASE_URL, FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS

const ViewCampaign = () => {
    
    const scheduleCard = () =>([
        {
            name: "schedule_data_container",
            className: "col-span-1 p-6 rounded-md bg-[#F8F8FF] drop-shadow-md h-fit",
            fields:[
                {
                    name: "schedule_data_head_container",
                    className: " flex pb-4 justify-between items-start",
                    fields: [
                        {
                            name: "schedule_data_head_main",
                            className: "flex flex-col gap-y-2 ",
                            fields: [
                                {
                                    name: "schedule_data_head_title",
                                    className: "font-medium text-lg",
                                    label: "Schedule - 01",
                                    element: "div"
                                },
                                {
                                    name: "schedule_data_head_date",
                                    element: "div",
                                    className: "text-xs",
                                    label: "N/A",
                                },
                                {
                                    name: "schedule_data_head_template",
                                    element: "button",
                                    className: "text-xs hover:font-medium text-[#3341BB]",
                                    key: "secrete key",
                                    label: "",
                                }
                            ]
                        },
                        {
                            name: "view_recipients_list_schedule_",
                            className: " p-2 flex gap-1 rounded-md hover:shadow-md",
                            icon: "list_view",
                            containerClassName: "flex",
                            iconsClassName: "w-[20px]",
                            label: "",
                            element: "button",

                        }
                        
                    ]
                },
                {
                    name: "schedule_data_body",
                    className: " flex flex-col gap-2 text-sm",
                    fields: [
                            {
                            name: "schedule_data_pair",
                            className: "flex justify-between border-b-[1px] border-b-[#919191] pb-2",
                            fields: [
                                {
                                    name: "schedule_data_label_status",
                                    element: "div",
                                    className: "text-[#595959]",
                                    label: "Status",

                                },
                                {
                                    name: "schedule_data_value_status",
                                    element: "div",
                                    className: "font-medium",
                                    label: "N/A",

                                },
                            ]
                        },
                        {
                            name: "schedule_data_pair",
                            className: "flex justify-between border-b-[1px] border-b-[#919191] pb-2",
                            fields: [
                                {
                                    name: "schedule_data_label_target",
                                    element: "div",
                                    className: "text-[#595959]",
                                    label: "Recipients",

                                },
                                {
                                    name: "schedule_data_value_target",
                                    element: "div",
                                    className: "font-medium",
                                    label: "N/A",

                                },
                            ]
                        },
                        {
                            name: "schedule_data_pair",
                            className: "flex justify-between border-b-[1px] border-b-[#919191] pb-2",
                            fields: [
                                {
                                    name: "schedule_data_label_delivered",
                                    element: "div",
                                    className: "text-[#595959]",
                                    label: "Delivered",

                                },
                                {
                                    name: "schedule_data_value_delivered",
                                    element: "div",
                                    className: "font-medium",
                                    label: "N/A",

                                },
                            ]
                        },
                        {
                            name: "schedule_data_pair",
                            className: "flex justify-between border-b-[1px] border-b-[#919191] pb-2",
                            fields: [
                                {
                                    name: "schedule_data_label_read",
                                    element: "div",
                                    className: "text-[#595959]",
                                    label: "Read",

                                },
                                {
                                    name: "schedule_data_value_read",
                                    element: "div",
                                    className: "font-medium",
                                    label: "N/A",

                                },
                            ]
                        },
                        // {
                        //     name: "schedule_data_pair",
                        //     className: "flex justify-between border-b-[1px] border-b-[#919191] pb-2",
                        //     fields: [
                        //         {
                        //             name: "schedule_data_label_click",
                        //             element: "div",
                        //             className: "text-[#595959]",
                        //             label: "Click Through",

                        //         },
                        //         {
                        //             name: "schedule_data_value_click",
                        //             element: "div",
                        //             className: "font-medium",
                        //             label: "568 (18%)",

                        //         },
                        //     ]
                        // },
                        // {
                        //     name: "schedule_data_pair",
                        //     className: "text-[#3341BB] flex justify-between",
                        //     fields: [
                        //         {
                        //             name: "schedule_data_label_conversion",
                        //             element: "div",
                        //             className: "",
                        //             label: "Conversion",

                        //         },
                        //         {
                        //             name: "schedule_data_value_conversion",
                        //             element: "div",
                        //             className: "font-medium",
                        //             label: "2554 (18%)",

                        //         },
                        //     ]
                        // }

                    ]
                }
            ]
        }
    ])

    const scheduleTemplate = () => ([
        {
            name: "schedule_template",
            className: "grid grid-cols-3 gap-6 pb-4 border-b-[1.5px]",
            fields: [
                ...scheduleCard(),
                
                {
                    name: 'top_campaign_summary_graph',
                    className: ' w-full h-full rounded-md bg-white ',
                    element: 'multi-barchart',
                    layout:"horizontal",
                    isStack: false,
                    isLegend: false,
                    label: 'Top Campaign Summary',
                    labelClassName: 'text-sm text-[#254AAA] text-center font-semibold mt-2 text-left',
                    visible: true,
                    categories: ['20-30', '30-40', '40-50'],
                    seriesData: [
                         {
                            name: 'Recipients',
                            data: [0, 0 , 0],
                            color: "#4287f5"
                        },{
                            name: 'Read',
                            data: [0, 0 , 0],
                            color: "#ff6836"
                        }],
                },
                
                {
                    name: 'recipient_data_barchart',
                    element: 'vertical-barchart',
                    className: ' w-full  rounded-md bg-white  ',
                    labelClassName: 'text-sm text-[#254AAA] text-center font-semibold mt-2 text-left',
                    centerLabel1: 'High',
                    label: "Campaign Overview",
                    centerLabel2:'Improve chatbot training data',
                    visible: true,
                    val:0,
                    pointWidth: 30,
                    categories: ['Sent', 'Delivered', 'Read', 'Failed'],
                    data: [
                        { label: 'Sent', value: 0},
                        { label: 'Delivered', value: 0 },
                        { label: 'Read', value: 0 },
                        { label: 'Failed', value: 0 }
                    ],
                }
            ]

        }
    ])

    const [schema, setSchema] = useState<any>({
        className: "bg-white rounded-lg overflow-hidden flex flex-col gap-1 h-full",
        schema: [
            {   name: "head_container",
                className: "bg-[#DEE7FF] py-3 px-4 flex items-center justify-between",
                fields: [
                    {
                        name: 'head_logo',
                        className: 'flex items-center gap-1 bg-[#fff] w-fit px-2 py-2 rounded-sm drop-shadow-md',
                        fields:[
                            {
                                name: 'head_logo_img',
                                img: `${BASE_URL}/api/public/assets/images/whatsapp-white-border.svg`,
                                alt: 'Apex_cura_image',
                                element: 'image',
                                visible: true,
                                imgClassName: 'w-30 self-center',
                            },
                            {
                                name: 'head_logo_text',
                                label: 'Whatsapp',
                                element: 'div',
                                className: ""
                            }
                        ]

                    },
                    {
                        name: "campaigns_head_data",
                        className: "flex gap-6 text-[#273238]",
                        fields: [
                            
                            {
                                name: 'campaigns_head_title',
                                label: "",
                                className: "flex gap-1 items-center",
                                fields: [
                                    {
                                        name: 'campaigns_head_title_label',
                                        label: "Campaign Title:",
                                        className: "",
                                        element: 'div',
                                    },
                                    {
                                        name: 'campaigns_head_title_value',
                                        label: "N/A",
                                        className: "font-medium",
                                        element: 'div',
                                    },   
                                ]
                            },
                            // {
                            //     name: 'campaigns_head_branch',
                            //     label: "",
                            //     className: "flex gap-1 items-center",
                            //     fields: [
                            //         {
                            //             name: 'campaigns_head_branch_label',
                            //             label: "Hospital Branch:",
                            //             className: "",
                            //             element: 'div',
                            //         },
                            //         {
                            //             name: 'campaigns_head_branch_value',
                            //             label: "Hyderabad, Telangana",
                            //             className: "font-medium",
                            //             element: 'div',
                            //         },   
                            //     ]
                            // }
                            
                        ]
                    },
                    {
                        name: "campaigns_head_controls",
                        className: "flex gap-3  text-[#2E3BAA]",
                        fields: [
                            // {
                            //     name: 'campaigns_head_edit',
                            //     icon: "edit",
                            //     label: "Edit",
                            //     className: "bg-[#F1F5FF] py-1 px-3 rounded-md hover:shadow-md ",
                            //     element: 'button',
                            // },
                            {
                                name: 'view_recipient_filters_modal',
                                icon: 'blue_eye',
                                label: "Recipient Group",
                                containerClassName: "flex",
                                iconsClassName: "w-[25px]",
                                className: "bg-[#F1F5FF] py-1 px-3 flex gap-1 rounded-md hover:shadow-md",
                                element: 'button',
                            },
                        ]
                    }
                ]
            },
            {
                name: "campaigns_body",
                className: "flex flex-col h-[70vh] overflow-y-scroll gap-6 p-6",
                fields: [

                ]
            },
            { 
                name: "recipients_table_modal",
                width: 1000,
                element: "modal",
                visible: false,
                fields: [
                  {
                    name: "modal_body",
                    className:
                      " flex flex-col justify-between items-top",
                    fields: [
                        {
                            name: "modal_head",
                            className: "flex justify-between items-center p-4 bg-[#f2f2f2]",
                            fields: [
                                {
                                    name:'modal_title_key',
                                    className:'font-semibold text-lg  text-[#3341BB]',
                                    element:'div',
                                    label:'Recipients'
                                },
                                {
                                    name: "recipients_table_modal_close",
                                    element: "button",
                                    label: "X",
                                    className:
                                      "!w-[35px] !h-[35px] rounded-full bg-primary text-xl text-white flex justify-center items-center",
                                },
                            ]
                        },
                      
                      {
                        name: "recipients_table",
                        className: 'p-4',
                        element: 'table',
                        variant: 'plain',
                        size: 'small',
                        pagination: true,
                        value: { name: 'pagination', page: 1, pageSize: 10 },
                        thead: [
                            {
                              name: "patient_name",
                              label: "Name",
                              key: "patient_name",
                              ellipsis:true
                            },
                            {
                              name: "phone_number",
                              label: "Mobile",
                              key: "phone_number",
                            },
                            {
                                name: "sent_time",
                                label: "Sent Time",
                                key: "sent_time",
                                render: (e: any, record: any) => record?.sent_time ?  moment(record?.sent_time, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY HH:mm") : 'N/A',
                                ellipsis:true
                              },
                            // {
                            //     name: "delivery_check",
                            //     label: "Delivered",
                            //     key: "delivered",
                            //     ellipsis:true,
                            //     render: (e: any, record: any) => record?.template?.delivered ? "✔️" : "❌" || "N/A"
                                

                            //   },
                            {
                              name: "delivery_time",
                              label: "Delivery Time",
                              render: (e: any, record: any) => record?.delivered_time ?  moment(record?.delivered_time, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY HH:mm") : 'N/A',
                              ellipsis:true
                            },
                            // {
                            //     name: "conversion_time",
                            //     label: "Read",
                            //     key: "read",
                            //     render: (e: any, record: any) => record?.template?.read ? "✔️" : "❌" || "N/A",
                            //     ellipsis:true
                            //   },
                            {
                              name: "read_time",
                              label: "Read Time",
                              key: "read_time",
                              render: (e: any, record: any) => record?.read_time ?  moment(record?.read_time, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY HH:mm") : 'N/A',
                              ellipsis:true
                            },
                            
                            
                          ],
                        tbody: [],
                    },
                    
                    ],
                  },
                  {
                    name: "modal_body_container",
                    className: "flex flex-col overflow-auto bg-white p-4",
                    fields: [],
                  },
                  
                ],
            },
            {
                name: "recipients_data_modal",
                className: "flex flex-col justify-between items-top",
                containerClassName: "",
                width: 500,
                element: "modal",
                visible: false,
                fields: [

                    {
                        name: "recipients_data_modal_head",
                        className: "flex justify-between items-center bg-[#f2f2f2] p-4",
                        fields: [
                            {
                                name: "campaign_filter_count_div",
                                label: "",
                                className: " flex items-center gap-2 w-fit px-3 py-2 rounded-md bg-[#EBF5FF] ",
                                fields:[
                                    {
                                    name: "campaign_filter_count",
                                    label: "0",
                                    element: "div",
                                    className: "text-[#3341BB] font-medium",
                                    },
                                    {
                                    name: "campaign_filter_count_div",
                                    label: "Recipients",
                                    element: "div",
                                    className: "font-medium",
                                    },
                                ]
                                },
                            {
                                name: "close_recipient_filters_modal",
                                element: "button",
                                label: "X",
                                className:
                                    "!w-[35px] !h-[35px] rounded-full bg-primary text-xl text-white flex justify-center items-center",
                            },
                        ]
                    },
                    {
                        name: "recipients_data_grid",
                        className: "grid grid-cols-6 p-6 gap-y-8 h-fit",
                        fields: [
                           
                            {
                                name: "reciepients_age",
                                className: "col-span-2 flex flex-col",
                                fields: [
                                    {
                                        name: "reciepients_age_label",
                                        label: "Age",
                                        element: 'div',
                                        className: "font-medium border-b-[1.5px] w-full  pb-1"
                                    },
                                    {
                                        name: "reciepients_age_value",
                                        className: "",
                                        element: 'div',
                                        label: "N/A"
                                    },
                                ]
                            },
                            {
                                name: "reciepients_gender",
                                className: "col-span-2 flex flex-col ",
                                fields: [
                                    {
                                        name: "reciepients_gender_label",
                                        label: "Gender",
                                        element: 'div',
                                        className: "font-medium border-b-[1.5px] w-full pb-1"
                                    },
                                    {
                                        name: "reciepients_gender_value",
                                        element: 'div',
                                        className: "",
                                        label: "N/A"
                                    },
                                ]
                            },
                            {
                                name: "reciepients_condition",
                                className: "col-span-2 flex flex-col ",
                                fields: [
                                    {
                                        name: "reciepients_condition_label",
                                        label: "Medical Condition",
                                        element: 'div',
                                        className: "font-medium border-b-[1.5px] w-full pb-1"
                                    },
                                    {
                                        name: "reciepients_condition_values",
                                        className: "",
                                        fields: [
                                            {
                                                name: "",
                                                element: 'div',
                                                className: "",
                                                label: "N/A"
                                            },
                                            
                                        ]
                                    },
                                ]
                            },
                            {
                                name: "reciepients_doctor",
                                className: "col-span-3 flex flex-col items-start",
                                fields: [
                                    {
                                        name: "reciepients_doctor_label",
                                        label: "Doctor",
                                        element: 'div',
                                        className: "font-medium border-b-[1.5px] w-full pb-1"
                                    },
                                    {
                                        name: "reciepients_doctor_values",
                                        className: "",
                                        fields: [
                                            {
                                                name: "",
                                                element: 'div',
                                                className: "",
                                                label: "N/A"
                                            },
                                            
                                            

                                        ]
                                    },
                                ]
                            },
                            {
                                name: "reciepients_department",
                                className: "col-span-3 flex flex-col items-start",
                                fields: [
                                    {
                                        name: "reciepients_department_label",
                                        label: "Department",
                                        element: 'div',
                                        className: "font-medium border-b-[1.5px] w-full pb-1"
                                    },
                                    {
                                        name: "reciepients_department_values",
                                        className: "",
                                        fields: [
                                            {
                                                name: "",
                                                element: 'div',
                                                className: "",
                                                label: "N/A"
                                            },

                                        ]
                                    },
                                ]
                            }
                        ]
                    },
                    
                    
                ],
            },
            {
                name: "template_data_modal",
                className: "pl-2 pb-2 bg-[#f2f2f2]",
                containerClassName: "",
                width: 500,
                element: "modal",
                visible: false,
                fields: [
                    {
                        name: "template_data_modal_head",
                        className: "flex justify-between items-center p-4 bg-[#f2f2f2]",
                        fields: [
                            {
                                name: "template_title",
                                label: "",
                                className: " flex items-center gap-2 w-fit ",
                                fields:[
                                    {
                                    name: "template_title_label",
                                    label: "Template:",
                                    element: "div",
                                    className: " font-medium text-lg",
                                    },
                                    {
                                    name: "template_title_value",
                                    label: "N/A",
                                    element: "div",
                                    className: "text-[#3341BB] font-medium text-lg",
                                    },
                                ]
                            },
                            {
                                name: "close_template_data_modal",
                                element: "button",
                                label: "X",
                                className:
                                    "!w-[35px] !h-[35px] rounded-full bg-primary text-xl text-white flex justify-center items-center",
                            },
                        ]
                    },
                    {
                        name: "template_data_modal_body_container",
                        className: "w-full max-h-[50vh] overflow-y-scroll border-1 bg-[#fff] rounded-md p-6 flex flex-col gap-1",
                        fields: [
                            {
                                name: "template_data_modal_body",
                                className: "",
                                element: 'div',
                                label: "preview not available....!",
                            },
                            {
                                name: "template_data_modal_footer",
                                className: "text-xs text-gray-400",
                                element: 'div',
                                label: ""
                                
                            }
                        ]
                        
                    },
                    

                ]

            }
          
        ]
    })


    const [searchText] = useState('')
    const [selectedTab, setSelectedTab] = useState('')
    const [loading,setLoading]=useState<boolean>(true)

    const location = useLocation()
    
    const cid = location.pathname.split('/')[3];    
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const org_id = Storage.getItem('details')?.['org_id']

    useEffect(() => {
        dispatch(setTopbar('Campaign Details'))
        dispatch(setActiveMenu("/campaigns"))
    }, [])

    useEffect(() => {
        if (selectedTab) {
            API_GET_Data({
                source: selectedTab,
            })
        }
    }, [selectedTab])

    const API_GET_Data = async ({ source }: any) => {
        setLoading(true)
        try {
            const res = await Utils.makeApiCall(
                `/campaign/${cid}/${org_id}`,
                'GET'
            )

            if (res.status) {
                const { data } = res
                if(data){

                    const schedules = data?.schedules
                    
                    setSchema((prev: any)=>{
                        prev?.schema?.map((f:any)=>{
                            if(f?.name === "head_container"){
                                if(f?.fields?.length){
                                    f.fields.map((ff:any)=>{

                                        if(ff.name === "head_logo"){
                                            if(ff?.fields?.length){
                                                ff.fields.map((fff:any)=>{
                                                    if(fff?.name === "head_logo_img"){
                                                        fff.img = data?.source === "whatsapp" ? `${BASE_URL}/api/public/assets/images/whatsapp-white-border.svg` : `${BASE_URL}/api/public/assets/images/sms.svg`
                                                    }
                                                    if(fff?.name === "head_logo_text"){
                                                        fff.img = data?.source
                                                    }
                                                })
                                            }
                                        }

                                        if(ff.name === "campaigns_head_data"){
                                            if(ff?.fields?.length){
                                                ff.fields.map((fff: any)=>{
                                                    if(fff.name === "campaigns_head_title"){
                                                        if(fff?.fields?.length){
                                                            fff.fields.map((title: any)=>{
                                                                if(title.name === "campaigns_head_title_value"){
                                                                    let title_text = data?.campaign_name 
                                                                    title_text = title_text[0].toUpperCase() + title_text.slice(1)
                                                                    title.label = title_text
                                                                }
                                                            })
                                                        }
                                                    }
                                                })
                                            }
                                        }
                                    })
                                }
                            }

                            if(f?.name === "campaigns_body"){
                                const arr: any = []
                                if(schedules?.length){
                                    schedules.map((schedule: any, index: any)=>{
                                        arr.push(handleTemplateData(scheduleTemplate(), schedule, index))
                                    })
                                }
                                f.fields = arr
                            }

                            if(f?.name === "recipients_data_modal"){
                                if(f?.fields?.length){
                                    f.fields.map((ff: any)=>{
                                        if(ff?.name === "recipients_data_modal_head"){
                                            if(ff?.fields?.length){
                                                ff.fields.map((fff: any)=>{
                                                    if(fff?.name === "campaign_filter_count_div"){
                                                        if(fff?.fields?.length){
                                                            fff.fields.map((ffff:any)=>{
                                                                if(ffff?.name === "campaign_filter_count"){
                                                                    ffff.label = data?.recipients_count | 0
                                                                }
                                                            })
                                                        }
                                                    }
                                                })
                                            }
                                        }
                                                    if(ff?.name === "recipients_data_grid"){
                                                        if(ff?.fields?.length){
                                                            ff.fields.map((fff: any)=>{
                                                                if(fff?.name === "reciepients_age"){
                                                                    if(fff?.fields.length){
                                                                        fff.fields.map((ffff: any)=>{
                                                                            if(ffff.name === "reciepients_age_value"){
                                                                                ffff.label = data?.recipient_group_details?.patient_group?.age
                                                                            }
                                                                        })
                                                                    }
                                                                }
            
                                                                if(fff?.name === "reciepients_gender"){
                                                                    if(fff?.fields.length){
                                                                        fff.fields.map((ffff: any)=>{
                                                                            if(ffff.name === "reciepients_gender_value"){
                                                                                ffff.label = data?.recipient_group_details?.patient_group?.gender
                                                                            }
                                                                        })
                                                                    }
                                                                }

                                                                if(fff?.name === 'reciepients_condition'){
                                                                    if(fff?.fields.length){
                                                                        fff.fields.map((ffff: any)=>{
                                                                            if(ffff.name === 'reciepients_condition_values'){
                                                                                const fields: any = []
                                                                                if(data?.recipient_group_details?.patient_group?.medical_condition === "" || data?.recipient_group_details?.patient_group?.medical_condition === null || data?.recipient_group_details?.patient_group?.medical_condition === undefined){
                                                                                    fields.push({
                                                                                        name: "",
                                                                                        element: 'div',
                                                                                        label: "N/A",
                                                                                    })
                                                                                }else{

                                                                                    data?.recipient_group_details?.patient_group?.medical_condition?.map((fil: any)=>{
    
    
                                                                                        fields.push({
                                                                                            name: "",
                                                                                            element: 'div',
                                                                                            label: fil,
                                                                                        })
                                                                                    })
                                                                                }
                                                                                ffff.fields = fields
                                                                            }   
                                                                        })
                                                                    }
                                                                }

                                                                if(fff?.name === 'reciepients_doctor'){
                                                                    if(fff?.fields.length){
                                                                        fff.fields.map((ffff: any)=>{
                                                                            if(ffff.name === 'reciepients_doctor_values'){
                                                                                const fields: any = []
                                                                                data?.recipient_group_details?.doctors?.map((fil: any)=>{
                                                                                    fields.push({
                                                                                        name: "",
                                                                                        element: 'div',
                                                                                        label: fil
                                                                                    })
                                                                                })
                                                                                if(data?.recipient_group_details?.doctors.length === 0){
                                                                                    fields.push({
                                                                                        name: "",
                                                                                        element: 'div',
                                                                                        label: "N/A"
                                                                                    })
                                                                                }
                                                                                ffff.fields = fields
                                                                            }   
                                                                        })
                                                                    }
                                                                }

                                                                if(fff?.name === 'reciepients_department'){
                                                                    if(fff?.fields.length){
                                                                        fff.fields.map((ffff: any)=>{
                                                                            if(ffff.name === 'reciepients_department_values'){
                                                                                const fields: any = []
                                                                                data?.recipient_group_details?.departments?.map((fil: any)=>{
                                                                                    fields.push({
                                                                                        name: "",
                                                                                        element: 'div',
                                                                                        label: fil
                                                                                    })
                                                                                })
                                                                                if(data?.recipient_group_details?.departments.length === 0){
                                                                                    fields.push({
                                                                                        name: "",
                                                                                        element: 'div',
                                                                                        label: "N/A"
                                                                                    })
                                                                                }
                                                                                ffff.fields = fields
                                                                            }   
                                                                        })
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    }
                                                
                                            
                                        
                                        
                                    })
                                }
                            }



                        })
                        return {...prev};
                    })
                    
                }
            } else {
                toast.error(res.message ? res.message : FAILURE_MSG, {
                    position: 'top-center',
                    autoClose: AUTO_CLOSE,
                })
            }
        } catch (error) {
            toast.error(CATCH_ERROR_MSG, {
                position: 'top-center',
                autoClose: AUTO_CLOSE,
            })
            console.error('Failed to fetch schema', error)
        }
        setLoading(false)
    }


    useEffect(() => {
        const id = setTimeout(() => {
            API_GET_Data({
                source: '',
            })
        }, 500)

        return () => {
            clearTimeout(id)
        }
    }, [searchText])


    const handleRecipientsTableModal = async(tempId: any, schedule_id: any, visible: boolean) =>{
       
        if(visible){
            try{
                const res = await Utils.makeApiCall(
                    `/recipients/${cid}/${tempId}/${schedule_id}`,
                    'GET'
                )   
                if (res.status) {
                    let data = res.data;
                    data = data?.recipients
                    setSchema((prev: any)=>{
                        prev?.schema?.map((f:any)=>{
                            if(f?.name === "recipients_table_modal"){
                                f.visible = visible || false 
            
                                if(f?.fields?.length){
                                    f?.fields.map((ff: any)=>{
                                        if(ff?.name === "modal_body"){
                                            if(ff?.fields?.length){
                                                ff?.fields.map((fff: any)=>{
                                                   if(fff?.name === "recipients_table"){
                                                    fff['tbody'] = data?.map((acc: any)=>{
                                                        const createdAt = acc["createdAt"]
                                                            ? moment(acc["createdAt"]).format("DD-MM-YYYY HH:mm:ss")
                                                            : "";
                                                        return { ...acc, createdAt };  
                                                    })
                                                   } 
                                                })
                                            }
                                        }
                                    })
                                }
                            }
            
                        })
            
                        return {...prev}
                    })
                }
            } catch(err){
                console.error(err);
            }

        }else{
            setSchema((prev: any)=>{
                prev?.schema?.map((f:any)=>{
                    if(f?.name === "recipients_table_modal"){
                        f.visible = visible || false 
                    }
    
                })
    
                return {...prev}
            })
        }
       

        
    }

    const handleDataModal = (visible: boolean) =>{
        setLoading(true)
        setSchema((prev: any)=>{
            
            prev?.schema?.map((f:any)=>{
                if(f?.name === "recipients_data_modal"){
                    f.visible = visible || false 

                    if(f?.fields?.length){
                        f.fields.map((ff:any)=>{
                            if(ff?.name === "modal_body"){
                                if(ff?.fields?.length){
                                    ff.fields.map((fff:any)=>{
                                        if(fff?.name === "recipients_table"){
                                            console.log("empty block");
                                            
                                        }
                                    })
                                }
                            }
                        })
                    }
                }
            })

            return {...prev}
        })
        setLoading(false)
    }

    const handleTemplateData = (template: any, data: any, index: any) =>{

        template = template?.length ? template [0] : template
        
        if(template?.fields?.length){
        
            template?.fields.map((t: any)=>{
                if(t?.name === "schedule_data_container"){
                    if(t?.fields?.length){
                        t?.fields.map((tt: any)=>{

                            if(tt?.name === "schedule_data_head_container"){
                                tt?.fields.map((ttt: any)=>{
                                    if(ttt?.name === "schedule_data_head_main"){
                                        ttt?.fields.map((tttt: any)=>{
                                            if(tttt?.name === "schedule_data_head_title"){
                                                tttt.label = `Schedule - ${index+1}`                                           
                                            }
                                            if(tttt?.name === "schedule_data_head_date"){
                                                let date = data?.scheduled_at  
                                                date = moment(date, 'YYYY-MM-DD HH:mm').format('DD-MM-YYYY HH:mm')
                                                tttt.label = date                                             
                                            }
                                            if(tttt?.name === "schedule_data_head_template"){
                                                let title = data?.template_name
                                                title = title.replace(/_/g, " ")
                                                title = title.replace(/\b\w/g, function(char: any) {
                                                    return char.toUpperCase();
                                                });
                                                // title = title[0].toUpperCase() + title.slice(1)
                                                tttt.label = `Template: ${title}`
                                                    
                                                tttt.key = data?.template_id                                     
                                            }
                                        })
                                    }
                                    if(ttt?.name === "view_recipients_list_schedule_"){
                                        ttt.name = `view_recipients_list_schedule_${index}`
                                    }
                                })
                            }

                            if(tt?.name === "schedule_data_body"){
                                if(tt?.fields?.length){

                                    tt?.fields.map((ttt: any)=>{
                                        if(ttt?.name === "schedule_data_pair"){
                                            
                                            if(ttt?.fields?.length){
                                                ttt?.fields.map((s: any)=>{
                                                    if(s?. name === "schedule_data_value_status"){  
                                                        let status = data?.status 
                                                        status = status.charAt(0).toUpperCase() +status.slice(1)                          
                                                        s.label = status
                                                    }
                                                    if(s?. name === "schedule_data_value_target"){     
                                                        s.label = data?.recipients_count                       
                                                        // s.label = data?.sent_count
                                                    }
                                                    if(s?. name === "schedule_data_value_delivered"){                            
                                                        s.label = data?.delivered_count
                                                    }
                                                    if(s?. name === "schedule_data_value_read"){                            
                                                        s.label = data?.read_count
                                                    }
                                                    if(s?. name === "schedule_data_value_click"){                               
                                                        s.label = data?.click_count || 0
                                                    }
                                                    if(s?. name === "schedule_data_value_conversion"){                            
                                                        s.label = data?.conversion_count || 0
                                                    }
                                                })
                                            }
                                        }
                                    })
                                }
                            }
                        })
                    }
                }

                if(t?.name === "top_campaign_summary_graph"){
                    const categories: any = []
                    const conversionRate: any = []
                    const conversions: any = []
                    const recipients: any = []
                    const read: any = []
                   
                    data?.age_gender_analytics?.map((age: any)=>{
                        categories.push(age?.gender === "male" ? `${age?.age} (M)` : `${age?.age} (F)`)
                        conversionRate.push(age?.conversion_rate || 0)
                        conversions.push(age?.conversions || 0)
                        recipients.push(age?.recipients || 0)
                        read.push(age?.read || 0)
                    })    
                    
                    const seriesData = [
                        {
                            name: "Recipients",
                            data: recipients,
                            color: "#4287f5"
                        },
                        {
                            name: "Read",
                            data: read,
                            color: "#ff6836"
                        },
                        // {
                        //     name: "Conversion Rate",
                        //     data: conversionRate,
                        //     color: "#ff1921"
                        // },
                        
                    ]

                    t.categories = categories
                    t.seriesData = seriesData
                }

                if(t?.name === "recipient_data_barchart"){
                    
                    
                        t.data = [
                            {
                                label: "Sent",
                                value: data?.delivery_reports_analytics?.message_sent || 0,
                                color: "#4287f5"
                                // color: '#e0dede'
                            },
                            {
                                label: "Delivered",
                                value: data?.delivery_reports_analytics?.message_delivered || 0,
                                color: "#7aadff"
                                // color: '#b5b3b3'
                                // color: '#4287f5'
                            },
                            {
                                label: "Read",
                                value: data?.delivery_reports_analytics?.message_read || 0,
                                color: "#7f8af0"
                                // color: '#629efc'
                            },
                            {
                                label: "Failed",
                                value: data?.delivery_reports_analytics?.failed || 0,
                                color: "#e41b47"
                            }
                                
                        ]
                }
                    
                

            })
        }
        return template
    }

    const handleTemplateModal = (data: any, visible: boolean) =>{
        setSchema((prev: any)=>{
            
            prev?.schema?.map((f:any)=>{
                if(f.name === "template_data_modal"){
                    f.visible = visible

                    f?.fields?.map((ff: any)=>{
                        if(ff.name === "template_data_modal_head"){
                            ff?.fields?.map((fff:any)=>{
                                if(fff.name === "template_title"){
                                    fff?.fields?.map((ffff: any)=>{
                                        if(ffff.name === "template_title_value"){
                                            ffff.label = data?.name?.split('_')?.map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))?.join(' ') || "N/A"
                                        }
                                    })
                                }
                            })
                        }
                        if(ff.name === "template_data_modal_body_container"){
                            ff?.fields?.map((fff: any)=>{
                                if(fff.name === "template_data_modal_body"){
                                    data?.components?.map((c: any)=>{
                                        if(c?.type === "BODY"){
                                            const examples = c?.example?.body_text[0]
                                            const label = examples && examples.length > 0 ? Utils.replacePlaceholders(c?.text,examples) : c?.text
                                            fff.label = label || 'preview not available....!'
                                        }
                                    })
                                }
                                if(fff.name === "template_data_modal_footer"){
                                    data?.components?.map((c: any)=>{
                                        if(c?.type === "FOOTER"){
                                            fff.label = c?.text || ''
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
            return {...prev}
        })
    }
    const handleSelect = (e: any) => {
        
        if (e.name === 'btn_add') {
            navigate('/campaigns/add')
        }
        if (e.name === 'btn_back') {
            dispatch(setTopbar('Campaigns'))
            dispatch(setActiveMenu("/campaigns"))
            navigate('/campaigns')
        }
        if (e.name === 'tabs') {
            setSelectedTab(e.value.key)
        }
        if (e.name === 'campaigns_table') {
            if (e.value?.name === 'pagination') {
                API_GET_Data({
                    source: '',
                })
            }
        }
        if(e.name === "view_recipient_filters_modal"){
            handleDataModal(true)
        }
        if(e.name === "close_recipient_filters_modal"){
            handleDataModal(false)
        }

        if(e.name.includes('view_recipients_list_schedule_')){
            const scheduleId = e.name.split('_')[4]
            fetchScheduleRecipients(scheduleId)

        }

        if(e.name === "recipients_table_modal_close"){
            handleRecipientsTableModal("", "", false);
        }
        if(e.name === "schedule_data_head_template"){
    
            fetchTemplatePreview(e?.key || "")  
        }
        if(e.name === "close_template_data_modal"){
            handleTemplateModal([], false)
        }
    }

    const fetchScheduleRecipients = async (scheduleId: any) =>{
        setLoading(true);
        try{
            const res = await Utils.makeApiCall(
                `/campaign/${cid}/${org_id}`,
                'GET'
            )   

            if (res.status) {
                const { data } = res
                let tempId = ""
                let schedule_id = ""
                
                if(data){
                    const schedules = data?.schedules
                    if(schedules?.length){
                        tempId = schedules[scheduleId]?.template_id 
                        schedule_id = schedules[scheduleId]?.schedule_id 
                        
                        handleRecipientsTableModal(tempId, schedule_id, true)
                    }
                }
                // if(recipients?.length === 0){
                //     recipients = data?.recipients_list
                // }
                
            }
        }
        catch(error){
            toast.error(CATCH_ERROR_MSG, {
                position: 'top-center',
                autoClose: AUTO_CLOSE,
            })
            console.error('Failed to fetch schema', error)
            return [];
        }
        setLoading(false)
    }


    const fetchTemplatePreview = async (templateId: any) =>{

        
        setLoading(true)
        try{
            const res = await Utils.makeApiCall(`/templates?id=${templateId}`, "GET");
            let tempData:any = []
            if (res.status) {
                const { data } = res
                if(data){
                    tempData = data?.templates?.[0]
                }
                
                handleTemplateModal(tempData, true)
            } else {
                toast.error(res.message ? res.message : FAILURE_MSG, {
                    position: 'top-center',
                    autoClose: AUTO_CLOSE,
                })
            }
        }
        catch(error){
            toast.error(CATCH_ERROR_MSG, {
                position: 'top-center',
                autoClose: AUTO_CLOSE,
            })
            console.error('Failed to fetch schema', error)
            return []
        }
        setLoading(false)
    }

    return (
        <div>
            {loading && <LoadingState/>}
            <ElementExecutor
                data={schema}
                selectedRecord={handleSelect}
            />
        </div>
    )
}

export default ViewCampaign
