import React, { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { ElementExecutor } from "@apexcura/core";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useActivityTimeout from "./hooks/useActivityTimeout";
import Utils from "./utils";
import Storage from "./utils/local-storage";
import { useDispatch, useSelector } from "react-redux";
import { getInitialSchemas, setActiveBranch, setActiveMenu, setProfileImage,setBackiconvisibility, setProfileName, setSidebarItems, setTopbar, setTopbarBranches, setUISchemas, setTopBarVisibility } from "./redux/uiSchema/uiSchemaSlice";
import { RootState } from "./redux/rootReducer";
import { AppDispatch } from "./redux/store";
import Schema from "./utils/schema";
import TableSkeleton from "./components/table-skeleton";
import { toast } from "react-toastify";
import { CONSTANTS } from "./utils/app-constants";
import LoadingState from "./pages/LoadingState";
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE,BASE_URL } = CONSTANTS;
interface IChangePasswordPayload{
  oldPassword:string,
  newPassword:string,
  confirmPassword:string
}
const Layout = () => {
  const schemas = useSelector(getInitialSchemas);  
  const { topBar, sideBar } = useSelector((state: RootState) => state.uiSchema);

  const {id,fullname,email,phonenumber}=Storage.getItem('details')
  const [passwordschema,setPasswordSchema]=useState<any>({
    name:"change_password",
    className:"",
    schema:[
      {
        name:'change-password-modal',
        containerClassName:"!w-[30%]",
        visible:true,
        element:"modal",
        className:'flex flex-col w-full bg-white border shadow-lg rounded-lg overflow-hidden min-h-[200px] max-h-[500px]',
        fields:[
            {
                name:'modal_title',
                className:'bg-[#F2F2F2] flex justify-between items-center p-2 pl-4 w-full',
                fields:[
                    {
                        name:'modal_title_key',
                        className:'font-semibold text-lg',
                        element:'div',
                        label:'Change Your Password'
                    },
                    {
                        name:'comment-modal-close',
                        element:'button',
                        label:'X',
                        className:'w-[40px] h-[40px] rounded-full bg-primary text-xl text-white flex justify-center items-center'
                    }
                ]
            },
            {
                name:'modal-body',
                className:'flex flex-col p-[20px] gap-4',
                fields:[
                    {
                        name:'modal-old-password-input',
                        element:'input-password',
                        label:'Current Password',
                        className: "w-full rounded-md mt-2 px-3 py-2 bg-gray-200 text-gray-700 font-semibold focus:outline-none",
                        labelClassName: "text-sm text-gray-500 font-semibold",
                        containerClassName: "w-full flex flex-col",
                    },
                    {
                        name:'modal-new-password-input',
                        element:'input-password',
                        label:'New Password',
                        containerClassName: "w-full flex flex-col",
                        className: "w-full rounded-md mt-2 px-3 py-2 bg-gray-200 text-gray-700 font-semibold focus:outline-none",
                        labelClassName: "text-sm text-gray-500 font-semibold",
                    },
                    {
                        name:'modal-confirm-password-input',
                        element:'input-password',
                        label:'Confirm Password',
                        containerClassName: "w-full flex flex-col",
                        className: "w-full rounded-md mt-2 px-3 py-2 bg-gray-200 text-gray-700 font-semibold focus:outline-none",
                        labelClassName: "text-sm text-gray-500 font-semibold",
                    },
                    {
                        name:'modal-submit',
                        element:'button',
                        label:'Submit',
                        className:'bg-primary rounded-md text-md text-white h-[40px] mt-[10px]'
                    }
                ]
            }
            
        ]
      }
    ]
  });
  const [profileSchema,setProfileSchema]=useState<any>({
    schema:[
      {
        name:"basic_details",
        className:"flex flex-col w-full border-b-2 w-full",
        containerClassName:"!w-[30%]",
        element:"modal",
        visible:true,
        fields:[
          {
              name:'profile_modal_title',
              className:'bg-[#F2F2F2] flex justify-between rounded-lg items-center p-2 pl-4 w-full',
              fields:[
                  {
                      name:'profile_modal_title_key',
                      className:'font-semibold text-lg',
                      element:'div',
                      label:'Profile'
                  },
                  {
                      name:'profile_modal_close',
                      element:'button',
                      label:'X',
                      className:'w-[40px] h-[40px] rounded-full bg-primary text-xl text-white flex justify-center items-center'
                  }
              ]
            },
            {
              name:'profile_modal-body',
              className:'flex flex-col p-3 gap-2',
              fields:[
                  {
                      name:'profile_name',
                      element:'input-text',
                      label:'Full Name',
                      className: "w-full rounded-md px-3 py-2 bg-gray-200 text-gray-700 font-semibold focus:outline-none",
                      labelClassName: "text-sm text-gray-500 font-semibold",
                      containerClassName: "w-full flex flex-col",
                      value:fullname,
                      disabled:true,
                  },
                  {
                      name:'profile_email',
                      element:'input-text',
                      label:'Email',
                      className: "w-full rounded-md px-3 py-2 bg-gray-200 text-gray-700 font-semibold focus:outline-none",
                      labelClassName: "text-sm text-gray-500 font-semibold",
                      containerClassName: "w-full flex flex-col",
                      value:email,
                      disabled:true,
                  },
                  {
                      name:'profile_phone_number',
                      element:'input-text',
                      label:'Phone Number',
                      containerClassName: "w-full flex flex-col",
                      className: "w-full rounded-md mt-2 px-3 py-2 bg-gray-200 text-gray-700 font-semibold focus:outline-none",
                      labelClassName: "text-sm text-gray-500 font-semibold",
                      value:phonenumber,
                  },
                  {
                      name:'profile-submit',
                      element:'button',
                      label:'Submit',
                      className:'bg-primary rounded-md text-md text-white h-[40px] mt-[10px]'
                  }
              ]
          }
        ]
      }
    ]
  })
  const logoutdata: any = Schema.get("logout");
  const branchSwitch: any = Schema.get("branch_switch");

  const [showLogout,setShowLogout]=useState(false);
  const [branchSwitching, setBranchSwitching] = useState(false)
  const [showChangePassword,setShowChangePassword]=useState(false);
  const [showProfile,setShowProfile]=useState(false);
  const [loading,setLoading]=useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const [changePasswordPayload,setChangePasswordPayload] = useState<IChangePasswordPayload>({
    oldPassword:'',
    newPassword:'',
    confirmPassword:'',
  })
  const [userDetailsPayload,setUserDetailsPayload] = useState<{
    fullname:string,
    phonenumber:string
  }>({fullname:fullname,phonenumber:phonenumber})
  const indianPhoneNumberRegex = /^[6-9]\d{9}$/;

  const [lastScrollTop, setLastScrollTop] = useState(0);
  
  const [showReleaseNotes,setShowReleaseNotes]=useState(false);

  

  const [releaseNotesSchema, setReleaseNotesSchema] = useState<any>({
    schema: [
      {
        name: "release_notes_modal",
        className: "",
        element: "modal",
        width: 600,
        visible: false,
        fields: [
          {
            name:'modal_header',
            className:'bg-[#F2F2F2] flex justify-between rounded-lg items-center p-2 pl-4 w-full',
            fields:[
                {
                    name:'release_notes_header_title',
                    className:'font-semibold text-lg text-[#3341BB]',
                    element:'div',
                    label:"What's new!!"
                },
                {
                    name:'release_notes_modal_close',
                    element:'button',
                    label:'X',
                    className:'w-[40px] h-[40px] rounded-full bg-primary text-xl text-white flex justify-center items-center'
                }
            ]
          },
          {
            name: "release_notes_body",
            className: "p-4",
            fields: [
              {
                name: "release_notes_title",
                className: "text-2xl font-medium text-gray-600",
                element: "div",
                label: "N/A",
              },
              {
                name: "release_notes_version",
                className: " font-bold text-gray-400 mb-3",
                element: "div",
                label: "V 0.0.0",
              },
              {
                name: "release_notes_content",
                className: "text-md text-[#333333] max-h-[50vh] overflow-y-scroll",
                element: "div",
                label: <div className='html_parse_body' dangerouslySetInnerHTML={{ __html:"N/A" }} ></div>
              }
            ]
          }
        ]
      }
    ]
  })  


  
  useActivityTimeout();

  useEffect(() => {
    
    dispatch(setProfileImage(`${BASE_URL}/api/${Storage.getItem("organization")?.chatbot_icon}`))
    const initialPath = location.pathname;
  
    if (!localStorage.getItem('currentRoute')) {
      localStorage.setItem('currentRoute', initialPath);
    }
  
    if (!localStorage.getItem('previousRoute')) {
      localStorage.setItem('previousRoute', '/');
    }
  }, []);
  

  useEffect(()=>{
    if(showProfile==true){
      setProfileSchema({
        schema:[
          {
            name:"basic_details",
            className:"flex flex-col w-full border-b-2 w-full",
            containerClassName:"!w-[30%]",
            element:"modal",
            visible:true,
            fields:[
              {
                  name:'profile_modal_title',
                  className:'bg-[#F2F2F2] flex justify-between rounded-lg items-center p-2 pl-4 w-full',
                  fields:[
                      {
                          name:'profile_modal_title_key',
                          className:'font-semibold text-lg',
                          element:'div',
                          label:'Profile'
                      },
                      {
                          name:'profile_modal_close',
                          element:'button',
                          label:'X',
                          className:'w-[40px] h-[40px] rounded-full bg-primary text-xl text-white flex justify-center items-center'
                      }
                  ]
                },
                {
                  name:'profile_modal-body',
                  className:'flex flex-col p-3 gap-2',
                  fields:[
                      {
                          name:'profile_name',
                          element:'input-text',
                          label:'Full Name',
                          className: "w-full rounded-md px-3 py-2 bg-gray-200 text-gray-700 font-semibold focus:outline-none",
                          labelClassName: "text-sm text-gray-500 font-semibold",
                          containerClassName: "w-full flex flex-col",
                          value:fullname,
                          disabled:true,
                      },
                      {
                          name:'profile_email',
                          element:'input-text',
                          label:'Email',
                          className: "w-full rounded-md px-3 py-2 bg-gray-200 text-gray-700 font-semibold focus:outline-none",
                          labelClassName: "text-sm text-gray-500 font-semibold",
                          containerClassName: "w-full flex flex-col",
                          value:email,
                          disabled:true,
                      },
                      {
                          name:'profile_phone_number',
                          element:'input-text',
                          label:'Phone Number',
                          containerClassName: "w-full flex flex-col",
                          className: "w-full rounded-md mt-2 px-3 py-2 bg-gray-200 text-gray-700 font-semibold focus:outline-none",
                          labelClassName: "text-sm text-gray-500 font-semibold",
                          value:phonenumber,
                      },
                      {
                          name:'profile-submit',
                          element:'button',
                          label:'Submit',
                          className:'bg-primary rounded-md text-md text-white h-[40px] mt-[10px]'
                      }
                  ]
              }
            ]
          }
        ]
      })
    }
  },[showProfile])

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      
      if (currentScrollTop > lastScrollTop) {
        // Scrolling down
        dispatch(setTopBarVisibility(true));
      } else {
        // Scrolling up
        dispatch(setTopBarVisibility(false));
      }
      
      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop, dispatch]);

  // useEffect(()=>{
  //   FetchCurrentReleaseVersion()
  // }, [])


  const FetchCurrentReleaseVersion = async() =>{
    try{
      const res:any=await Utils.makeFormdataApiCall("/currentReleaseNotes","GET");
      if(res.status){
        
        Storage.setItem("currentVersion", res?.data?.latestVersion)
        Storage.setItem("userVersion", res?.data?.userVersion)
        
        if(res?.data?.latestVersion !== res?.data?.userVersion){
          setShowReleaseNotes(true)
          setReleaseNotesSchema((prev: any)=>{
            prev?.schema?.map((f: any)=>{
              if(f.name === "release_notes_modal"){
                f.visible = true
                f?.fields?.map((ff: any)=>{
                  if(ff.name === "release_notes_body"){
                    
                    ff?.fields?.map((s: any)=>{
                        if(s.name === "release_notes_title"){
                          s.label = res?.data?.latestReleaseNotes?.title
                        } else if(s.name === "release_notes_version"){
                          s.label = res?.data?.latestReleaseNotes?.version
                        } else if(s.name === "release_notes_content"){
                          s.label = <div className='html_parse_body' dangerouslySetInnerHTML={{ __html: `${res?.data?.latestReleaseNotes?.content}` }} ></div>
                        }
                    })
                  }
                })
              }
            })
            return {...prev}
          })
        }
      }
    }catch(e){
      console.log(e);
    }
  }



  useEffect(() => {
    const currentPath = location.pathname;
    const storedPreviousPath = localStorage.getItem('previousRoute');
    const storedCurrentPath = localStorage.getItem('currentRoute') || "/";
  
    if (storedCurrentPath !== currentPath) {
      localStorage.setItem('previousRoute', storedCurrentPath);
      localStorage.setItem('currentRoute', currentPath);
    }
    if (currentPath.includes("add") || currentPath.includes("view") || currentPath.includes("edit") || currentPath.includes("compareUnits")) {
      dispatch(setBackiconvisibility(true));
    } else {
      dispatch(setBackiconvisibility(false));
    }
  
    if (sideBar?.schema?.[0]?.items) {
      updateTopbarAndSidebar(currentPath);
    }
  }, [location, sideBar]);
  

  const updateTopbarAndSidebar = (pathname: string) => {
    if (sideBar?.schema?.[0]?.items) {
      const sidebarItems = sideBar.schema[0].items;
      const matchedItem = findMatchingMenuItem(sidebarItems, pathname);
  
      if (matchedItem) {
        dispatch(setTopbar(matchedItem.label));
        dispatch(setActiveMenu(matchedItem.route));
      }
    }
  };

  const findMatchingMenuItem = (items: any[], pathname: string): any => {
    if (!Array.isArray(items)) return null;
    
    for (const item of items) {
      if (item.route && (pathname==item.route)) {
        return item;
      }
      if (Array.isArray(item.submenu) && item.submenu.length > 0) {
        const submenuMatch = findMatchingMenuItem(item.submenu, pathname);
        if (submenuMatch) {
          return submenuMatch;
        }
      }
    }
    return null;
  };

  useEffect(() => {    
    if (schemas.length) {
      fetchSideMenus();
      fetchBranches();
    }
  }, [schemas]);

  useEffect(() => {
    const checkAuth = () => {
      const details = localStorage.getItem("details");
      if (!details) {
        navigate("/", { replace: true });
      }
    };

    checkAuth(); 
  }, [navigate]);

  const API_POST_ChangePassword = async() => {
    if(!changePasswordPayload.oldPassword){
        toast.error('Please enter your current password',{ position: "top-center", autoClose: AUTO_CLOSE })
        return
    }else if(!changePasswordPayload.newPassword){
        toast.error('Please enter your new password',{ position: "top-center", autoClose: AUTO_CLOSE })
        return
    }else if(!changePasswordPayload.confirmPassword){
        toast.error('Please confirm your new password',{ position: "top-center", autoClose: AUTO_CLOSE })
        return
    }else if(changePasswordPayload.confirmPassword !== changePasswordPayload.newPassword){
        toast.error('Passwords do not match',{ position: "top-center", autoClose: AUTO_CLOSE })
        return
    }
    setLoading(true)
    try{
      const response = await Utils.makeApiCall(`/changePassword?userId=${Storage.getItem('details')['_id']}`,'POST',changePasswordPayload)
      if(response && response.status){
          toast.success(response.message,{ position: "top-center", autoClose: AUTO_CLOSE })
          setShowChangePassword(false)
      }else{
          
          toast.error(response.message ? response.message : FAILURE_MSG,{ position: "top-center", autoClose: AUTO_CLOSE })
      } 
    }catch(error){
      toast.error(CATCH_ERROR_MSG,{position:"top-center",autoClose:AUTO_CLOSE})
    }
    setLoading(false)
  }

  const API_PUT_Userdetails=async()=>{
    const trimmedFullname = userDetailsPayload.fullname.trim();
    const trimmedPhonenumber = userDetailsPayload.phonenumber.trim();
    if(trimmedFullname.length===0){
      toast.error("User fullname cannot be empty",{position:"top-center",autoClose:AUTO_CLOSE})
      return
    }
    if(!indianPhoneNumberRegex.test(trimmedPhonenumber)){
      toast.error("Please enter a valid phone number",{position:"top-center",autoClose:AUTO_CLOSE})
      return
    }
    setLoading(true)
    try{
      const res:any=await Utils.makeApiCall(`/updateUserDetailsById`,"PUT",{ id:Storage.getItem("details")["id"],fullname: trimmedFullname, phonenumber: trimmedPhonenumber,email:Storage.getItem("details")["email"] });
      if(res && res.status){
        Storage.setItem('details',{...Storage.getItem("details"),fullname:trimmedFullname,phonenumber:trimmedPhonenumber})
        toast.success(res.message,{ position: "top-center", autoClose: AUTO_CLOSE })
        dispatch(setProfileName(trimmedFullname))
        setShowProfile(false)
      }else{  
        toast.error(res.message ? res.message : FAILURE_MSG,{ position: "top-center", autoClose: AUTO_CLOSE })
      } 
    }catch(error){
      toast.error(CATCH_ERROR_MSG,{position:"top-center",autoClose:AUTO_CLOSE})
    }
    setLoading(false)
  }

  const fetchBranches = () => {
    const processFields = (obj: any) => {
      if (obj["fields"] && obj["fields"].length) {
        obj.fields.forEach(processFields);
      } else {
        if (obj.endPoint || obj.firstEndpoint) {
          API_GET_Options({ name: obj.name, url: obj.endPoint || obj.firstEndpoint });
        }
      }
    };
    topBar.schema.forEach(processFields); 
  }

  const fetchSideMenus = async () => {
    const url = `/getAccountUsrMenu`;
    try {
      const response = await Utils.makeApiCall(url, "POST", {});
      if (response.status) {
        const items = response.data;
        // items.push({
        //   "id": 122,
        //   "label": "Logout",
        //   "icon": "logout",
        //   "route": "$",
        //   "key": "$",
        //   "position": 9
        // })
        dispatch(setSidebarItems(items))
        const item = response.data.find((e: any) => {
            if (e?.submenu?.length > 0) {
              return e.submenu.find((eff:any)=> location.pathname.includes(eff.route))
            } else if (location.pathname.includes(e.route)){
              return e;
            }
          }
        );

        if (item) {
          handleSidebarChange( location.pathname);
        }
      }
    } catch (error) {
      console.error("Failed to fetch organization data", error);
    }
  };

  const API_GET_Options = async ({ name = "", url = "" }) => {

    try {
      const res = await Utils.makeApiCall(url, "GET");
      if (res.status) {
        const options = res.data.map((op:any)=>({ label: op.branch_name, value: op.branch_id }))
        dispatch(setTopbarBranches(options));
      } 
    } catch (error) {
      console.error("Failed to fetch schema", error);
    }

  };

  const API_refreshToken = async (branch:any) => {
    setBranchSwitching(true);
    const branch_id = branch?.value;
    try {
      const res = await Utils.makeApiCall("/switchBranch", "POST", { branch_id });
      if (res.status) {
        dispatch(setActiveBranch(branch));
        window.location.reload()
      } 
    } catch (error) {
      console.error("Failed to change unit", error);
    }
    // setBranchSwitching(false);
  };

  const handleSidebarChange = ( route: string) => {
    if (route !== "$") {
      navigate(route);
      updateTopbarAndSidebar(route);
    }
  };

    

  const handleSelectedRecord = (e: any) => {    
    if (e.value === "$") {
      setShowLogout(true);
    }
    else if (e.name === "sidenav") {
      handleSidebarChange( e?.value);
    }
  };

  const handleLogout = (e: any) => {
    if (e.name === "cancel_button") {
      setShowLogout(false);
    }
    if (e.name === "logout_button") {
      navigate("/", { replace: true });
      try {
        dispatch(setUISchemas({data: []}));
        dispatch(setActiveBranch({}))
      } catch (error) {
        console.log(error);
      }
      Storage.clearAll();
      localStorage.removeItem('routeStack');
      window.location.reload()
    }
  };

  const handleSelect=(e:any)=>{
    if (e.name === "back_icon") {
      const previousRoute = localStorage.getItem('previousRoute');
      if (previousRoute) {
        navigate(previousRoute);
      } else {
        
        navigate(-1);
      }
    }else if (e.name === "branches"){
      API_refreshToken(e.value);
    }
    if(e.name=="snippet-copy"){
      if(e.value.key==3){
        setShowLogout(true)
      }
      if(e.value?.key==1){
        setShowProfile(true)
      }
      if(e.value?.key==2){
        setShowChangePassword(true)
      }
    }
  }

 const handlePassword=(e:any)=>{
    if(e.name=="comment-modal-close"){
      setShowChangePassword(false)
    }
    if(e.name=="modal-old-password-input"){
      setChangePasswordPayload({...changePasswordPayload,oldPassword:e.value})
    }
    if(e.name=="modal-new-password-input"){
      setChangePasswordPayload({...changePasswordPayload,newPassword:e.value})
    }
    if(e.name=="modal-confirm-password-input"){
      setChangePasswordPayload({...changePasswordPayload,confirmPassword:e.value})
    }
    if(e.name=="modal-submit"){
      API_POST_ChangePassword()
    }
  }

  const handleProfile=(e:any)=>{
    if(e.name=="profile_modal_close"){
      setShowProfile(false)
    }
    if(e.name=="profile_name"){
      setUserDetailsPayload({...userDetailsPayload,fullname:e.value})
    }
    if(e.name=="profile_phone_number"){
      
      setUserDetailsPayload({...userDetailsPayload,phonenumber:e.value})
    }
    if(e.name=="profile-submit"){
      API_PUT_Userdetails()
    }
  }

  const updateUserVersion = async() =>{
    try{
      // updateUserVersion
      const res:any=await Utils.makeFormdataApiCall("/updateUserVersion","GET");
      if(res.status){
        setShowReleaseNotes(false)
        FetchCurrentReleaseVersion()
      }
    }catch(e){
      console.log(e)
    }
    
  }

  const handleReleaseNotesSelect = (e: any) =>{
    if (e.name === "release_notes_modal_close"){
      updateUserVersion()
    }
    
  }


  return (
    <div className="flex">
      {loading && <LoadingState/>}
      <ElementExecutor data={JSON.parse(JSON.stringify(sideBar))} selectedRecord={handleSelectedRecord} />

      {showReleaseNotes && <ElementExecutor data={releaseNotesSchema} selectedRecord={(e:any)=>{handleReleaseNotesSelect(e)}} />}

      {showLogout && <ElementExecutor data={logoutdata} selectedRecord={(e:any)=>{handleLogout(e)}} />}
       {showChangePassword && <ElementExecutor data={passwordschema} selectedRecord={(e:any)=>{handlePassword(e)}} />}
      {showProfile && <ElementExecutor data={profileSchema} selectedRecord={(e:any)=>{handleProfile(e)}} />}
      {branchSwitching && <ElementExecutor data={branchSwitch} />}
      <div className="flex-1">
        <ElementExecutor data={JSON.parse(JSON.stringify(topBar))} key={JSON.stringify(topBar)} selectedRecord={(e:any)=>handleSelect(e)}/>
        <div className="p-4 px-6 bg-[#EFF1F9] min-h-dvh">
          <Suspense fallback={<TableSkeleton />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default Layout;