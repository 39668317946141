export const CONSTANTS= {
    BASE_URL: "https://demo.apexcura.com",
    SOCKET_URL: "wss://demo.apexcura.com/apexws",
    // BASE_URL:"http://localhost:5000",
    // BASE_URL: "http://192.168.0.133:3001",
    TEST_ORG_ID: "665fe952baaf450e2415d76c",
    CATCH_ERROR_MSG:"Oops, something is wrong. Please try again.",
    FAILURE_MSG:"Oops! something is wrong. Please try again.",
    AUTO_CLOSE:2000,
    WHATSAPP_BOT_OPENER: 'https://api.whatsapp.com/send?phone=918374099022&text=Hi,%20Apexcura' ,
    clientId : '1041288141286-t7gs4s9h3aqoq2oh6b147qnkql9gpfe7.apps.googleusercontent.com'
}