import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ElementExecutor } from '@apexcura/core';
import { toast } from 'react-toastify';
import { CONSTANTS } from '../utils/app-constants';
import Utils from '../utils';
import LoadingState from './LoadingState';
import Storage from '../utils/local-storage';
import { useDispatch, useSelector } from 'react-redux';
import { getPagePermissions } from '../redux/auth/authSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { setActiveMenu, setTopbar } from '../redux/uiSchema/uiSchemaSlice';
import {Tooltip,Avatar} from "antd"
import {Popover} from "antd"
import axios from 'axios';
const { BASE_URL, FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;

const newCards:any=[
    {
        name: 'avg_sent_score',
        visible: true,
        className:
            ' rounded-md cursor-default flex border-[#93B8E2] gap-2 items-center justify-between border-[1px]  p-[10px]',
        element: '',
        fields: [
            {
              name:"avg_sent_score_icon_and_value",
              className:"flex gap-2 justify-center items-center",
              fields:[
                {
                  name: 'avg_sent_score_icon',
                  className: '',
                  iconsClassName:"w-8 h-8  cursor-default",
                  element: 'button',
                  icon:`${BASE_URL}/api/public/assets/images/calls-rounded.svg`,
                  isSVGStylesOverride: false,
                  visible: true,
                },
                {
                  name: 'avg_sent_score_text',
                  className: 'text-sm font-semibold text-[#505050]',
                  element: 'div',
                  label: 'Sentiment Score',
                  visible: true,
                },
              ]
            },
            {
              name: 'avg_sent_score_value',
              className:'text-base font-semibold text-[#254AAA]',
              element: 'div',
              label: '23',
              visible: true,
          },
        ],
    },
    {
      name: 'avg_call_quality',
      visible: true,
      className:
          ' rounded-md flex cursor-default border-[#93B8E2] gap-2 items-center justify-between border-[1px]  p-[10px]',
      element: '',
      fields: [
          {
            name:"avg_call_quality_icon_and_value",
            className:"flex gap-2 justify-center items-center",
            fields:[
              {
                name: 'avg_call_quality_icon',
                className: '',
                iconsClassName:"w-8 h-8  cursor-default",
                element: 'button',
                icon:`${BASE_URL}/api/public/assets/images/calls-rounded.svg`,
                isSVGStylesOverride: false,
                visible: true,
              },
              {
                name: 'avg_call_quality_text',
                className: 'text-sm font-semibold text-[#505050]',
                element: 'div',
                label: 'Call Quality',
                visible: true,
              },
            ]
          },
          {
            name: 'avg_call_quality_value',
            className:'text-base font-semibold text-[#254AAA]',
            element: 'div',
            label: '45',
            visible: true,
        },
      ],
    },
]

const Calls = () => {
    const [schema, setSchema] = useState<any>({
        className:
          'w-full bg-white rounded-lg py-[10px] px-[20px] flex flex-col gap-3',
        schema: [
          {
            name: "agent_stats_modal",
            element: "modal",
            className: "",
            visible: false,
            width: 900,
            fields: [
                  {
                    name: "modal_head",
                    className: "flex justify-between items-center p-4 bg-[#f2f2f2]",
                    fields: [
                        {
                            name:'modal_title_key',
                            className:'font-semibold text-lg  text-[#3341BB]',
                            element:'div',
                            label:'Agent Statistics'
                        },
                        {
                            name: "agent_stats_modal_close",
                            element: "button",
                            label: "X",
                            className:
                              "!w-[35px] !h-[35px] rounded-full bg-primary text-xl text-white flex justify-center items-center",
                        },
                    ]
                },
                {
                  name: 'agent_modal_filters',
                  fields: [
                    {
                      name:"agent_modal_date_and_search",
                      className:"flex gap-2 justify-center items-center ",
                      fields:[
                        {
                          name: "agent_modal_search",
                          label: "",
                          placeholder: "Mobile...",
                          isSearch:true,
                          element: "input-text",
                          value: "",
                          className: "",
                          icon:"search",
                          iconsClassName:"w-[15px] flex justify-center items-center"
                          
                        },
                        {
                          name:"agent_modal_segments",
                          element:"segmented",
                          segmentedOptions:[
                            {
                                label:(<Tooltip title="Today">1D</Tooltip>),
                                value:"TD"
                            },
                            // {
                            //     label:(<Tooltip title="Yesterday">YD</Tooltip>),
                            //     value:"YD"
                            // },
                            {
                                label:(<Tooltip title="This Week">1W</Tooltip>),
                                value:"TW"
                            },
                            {
                                label:(<Tooltip title="This Month">1M</Tooltip>),
                                value:"TM"
                            },
                          ],
                          visible:true,
                          value:"TW"
                        },
                        {
                          name: 'agent_modal_date',
                          label: '',
                          placeholder: 'Select a date',
                          element: 'daterangepicker',
                          value: [moment().subtract(7, 'days').format('DD-MM-YYYY'), moment().format("DD-MM-YYYY")],
                          className: 'flex-1 w-[240px]',
                        },
                      ]
                    },
                  ],
                  className: 'flex justify-between items-center gap-2 bg-white p-4',
                },
                {
                  name: "agent_stats_table",
                  className: 'p-4',
                  element: 'table',
                  variant: 'plain',
                  size: 'small',
                  pagination: true,
                  sortable: true,
                  value: { name: 'pagination', page: 1, pageSize: 10 },
                  thead: [
                      {
                        name: "agent",
                        label: "Agent Number",
                        key: "agent",
                      },
                      {
                        name: "answered_calls",
                        label: "Answered",
                        key: "answered_calls",
                      },
                      {
                        name: "missed_calls",
                        label: "Missed Calls",
                        key: "missed_calls",
                      },
                      {
                        name: "patient_hungup",
                        label: "Patient HangUps",
                        key: "patient_hungup",
                      },
                      {
                        name: "total_calls",
                        label: "Total Calls",
                        key: "total_calls",
                      },
                    ],
                  tbody: [],
                },
            ]
          },
          {
              name: 'call_details',
              visible: true,
              className:
                  'grid grid-cols-6 gap-2 font-semibold w-full',
              // fields: [
              //     {
              //         name: 'total_calls',
              //         visible: true,
              //         className:
              //             ' rounded-md flex border-[#93B8E2] gap-2 items-center justify-between border-[1px]  p-[10px]',
              //         element: '',
              //         fields: [
              //             {
              //               name:"total_calls_icon_and_value",
              //               className:"flex gap-4 justify-center items-center",
              //               fields:[
              //                 {
              //                   name: 'total_calls_icon',
              //                   className: '',
              //                   iconsClassName:"w-10 h-10  cursor-default",
              //                   element: 'button',
              //                   icon:`${BASE_URL}/api/public/assets/images/calls-rounded.svg`,
              //                   isSVGStylesOverride: false,
              //                   visible: true,
              //                 },
              //                 {
              //                   name: 'total_calls_text',
              //                   className: 'text-sm font-semibold text-[#505050]',
              //                   element: 'div',
              //                   label: 'Total Calls',
              //                   visible: true,
              //                 },
              //               ]
              //             },
              //             {
              //               name: 'total_calls_value',
              //               className:'text-xl font-semibold text-[#254AAA]',
              //               element: 'div',
              //               label: '0',
              //               visible: true,
              //           },
              //         ],
              //     },
              //     {
              //         name: 'answered_calls',
              //         visible: true,
              //         className:
              //             ' rounded-md flex border-[#93B8E2] items-center justify-between border-[1px] gap-2 p-[10px]',
              //         element: '',
              //         fields: [
              //             {
              //               name:"total_answered_icon_and_value",
              //               className:"flex gap-4 justify-center items-center",
              //               fields:[
              //                   {
              //                       name: 'total_answered_icon',
              //                       className: '',
              //                       iconsClassName:"w-10 h-10 cursor-default",
              //                       element: 'button',
              //                       icon:`${BASE_URL}/api/public/assets/images/calls-answered.svg`,
              //                       isSVGStylesOverride: false,
              //                       visible: true,
              //                   },
              //                   {
              //                       name: 'answered_calls_text',
              //                       className: 'text-sm font-semibold flex gap-2 text-[#505050]',
              //                       element: 'div',
              //                       label: 'Answered Calls',
              //                       visible: true,
              //                   },
              //                 ]
              //             },
              //             {
              //                 name: 'answered_calls_value',
              //                 className:'text-xl font-semibold text-[#254AAA]',
              //                 element: 'div',
              //                 label: '0',
              //                 visible: true,
              //             },
              //         ],
              //     },
              //     {
              //         name: 'total_missed_calls',
              //         className:
              //             'rounded-md flex border-[#93B8E2]  items-center justify-between border-[1px]  gap-2 p-[10px]',
              //         element: '',
              //         visible: true,
              //         fields: [
              //             {
              //               name:"total_missed_icon_and_value",
              //               className:"flex gap-4 justify-center items-center",
              //               fields:[
              //                 {
              //                   name: 'total_missed_icon',
              //                   className: '',
              //                   iconsClassName:"w-10 h-10 cursor-default",
              //                   element: 'button',
              //                   icon:`${BASE_URL}/api/public/assets/images/calls-missed.svg`,
              //                   isSVGStylesOverride: false,
              //                   visible: true,
              //                 },
              //                 {
              //                   name: 'total_missed_calls_text',
              //                   className: 'text-sm font-semibold flex gap-2 text-[#505050]',
              //                   element: 'div',
              //                   label: 'Missed Calls',
              //                   visible: true,
              //                 },
              //               ]
              //             },
              //             {
              //                 name: 'total_missed_calls_value',
              //                 className:'text-xl font-semibold text-[#254AAA]',
              //                 element: 'div',
              //                 label: '0',
              //                 visible: true,
              //             },
              //         ],
              //     },
              //     {
              //         name: 'total_callback_requests',
              //         visible: true,
              //         className:
              //             'rounded-md flex border-[#93B8E2]  items-center justify-between border-[1px]  gap-2 p-[10px]',
              //         element: '',
              //         fields: [
              //             {
              //               name:"total_callback_icon_and_value",
              //               className:"flex gap-4 justify-center items-center",
              //               fields:[
              //                 {
              //                     name: 'total_callback_icon',
              //                     className: '',
              //                     iconsClassName:"w-10 h-10 cursor-default",
              //                     element: 'button',
              //                     icon:`${BASE_URL}/api/public/assets/images/callback.svg`,
              //                     isSVGStylesOverride: false,
              //                     visible: true,
              //                 },
              //                 {
              //                     name: 'total_callback_requests_text',
              //                     className: 'text-sm font-semibold flex gap-2 text-[#505050]',
              //                     element: 'div',
              //                     label: 'Callback Requests',
              //                     visible: true,
              //                 },
              //               ]
              //             },
              //             {
              //                 name: 'total_callback_requests_value',
              //                 className:'text-xl font-semibold text-[#254AAA]',
              //                 element: 'div',
              //                 label: '0',
              //                 visible: true,
              //             },
              //         ],
              //     },
              //     {
              //         name: 'total_ambulance_requests',
              //         visible: true,
              //         className:
              //             'rounded-md flex border-[#93B8E2]  items-center justify-between border-[1px]  gap-2 p-[10px]',
              //         element: '',
              //         fields: [
              //             {
              //               name:"total_ambulance_icon_and_value",
              //               className:"flex gap-4 justify-center items-center",
              //               fields:[
              //                 {
              //                   name: 'total_ambulance_icon',
              //                   className: '',
              //                   iconsClassName:"w-10 h-10 cursor-default",
              //                   element: 'button',
              //                   icon:`${BASE_URL}/api/public/assets/images/ambulance-requests.svg`,
              //                   isSVGStylesOverride: false,
              //                   visible: true,
              //                 },
              //                 {
              //                   name: 'total_ambulance_requests_text',
              //                   className: 'text-sm font-semibold flex gap-2 text-[#505050]',
              //                   element: 'div',
              //                   label: 'Ambulance requests',
              //                   visible: true,
              //                 },
              //               ]
              //             },
                          
              //             {
              //                 name: 'total_ambulance_requests_value',
              //                 className:'text-xl font-semibold text-[#254AAA]',
              //                 element: 'div',
              //                 label: '0',
              //                 visible: true,
              //             },
              //         ],
              //     },
              //     // {
              //     //   name: 'total_hangup_calls',
              //     //   visible: true,
              //     //   className:
              //     //       'rounded-md flex border-[#93B8E2]  items-center justify-between border-[1px]  gap-2 p-[10px]',
              //     //   element: '',
              //     //   fields: [
              //     //       {
              //     //         name:"total_hangup_calls_icon_text",
              //     //         className:"flex gap-4 justify-center items-center",
              //     //         fields:[
              //     //           {
              //     //             name: 'total_hangup_calls_icon',
              //     //             className: '',
              //     //             iconsClassName:"w-10 h-10 cursor-default",
              //     //             element: 'button',
              //     //             icon:`${BASE_URL}/api/public/assets/images/hangupcalls.svg`,
              //     //             isSVGStylesOverride: false,
              //     //             visible: true,
              //     //           },
              //     //           {
              //     //             name: 'total_hangup_calls_text',
              //     //             className: 'text-xs font-semibold flex gap-2 text-[#505050]',
              //     //             element: 'div',
              //     //             label: 'Hangup Calls',
              //     //             visible: true,
              //     //           },
              //     //         ]
              //     //       },
                        
              //     //       {
              //     //           name: 'hangup_calls_value',
              //     //           className:'text-xl font-semibold text-[#254AAA]',
              //     //           element: 'div',
              //     //           label: '0',
              //     //           visible: true,
              //     //       },
              //     //   ],
              //     // },
              // ],
              fields:[]
          },
          {
            name: 'filters',
            fields: [
              {
                name:"date_and_search",
                className:"flex gap-2 justify-center items-center ",
                fields:[
                  {
                    name: "search",
                    label: "",
                    placeholder: "Mobile...",
                    isSearch:true,
                    element: "input-text",
                    value: "",
                    className: "",
                    icon:"search",
                    iconsClassName:"w-[15px] flex justify-center items-center"
                    
                  },
                  {
                    name:"segments",
                    element:"segmented",
                    segmentedOptions:[
                      {
                          label:(<Tooltip title="Today">1D</Tooltip>),
                          value:"TD"
                      },
                      // {
                      //     label:(<Tooltip title="Yesterday">YD</Tooltip>),
                      //     value:"YD"
                      // },
                      {
                          label:(<Tooltip title="This Week">1W</Tooltip>),
                          value:"TW"
                      },
                      {
                          label:(<Tooltip title="This Month">1M</Tooltip>),
                          value:"TM"
                      },
                    ],
                    visible:true,
                    value:"TW"
                  },
                  {
                    name: 'date',
                    label: '',
                    placeholder: 'Select a date',
                    element: 'daterangepicker',
                    value: [moment().subtract(7, 'days').format('DD-MM-YYYY'), moment().format("DD-MM-YYYY")],
                    className: 'flex-1 w-[240px]',
                  },
                ]
              },
              {
                name:"refresh_and_download",
                className:"flex gap-2 justify-center items-center ",
                fields:[
                  {
                    name: 'btn_agent_stats',
                    label: 'Agent Statistics',
                    element: 'button',
                    className: 'secondary-button',
                    visible:false,
                  },
                  {
                    name: 'btn_refresh',
                    label: 'Refresh',
                    element: 'button',
                    icon: 'refresh',
                    className: 'secondary-button',
                    action: 'download_list',
                    iconsClassName: 'size-4',
                  },
                  {
                    name: 'btn_download',
                    label: 'Download',
                    element: 'button',
                    icon: 'download',
                    visible: false,
                    className: 'secondary-button',
                    action: 'download_list',
                    iconsClassName: 'size-4',
                  },
                ]
              },
            ],
            className: 'flex justify-between items-center gap-2 bg-white',
          },
          {
            count:0,
            className: 'mt-2 bg-white',
            name: "table",
            element: 'table',
            loading: true,
            variant: 'plain',
            size: 'small',
            pagination: true,
            rowClick: true,
            view: true,
            visible: true,
            value:null,
            thead: [
              {
                name:"Name",label:"Customer",key:"Name",
                render:(e:any,record:any)=>{
                  return (
                    <div className="flex gap-1 items-center">
                      <Avatar size={"small"} style={{backgroundColor:`${Utils.stringToColour(e)}`}}> 
                              <p className={`capitalize`}>{Utils.initials(e)}</p>
                      </Avatar>
                      <div className={"capitalize"}>{e}<p className='text-xs'>({record["From"]})</p></div>
                    </div>
                  )
                }
              },
              // { name: 'From', label: 'From', key: 'From' },
              { name: 'DialWhomNumber', label: 'Agent', key: 'DialWhomNumber' },
              // { name: 'To', label: 'IVR', key: 'To' },
              // {name: 'Direction', label: 'Direction', key: 'Direction'},
              {
                name: 'StartTime',
                label: 'Arrived on',
                key: 'StartTime',
              },
              {
                name: 'DialCallDuration',
                label: 'Duration',
                key: 'DialCallDuration',
              },
              {
                name:"status",
                label:"Status",
                key:"status",
                filters: [
                  { text: 'None', value: 'None' },
                  { text: 'Open', value: 'Open' },
                  { text: 'Pending', value: 'Pending' },
                  { text: 'Closed', value: 'Closed' },
                ],
                render:(e:any,record:any)=>{
                  const colors:any = {
                    "None": "bg-[#29AAE1]/10 text-[#29AAE1]",
                    "Open": "bg-fuchsia-500/5 text-fuchsia-500",
                    "Pending": "bg-yellow-500/10 text-yellow-500",
                    "Closed/Callback-done": "bg-green-500/5 text-green-500",
                    "Closed/Call-back done": "bg-green-500/5 text-green-500",
                    "Callback": "bg-fuchsia-500/5 text-fuchsia-500",
                    "Closed": "bg-green-500/5 text-green-500  ",
                };
                  const bgColor:string = colors[record?.status];
                  return <span className={`w-full p-1 px-2 rounded-md font-medium ${bgColor}`}>{e}</span>
                }
              },
              { name: 'DialCallStatus', label: 'Call Type', key: 'DialCallStatus',
                render:(e:any,record:any)=>{
                const colors:any = {
                  "Call Back Request": "bg-[#29AAE1]/5 text-[#29AAE1]",
                  "Ambulance Request": "bg-[#BF0E0E]/5 text-[#BF0E0E]",
                  "Call Was Successful": "bg-[#4DA723]/5 text-[#4DA723]",
                  "Client Hungup": "bg-[#F59F0D]/5 text-[#F59F0D]",
                  "Missed Call": "bg-[#F9A07A]/5 text-[#F37748]"
                };
                const bgColor:string = colors[record?.DialCallStatus] || "bg-[#F2F2F2]/50 text-default-300"
                return <span className={`w-full p-1 px-2 rounded-md font-medium ${bgColor}`}>{e}</span>
                
              } },
              {
                name: 'sentiment_score',
                label: 'Sentiment Score',
                key: 'sentiment_score',
              },
              {
                name: 'quality_score',
                label: 'Quality Score',
                key: 'quality_score',
                render:(e:any,record:any)=>{
                  const colors:any = {
                    "<50": "bg-[#BF0E0E]/5 text-[#BF0E0E]",
                    "81-100": "bg-[#4DA723]/5 text-[#4DA723]",
                    "51-80": "bg-[#F9A07A]/5 text-[#F37748]"
                  };
                  const bgColor:string =e<=50? colors["<50"]: e>=51 && e<=80?colors["51-80"]: colors["81-100"]
                  return <span className={`w-full p-1 px-2 rounded-md font-medium ${bgColor}`}>{e}</span>
                }
              },
            ],
            tbody: [],
            modalContent: {
              className: "flex flex-col gap-2 overflow-auto max-h-[400px] p-[10px]",
              fields: [],
            },
          },
          {
            name: 'view_modal_container',
            className: "",
            containerClassName:'!w-[50%] flex flex-col bg-white border shadow-lg rounded-lg',
            element: "modal",
            visible: false,
            fields:[
              {
                name: 'modal_title',
                className:
                  'w-full bg-[#F2F2F2] p-2 pl-4 flex items-center justify-between',
                fields: [
                  {
                    name: 'modal_title_key',
                    element: 'div',
                    label: 'Call Transcripts',
                    className:
                      'font-semibold text-lg text-primary',
                  },
                  {
                    name: 'modal_close',
                    element: 'button',
                    label: 'X',
                    className:
                      'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
                  },
                ],
              },
              {
                name: 'modal_body',
                className:
                  'flex flex-col max-h-[400px] p-4  overflow-auto gap-3',
                fields: [
                  {
                    name:"audio",
                    element:"div",
                    label:"",
                    className:"h-[20%] w-full"
                  },
                  {
                    name:"audio_text",
                    element:"div",
                    label:"",
                    className:"h-[60%] w-full overflow-scroll [&::-webkit-scrollbar]:hidden font-base text-base text-black"
                  }
                ],
              },
            ]
          },
        ],
    });
    const [dateRange, setDateRange] = useState<[string, string]>([
      moment().subtract(7, 'days').format('DD-MM-YYYY'),
      moment().format('DD-MM-YYYY'),
    ])

    const [agentDateRange, setAgentDateRange] = useState<[string, string]>([
      moment().subtract(7, 'days').format('DD-MM-YYYY'),
      moment().format('DD-MM-YYYY'),
    ])

    const [searchText, setSearchText] = useState('');
    const [agentSearchText, setAgentSearchText] = useState('');
    const [loading, setLoading] = useState<boolean>(false);
    const [pagination,setPagination]=useState({pagenum:1,limit:10});
    const [filters,setFilters]=useState({DialCallStatus:[],status:[]})
    const dispatch=useDispatch()
    const location=useLocation()
    const navigate=useNavigate();
    const [calltype,setCalltype]=useState("");

    const org_id = Storage.getItem("details")?.["org_id"];

    const pagePermissions = useSelector(getPagePermissions)

    const change_calls_data = (data: any) => {
      const download = pagePermissions.find((r:any) => r.route === location.pathname)?.download || false;
      const add = pagePermissions.find((r:any) => r.route === location.pathname)?.add || false;
      const view = pagePermissions.find((r:any) => r.route === location.pathname)?.view || false;
      setSchema((prev:any)=>{
        const updatedschema=prev.schema.map((field:any)=>{
          if(field.name==="call_details"){
            field.fields=field.fields.map((ffield:any)=>{
              ffield.fields=ffield.fields.map((fffield:any)=>{
                if(fffield.name==="total_calls_value"){
                  fffield.label=data?.counts?.totalCalls;
                }
                if(fffield.name==="answered_calls_value"){
                  fffield.label=data?.counts?.totalAnsweredCalls;
                }
                if(fffield.name==="total_missed_calls_value"){
                  fffield.label=data?.counts?.totalMissedCalls;
                }
                if(fffield.name==="total_callback_requests_value"){
                  fffield.label=data?.counts?.totalCallBackRequest;
                }
                if(fffield.name==="total_ambulance_requests_value"){
                  fffield.label=data?.counts?.totalAmbulanceRequest;
                }
                if(fffield.name==="hangup_calls_value"){
                  fffield.label=data?.counts?.totalHangupSessions;
                }
                return fffield;
              })
              return ffield;
            })
          }
          if(field.name==="filters"){
            field.fields=field.fields.map((ffield:any)=>{
              if(ffield.name==="refresh_and_download"){
                ffield.fields=ffield.fields.map((fffield:any)=>{
                  if(fffield.name==="btn_download"){
                    fffield.visible = download
                  }
                  return fffield;
                })
              }
              return ffield;
            })
          }
          if(field.name==="table"){
            field.loading = false;
            field.count = data?.total;
            field.tbody=data?.data.map((item:any) => {
              item.StartTime = moment(item.StartTime, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY HH:mm");
              item["sentiment_score"]=Math.floor(Math.random() * 100) + 1;
              item["quality_score"]=Math.floor(Math.random() * 100) + 1;
              return item;
            });
            const hasactions = field["thead"].some((ef:any)=>ef.name === "actions");
              if ((view || add) && !hasactions){
                field['thead'].push({
                  name: 'actions',
                  label: '',
                  key: 'actions',
                  sortable:false,
                  render: (e: any, record: any) => {
                    return (
                      <div className='w-full flex gap-1 justify-center items-center'>
                        {record?.DialCallDuration?.length!==0 && record?.RecordingUrl && record?.RecordingUrl?.toLowerCase() !== "null" && <div
                            // onKeyDown={() =>
                            //   handleSelectedRecord({ name: "audio", value: record })
                            // }
                            // tabIndex={0}
                            // role="button"
                            role= "presentation"
                            onClick={(e) => {
                              e.stopPropagation(); 
                              handleSelectedRecord({ name: "audio", value: record })
                              }
                            }
                          >
                            <Tooltip title="Audio & Transcript">
                            <img
                              src={
                                `${BASE_URL}/api/public/assets/images/audio.svg`
                              }
                              alt="View the record"
                              className='w-[80px] h-[20px]'
                            />
                            </Tooltip>
                          </div>}
                          {<div
                            className='w-[20px] h-[20px] p-1 cursor-pointer  flex items-center justify-center bg-white rounded-md'
                            role='presentation'
                            onClick={ (e) => {
                              e.stopPropagation(); 
                            }
                            }
                          >
                            <Tooltip title="Quick actions">
                              <Popover
                                content={
                                  <div className='flex flex-col gap-2'>
                                    {/* <p className='text-primary font-medium text-sm'>Quick Actions</p>
                                    <hr/> */}
                                    {[{"name": "comments", "label": `${"  "}Add a Comment`, icon:"comments.svg"}, {name: "book_appointment", label: "Book an appointment", icon: "appointments.svg"}].map((item:any)=>{
                                      return <button key={item.name} className='text-xs font-medium hover:shadow-sm transition-all duration-600 text-left text-gray-900 rounded-md p-1 flex items-center gap-1'>
                                        <img src={`${BASE_URL}/api/public/assets/images/${item.icon}`} alt={item.icon} className='w-[20px] h-[20px]'/>{item.label}
                                      </button>;
                                    })}
                                  </div>
                                }
                                placement="bottomRight"
                                title=""
                                trigger="click"
                              >
                                <p className='text-2xl font-bold' >+</p>
                              </Popover>
                            </Tooltip>
                          </div>}
                          {/* {view && <div
                            onKeyDown={() =>
                              handleSelectedRecord({ name: "view", value: record })
                            }
                            tabIndex={0}
                            role="button"
                            onClick={() =>
                              handleSelectedRecord({ name: "view", value: record })
                            }
                          >
                            <Tooltip title="View">
                            <img
                              src={
                                `${BASE_URL}/api/public/assets/images/eye.svg`
                              }
                              alt="View the record"
                              className='w-[80px] h-[15px]'
                            />
                            </Tooltip>
                          </div>} */}
                          
                      </div>
                    );
                  },
                })
              }
          }
          return field;
        })
        return {...prev,schema:updatedschema}
      })
    }
    console.log(filters);
    
    useEffect(() => {
      if (location.state?.source === "Calls") {
        dispatch(setTopbar(location.state?.source))
        dispatch(setActiveMenu("/calls"))
      }
    }, [location.state?.source, dispatch]);

    useEffect(() => {
      const id = setTimeout(() => {
        API_GET_CALLS_Data({
              startDate: dateRange[0],
              endDate: dateRange[1],
              searctext: searchText,
              callType:calltype,
              callStatus:filters.DialCallStatus,
              status:filters.status
          })
      }, 500)

      return () => {
          clearTimeout(id)
      }
    }, [searchText])

    useEffect(()=>{
        if(dateRange[0]==="" && dateRange[1]===""){
            change_segments_value(null);
            return;
        }
        const first = moment(dateRange[0], 'DD-MM-YYYY');
        const second = moment(dateRange[1], 'DD-MM-YYYY');
        const diffInDays = second.diff(first, 'days');
        const today = moment().startOf('day');
    
        if (first.isSame(today, 'day') && second.isSame(today, 'day')) {
            change_segments_value("TD")
        } else if (diffInDays === 7 && second.isSame(today, 'day')) {
            change_segments_value("TW")
        } else if (diffInDays === 30 && second.isSame(today, 'day')) {
            change_segments_value("TM")
        }else{
            change_segments_value(null)
        }
    },[dateRange])

    const change_segments_value=(val:string | null)=>{
      setSchema((prev:any)=>{
          const updatedSchema=prev.schema.map((field:any)=>{
              if(field.name==="filters"){
                field.fields=field.fields.map((ffield:any)=>{
                  if(ffield.name==="date_and_search"){
                    ffield.fields=ffield.fields.map((fffield:any)=>{
                      if(fffield.name==="segments"){
                        fffield.value=val
                      }
                      return fffield;
                    })
                  }
                  return ffield;
                })
              }
              return field;
          })
          return {...prev,schema:updatedSchema}
      })
    }

    const fetchCardsSchema = async() => {
      try{
        const res = await Utils.makeApiCall('/schema?schema_for=calls_cards', "GET")
        // const response = await axios.get("http://localhost:3001/api/schema?shema_for=calls_cards");
        // const res = response?.data;
        // console.log("ok", res);
        
        if (res.status) {
          setSchema((prev: any) => {
            const updatedSchema = prev?.schema?.map((f: any) => {
              if (f.name === "call_details") {
                f.fields = [...res.data.map((ff: any) => {
                  if (!ff.fields[0].fields[0].icon.startsWith(BASE_URL)) {
                    ff.fields[0].fields[0].icon = BASE_URL + ff.fields[0].fields[0].icon;
                  }
                  return ff;
                }),...newCards]
              }
              return f;
            });
            return { ...prev, schema: updatedSchema };
          });
        }
      } catch (e){
        console.log("error while fetching cards schema", e);
      }
    }

    useEffect(() => {
      fetchCardsSchema();
    }, [])
    // useEffect(() => {
    //   console.log(filters);
      
    //   if (filters !== null) {
    //     // Trigger the API call once filters are updated
    //     API_GET_CALLS_Data({
    //       startDate: dateRange[0],
    //       endDate: dateRange[1],
    //       searctext: searchText,
    //       callType: calltype,
    //       callStatus: filters,  // now it will have the updated filters
    //     });
    //   }
    // }, [filters]);

    const API_GET_CALLS_Data=async ({
      page = 1,
      limit = 10,
      startDate = "",
      endDate = "",
      searctext = "",
      callType="",
      callStatus=[],
      status=[]
    } = {})=>{
      setSchema((prev: any) => Utils.handleLoading(prev, true))
      try {
          const callStatusString = callStatus&&callStatus.length ? callStatus.join(",") : "";
          const statusString = status&&status.length ? status.join(",") : "";
          // console.log(callStatusString);
          
          const res: any = await Utils.makeApiCall(`/getIvrExotelCalls?org_id=${org_id}&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&search=${searctext}&call_type=${callType}&call_status=${callStatusString}&status=${statusString}`, "GET");
          if (res.status) {
              change_calls_data(res);

          } else {
              toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
          }
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
          console.error('Failed to fetch schema', error);
      }
      setSchema((prev: any) => Utils.handleLoading(prev, false))
    }
    const API_GET_REFRESH_CALLS_Data=async ({
      page = 1,
      limit = 10,
      startDate = "",
      endDate = "",
      searctext = "",
      callType=""
    } = {})=>{
      setLoading(true)
      try {
          const res: any = await Utils.makeApiCall(`/getIvrExotelCalls?org_id=${org_id}&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&searchText=${searctext}&call_type=${callType}`, "GET");
          if (res.status) {
              change_calls_data(res);

          } else {
              toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
          }
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
          console.error('Failed to fetch schema', error);
      }
      finally{
        setLoading(false)
      }
    }

    const downloadToExcel = async () => {
      try {
        const tableSchema = schema.schema.find((s: any) => s.element === "table");
        const { thead } = tableSchema;
        setLoading(true);

        const response = await Utils.makeApiCall(`/getIvrExotelCalls?page=1&limit=10&startDate=${dateRange[0]}&endDate=${dateRange[1]}&download=true&search=${searchText}`, "GET");
        // const formattedData: any = response?.data.map((item: any) => {
        //   const formattedStartTime = moment(item.StartTime).format('DD-MM-YYYY HH:mm:ss');
        //   let formattedDuration = '';
        //   let formattedDialCallStatus = item.DialCallStatus;
  
        //   if (item.DialCallStatus.toLowerCase() === "completed") {
        //       const durationInSeconds = item.DialCallDuration;
        //       const duration = moment.duration(durationInSeconds, 'seconds');
              
        //       if (duration.asHours() >= 1) {
        //           formattedDuration = `${Math.floor(duration.asHours())}h ${duration.minutes()}m ${duration.seconds()}s`;
        //       } else if (duration.asMinutes() >= 1) {
        //           formattedDuration = `${Math.floor(duration.asMinutes())}m ${duration.seconds()}s`;
        //       } else {
        //           formattedDuration = `${duration.seconds()}s`;
        //       }
  
        //       formattedDialCallStatus = "Call was successful";
        //   } else if (item.digits === "\"2\"") {
        //       formattedDialCallStatus = "Ambulance request";
        //   } else if (item.digits === "\"1\"") {
        //       formattedDialCallStatus = "Callback request";
        //   }
  
        //   const formattedDirection = item.Direction.charAt(0).toUpperCase() + item.Direction.slice(1).toLowerCase();
  
        //   return {
        //       ...item,
        //       DialCallDuration: formattedDuration,
        //       StartTime: formattedStartTime,
        //       originalStartTime: item.StartTime,
        //       DialCallStatus: formattedDialCallStatus,
        //       Direction: formattedDirection
        //   };
        // });
  
        // const sortedData = formattedData.sort((a:any, b:any) => {
        //     return moment(b.originalStartTime).valueOf() - moment(a.originalStartTime).valueOf();
        // });
        // const data = sortedData.map(({ originalStartTime, ...item }: any) => item);
        setLoading(false);

        if (!response?.data || response?.data.length === 0) {
          toast.error("No data available to download", { position: "top-center", autoClose: AUTO_CLOSE });
          return;
        }

        Utils.exportToExcel(thead, response?.data, "Calls");
      } catch (error) {
        console.error("Error downloading Excel:", error);
        toast.error("Failed to download data as Excel", { position: "top-center", autoClose: AUTO_CLOSE });
      }
      finally{
        setLoading(false);
      }
    };

    const setTablePagination=()=>{
      setSchema((prevState: any) => {
        const updatedSchema = prevState.schema.map((s: any) => {
          if (s.name === "table") {
            s.value = {name: 'pagination', page: 1, pageSize: 10}
          }
          return s;
        });
        return { ...prevState, schema: updatedSchema };
      });
    }

    const handleAgentStatsModal = async (visible: boolean, data = []) =>{

      setSchema((prev: any)=>{
        prev?.schema?.map(async (f: any)=>{
          if(f.name === "agent_stats_modal"){
            f.visible = visible
            if(data){
                f?.fields?.map((ff: any)=>{
                  if(ff.name === "agent_stats_table"){
                    ff['tbody'] = data?.map((acc: any)=>{
                      return {...acc}
                    })
                  }
                }) 
            }
          }
        })

        return {...prev}
      })
    }

    const fetchAudio = async (url:any) => {
      const response = await fetch(url, {
          headers: {
              'Authorization': 'Basic ' + btoa('00d1ed376737e1388536fb2afbfda8e31c4d0e2e8e4731c5:809c45c638cb4a64a02daf60ce5d7eefadfde89306d6c7c9'),
          },
      });
      const blob = await response.blob();
      const audioUrl = URL.createObjectURL(blob);
      return audioUrl;
  };

    const change_model_data=async (url:any,res:any)=>{
      // const audioUrl=await fetchAudio(url)
      // // console.log(audioUrl)
      // if(audioUrl){
        setSchema((prev:any)=>{
          const updatedSchema=prev.schema.map((field:any)=>{
            if(field.name==="view_modal_container"){
              field.visible=true,
              field.fields=field.fields.map((ffield:any)=>{
                if(ffield.name==="modal_body"){
                  ffield.fields=ffield.fields.map((fffield:any)=>{
                    if(fffield.name==="audio_text"){
                       fffield.label=res?.transcript;
                    }
                    if(fffield.name==="audio"){
                      fffield.label=<audio controls controlsList="nodownload" className='w-[100%]'><source src={url} type="audio/mpeg" /> <track kind="captions" src="your-captions-file.vtt" srcLang="en" label="English captions" /> Your browser does not support the audio element.</audio>
                    }
                    return fffield;
                  })
                }
                return ffield;
              })
            }
            return field;
          })
          return {...prev,schema:updatedSchema}
        })
      // }
    } 
    

    const API_GET_AUDIO_TEXT=async (record:any)=>{
     setLoading(true)
      try {
          const res: any = await Utils.makeApiCall(`/getCallTranscripts?id=${record?._id}`, "GET");
          if (res.status) {
            change_model_data(record?.RecordingUrl,res?.data);
          } else {
              toast.error(res?.message ? res?.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
          }
          setLoading(false)
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
          console.error('Failed to fetch schema', error);
      }
      setLoading(false)
    }


    const GET_AGENT_API_STATS = async ({
      startDate = "",
      endDate = "",
      searchtext = agentSearchText
    } = {}) =>{
      

      setSchema((prev: any) => Utils.handleLoading(prev, true, "agent_stats_table"))
      try {
          const res: any = await Utils.makeApiCall(`/getIvrAgentStats?org_id=${org_id}&startDate=${startDate}&endDate=${endDate}&search=${searchtext}`, "GET");

          if (res.status) {
            handleAgentStatsModal(true, res.data)

          } else {
              toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
          }
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
          console.error('Failed to fetch schema', error);
      }
      setSchema((prev: any) => Utils.handleLoading(prev, false, "agent_stats_table"))

    }

    const handleCardsClick=(e:any)=>{
      setSchema((prev:any)=>{
        const updatedSchema=prev.schema.map((field:any)=>{
          if(field.name==="call_details"){
            field.fields=field.fields.map((ffield:any)=>{
              if(ffield?.name===e){
                ffield.className="rounded-md flex cursor-pointer border-[#93B8E2] gap-2 items-center justify-between border-[2px]  p-[10px]"
              }
              if(ffield?.name!==e){
                ffield.className="rounded-md flex cursor-pointer border-[#93B8E2] gap-2 items-center justify-between border-[1px]  p-[10px]"
              }
              return ffield
            })
          }
          return field;
        })
        return {...prev,schema:updatedSchema}
      })
    }

    const handleSelectedRecord=(e:any)=>{
      if (e.name === "btn_download") {
        downloadToExcel();
      }
      if(e.name === "search"){
        setSearchText(e.value)
        setSchema((prevState: any) => {
          const updatedSchema = prevState.schema.map((s: any) => {
            if (s.element === "table") {
              s.value = {name: 'pagination', page: 1, pageSize: 10}
            }
            return s;
          });
          return { ...prevState, schema: updatedSchema };
        });
      }
      if(e.name === "agent_modal_search"){
        setAgentSearchText(e.value);
        GET_AGENT_API_STATS({searchtext: e.value })
      }
      if (e.name === 'date') {
        setSchema((prevState: any) => {
          const updatedSchema = prevState.schema.map((s: any) => {
            if (s.element === "table") {
              s.value = {name: 'pagination', page: 1, pageSize: 10}
            }
            return s;
          });
          return { ...prevState, schema: updatedSchema };
        });
        if (e.value === '') {
          
            setDateRange(['', ''])
            API_GET_CALLS_Data({ startDate: "", endDate: "",searctext:searchText,callType:calltype,callStatus:filters.DialCallStatus,status:filters.status })
        } else {
            setDateRange(e.value)
            API_GET_CALLS_Data({ startDate: e.value[0], endDate: e.value[1],searctext:searchText,callType:calltype,callStatus:filters.DialCallStatus,status:filters.status });
        }
      }
      if (e.name === 'agent_modal_date') {
       
        if (e.value === '') {
          setAgentDateRange(['', ''])
            GET_AGENT_API_STATS({ startDate: "", endDate: "" })
        } else {
          setAgentDateRange(e.value)
            GET_AGENT_API_STATS({ startDate: e.value[0], endDate: e.value[1] });
        }
      }
      if(e.name === "btn_refresh"){
        setTablePagination();
        handleCardsClick("total_calls")
        API_GET_REFRESH_CALLS_Data({
          // page: pagination.pagenum,
          // limit: pagination.limit,
          startDate: dateRange[0],
          endDate: dateRange[1],
          searctext: "",
          callType:""
          
        })
      }
      if (e.name === "table") {
        
        if (e.value?.name === "pagination") {
          const { page, pageSize } = e.value;
          API_GET_CALLS_Data({
            page: page,
            limit: pageSize,
            startDate: dateRange[0],
            endDate: dateRange[1],
            searctext: searchText,
            callType:calltype,
            callStatus:filters.DialCallStatus,
            status:filters.status
          });
        } 
        else if (e.value?.name === "filters") {
          const {DialCallStatus,status} = e.value.value;
          setFilters(e.value.value)
          API_GET_CALLS_Data({
            startDate: dateRange[0],
            endDate: dateRange[1],
            searctext: searchText,
            callType:calltype,
            callStatus:DialCallStatus,
            status:status,
            page:1 

          });
        }
        if (e?.["value"]?.name === "view") {
          const id =  e["value"]?.["value"]?.["_id"] || e["value"]?.["_id"];
          navigate(`/calls/view/${id}`)
        }
      }
    
      if (e.name === "segments") {
        if (e.value === "TD") {
            setDateRange([
                moment().format('DD-MM-YYYY'),
                moment().format('DD-MM-YYYY'),
            ]);
            API_GET_CALLS_Data({
                startDate: moment().format('DD-MM-YYYY'),
                endDate: moment().format('DD-MM-YYYY'),
                searctext: searchText,
                callType:calltype,
                callStatus:filters.DialCallStatus,
                status:filters.status

            })
            setSchema((prev: any) => {
                const updatedSchema = prev.schema.map((field: any) => {
                    if (field.name === "filters") {
                        field.fields = field.fields.map((ffield: any) => {
                            if (ffield.name === "date_and_search") {
                                ffield.fields = ffield.fields.map((ff: any) => {
                                    if (ff.name === "date") {
                                        ff.value = [
                                            moment().format('DD-MM-YYYY'),
                                            moment().format('DD-MM-YYYY'),
                                        ];
                                    }
                                    return ff;
                                });
                            }
                            return ffield;
                        });
                    }
                    if(field.element==="table"){
                      field.value={
                          name: 'pagination',
                          page: 1,
                          pageSize: 10,
                      }
                  }
                    return field;
                });
                return { ...prev, schema: updatedSchema };
            });
        }
        if (e.value === "TM") {
            setDateRange([
                moment().subtract(30, 'days').format('DD-MM-YYYY'),
                moment().format('DD-MM-YYYY'),
            ]);
            API_GET_CALLS_Data({
                startDate: moment().subtract(30, 'days').format('DD-MM-YYYY'),
                endDate: moment().format('DD-MM-YYYY'),
                searctext: searchText,
                callType:calltype,
                callStatus:filters.DialCallStatus,
                status:filters.status
            })
            setSchema((prev: any) => {
                const updatedSchema = prev.schema.map((field: any) => {
                    if (field.name === "filters") {
                        field.fields = field.fields.map((ffield: any) => {
                            if (ffield.name === "date_and_search") {
                                ffield.fields = ffield.fields.map((ff: any) => {
                                    if (ff.name === "date") {
                                        ff.value = [
                                            moment().subtract(30, 'days').format('DD-MM-YYYY'),
                                            moment().format('DD-MM-YYYY'),
                                        ];
                                    }
                                    return ff;
                                });
                            }
                            return ffield;
                        });
                    }
                    if(field.element==="table"){
                      field.value={
                          name: 'pagination',
                          page: 1,
                          pageSize: 10,
                      }
                  }
                    return field;
                });
                return { ...prev, schema: updatedSchema };
            });
        }
        if (e.value === "TW") {
            setDateRange([
                moment().subtract(7, 'days').format('DD-MM-YYYY'),
                moment().format('DD-MM-YYYY'),
            ]);
            API_GET_CALLS_Data({
                startDate: moment().subtract(7, 'days').format('DD-MM-YYYY'),
                endDate: moment().format('DD-MM-YYYY'),
                searctext: searchText,
                callType:calltype,
                callStatus:filters.DialCallStatus,
                status:filters.status,
            })
            setSchema((prev: any) => {
                const updatedSchema = prev.schema.map((field: any) => {
                    if (field.name === "filters") {
                        field.fields = field.fields.map((ffield: any) => {
                            if (ffield.name === "date_and_search") {
                                ffield.fields = ffield.fields.map((ff: any) => {
                                    if (ff.name === "date") {
                                        ff.value = [
                                            moment().subtract(7, 'days').format('DD-MM-YYYY'),
                                            moment().format('DD-MM-YYYY'),
                                        ];
                                    }
                                    return ff;
                                });
                            }
                            return ffield;
                        });
                    }
                    if(field.element==="table"){
                      field.value={
                          name: 'pagination',
                          page: 1,
                          pageSize: 10,
                      }
                  }
                    return field;
                });
                return { ...prev, schema: updatedSchema };
            });
        }
      }
      if (e.name === "agent_modal_segments") {
        if (e.value === "TD") {
          setAgentDateRange([
                moment().format('DD-MM-YYYY'),
                moment().format('DD-MM-YYYY'),
            ]);
            GET_AGENT_API_STATS({
                startDate: moment().format('DD-MM-YYYY'),
                endDate: moment().format('DD-MM-YYYY'),
                searchtext: searchText,
            })
            setSchema((prev: any) => {
                prev?.schema?.map((f: any)=>{
                  if(f.name==="agent_stats_modal"){
                    f?.fields?.map((ff: any)=>{
                      if(ff.name === "agent_modal_filters"){
                        ff?.fields?.map((fff: any)=>{
                          if(fff.name === "agent_modal_date_and_search"){
                            fff?.fields?.map((s: any)=>{
                              if(s.name === "agent_modal_date"){
                                s.value = [
                                            moment().format('DD-MM-YYYY'),
                                            moment().format('DD-MM-YYYY'),
                                          ]
                              }
                            })
                          }
                        })
                      }
                    })
                  }
                })

                return { ...prev}; 
            });
        }
        if (e.value === "TM") {
          setAgentDateRange([
                moment().subtract(30, 'days').format('DD-MM-YYYY'),
                moment().format('DD-MM-YYYY'),
            ]);
            GET_AGENT_API_STATS({
                startDate: moment().subtract(30, 'days').format('DD-MM-YYYY'),
                endDate: moment().format('DD-MM-YYYY'),
                searchtext: searchText,
            })
            setSchema((prev: any) => {
              prev?.schema?.map((f: any)=>{
                if(f.name==="agent_stats_modal"){
                  f?.fields?.map((ff: any)=>{
                    if(ff.name === "agent_modal_filters"){
                      ff?.fields?.map((fff: any)=>{
                        if(fff.name === "agent_modal_date_and_search"){
                          fff?.fields?.map((s: any)=>{
                            if(s.name === "agent_modal_date"){
                              s.value = [
                                          moment().subtract(30, 'days').format('DD-MM-YYYY'),
                                          moment().format('DD-MM-YYYY'),
                                        ]
                            }
                          })
                        }
                      })
                    }
                  })
                }
              })

              return { ...prev}; 
            });
        }
        if (e.value === "TW") {
          setAgentDateRange([
                moment().subtract(7, 'days').format('DD-MM-YYYY'),
                moment().format('DD-MM-YYYY'),
            ]);
            GET_AGENT_API_STATS({
                startDate: moment().subtract(7, 'days').format('DD-MM-YYYY'),
                endDate: moment().format('DD-MM-YYYY'),
                searchtext: searchText,
            })
            setSchema((prev: any) => {
              prev?.schema?.map((f: any)=>{
                if(f.name==="agent_stats_modal"){
                  f?.fields?.map((ff: any)=>{
                    if(ff.name === "agent_modal_filters"){
                      ff?.fields?.map((fff: any)=>{
                        if(fff.name === "agent_modal_date_and_search"){
                          fff?.fields?.map((s: any)=>{
                            if(s.name === "agent_modal_date"){
                              s.value = [
                                          moment().subtract(7, 'days').format('DD-MM-YYYY'),
                                          moment().format('DD-MM-YYYY'),
                                        ]
                            }
                          })
                        }
                      })
                    }
                  })
                }
              })

              return { ...prev}; 
          });
        }
      }
      if(e?.value?.name==="view"){
        navigate(`/calls/view/${e?.value.value?._id}`)
      }
      if(e.name === "btn_agent_stats"){
        GET_AGENT_API_STATS({
          startDate: moment().subtract(7, 'days').format('DD-MM-YYYY'),
          endDate: moment().format('DD-MM-YYYY'),
        })
      }
      if(e.name === "agent_stats_modal_close"){
        handleAgentStatsModal(false)
        setSchema((prev: any)=>{
          prev?.schema?.map(async (f: any)=>{
            if(f.name === "agent_stats_modal"){
                f?.fields?.map((ff: any)=>{
                  if(ff.name === "agent_modal_filters"){
                    ff?.fields?.map((fff: any)=>{
                      
                      
                      if(fff.name === "agent_modal_date_and_search"){
                        fff?.fields?.map((g: any)=>{
                          if(g.name === "agent_modal_segments"){
                            g.value = "TW"

                          }
                        })
                      }
                    })
                  }
                })
            }
          })
  
          return {...prev}
        })
      }
      if(e.name==="total_calls"){
        setCalltype("")
        handleCardsClick(e.name)
        setTablePagination();
        API_GET_CALLS_Data({ startDate: dateRange[0], endDate: dateRange[1],searctext:searchText,callType:"",status:filters.status });
      }
      if(e.name==="answered_calls"){
        setCalltype("answered_calls")
        handleCardsClick(e.name)
        setTablePagination();
        API_GET_CALLS_Data({ startDate: dateRange[0], endDate: dateRange[1],searctext:searchText,callType:"answered_calls",status:filters.status });
      }
      if(e.name==="total_missed_calls"){
        setCalltype("missed_calls")
        handleCardsClick(e.name)
        setTablePagination();
        API_GET_CALLS_Data({ startDate: dateRange[0], endDate: dateRange[1],searctext:searchText,callType:"missed_calls",status:filters.status });
      }
      if(e.name==="total_callback_requests"){
        setCalltype("call_back")
        handleCardsClick(e.name)
        setTablePagination();
        API_GET_CALLS_Data({ startDate: dateRange[0], endDate: dateRange[1],searctext:searchText,callType:"call_back",status:filters.status });
      }
      if(e.name==="total_ambulance_requests"){
        setCalltype("ambulance_request")
        handleCardsClick(e.name)
        setTablePagination();
        API_GET_CALLS_Data({ startDate: dateRange[0], endDate: dateRange[1],searctext:searchText,callType:"ambulance_request",status:filters.status });
      }
      if(e.name==="audio"){
        API_GET_AUDIO_TEXT(e?.value)
      }

      if(e.name==="modal_close"){
        setSchema((prev:any)=>{
          return {...prev,schema:prev.schema.map((field:any)=>{
            if(field.name==="view_modal_container"){
              field.visible=false;
            }
            return field;
          })}
        })
      }
    }

  return (
    <div className="bg-[#EFF1F9]">
      {loading && <LoadingState />}
      <ElementExecutor
        data={schema}
        selectedRecord={(e: any) => {
          handleSelectedRecord(e);
        }}
      />
    </div>
  )
}

export default Calls;
