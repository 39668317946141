import React, { useEffect, useState } from 'react'
import { ElementExecutor } from '@apexcura/core'
import moment from 'moment'
import { toast } from 'react-toastify'
import Utils from '../../utils';
import { CONSTANTS } from "../../utils/app-constants";
import LoadingState from "./../LoadingState";
import { HandleModalDataParams, Item, LeadStatus } from '../../utils/types'
import Storage from '../../utils/local-storage'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveMenu, setTopbar } from '../../redux/uiSchema/uiSchemaSlice';
import { getPagePermissions } from '../../redux/auth/authSlice';
import { Tooltip,Avatar } from "antd";

const { BASE_URL, FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;
const LeadStatusStyles:any = {
  "New": "bg-[#29AAE1]/10 text-[#29AAE1]",
  "Open": "bg-cyan-500/5 text-cyan-500",
  "In-Progress": "bg-yellow-500/10 text-yellow-500",
  "Qualified": "bg-green-500/5 text-green-500",
  "Callback": "bg-fuchsia-500/5 text-fuchsia-500",
  "Closed": "bg-red-500/5 text-red-500",
  "CONFIRMED":"bg-green-500/5 text-green-500"
};
export const ViewAppointments = () => {
  const [schema, setSchema] = useState<any>({
    className:
      'w-full bg-white rounded-lg py-[10px] px-[20px] flex flex-col gap-3',
    schema: [
      {
        name: 'filters',
        fields: [
          {
            name:"date_and_search",
            className:"flex gap-2 justify-center items-center ",
            fields:[
              {
                name: "search",
                label: "",
                placeholder: "Name, mobile..",
                element: "input-text",
                isSearch:true,
                value: null,
                className: "",
                icon:"search",
                iconsClassName:"w-[15px] flex justify-center items-center"
              },
              {
                  name:"segments",
                  element:"segmented",
                  segmentedOptions:[
                    {
                        label:(<Tooltip title="Today">1D</Tooltip>),
                        value:"TD"
                    },
                    {
                        label:(<Tooltip title="This Week">1W</Tooltip>),
                        value:"TW"
                    },
                    {
                        label:(<Tooltip title="This Month">1M</Tooltip>),
                        value:"TM"
                    },
                  ],
                  visible:true,
                  value:"TD"
              },
              {
                name: 'date',
                label: '',
                placeholder: 'Select a date',
                element: 'daterangepicker',
                value: [moment().format('DD-MM-YYYY'), moment().format("DD-MM-YYYY")],
                className: 'flex-1 w-[240px]',
              },
              
            ]
          },
          {
            name:"refresh_and_download",
            className:"flex gap-2 justify-center items-center ",
            fields:[
              {
                name: 'branch-filter',
                // label: 'Doctor',
                element: 'single-select',
                containerClassName:'w-full flex flex-row',
                placeholder:'Select Branch',
                className: 'w-[200px]',
                options:[]
              },
              {
                name: 'doctor-filter',
                // label: 'Doctor',
                element: 'single-select',
                containerClassName:'w-full flex flex-row',
                placeholder:'Select Doctor',
                className: 'w-[200px]',
                options:[]
              },
              {
                name: 'btn_download',
                label: 'Download',
                element: 'button',
                icon: 'download',
                visible: false,
                className: 'secondary-button',
                action: 'download_list',
                iconsClassName: 'size-4',
              },
              {
                name: "btn_add",
                label: "Add",
                element: "button",
                icon: "add",
                visible: false,
                className: "add-button hover:shadow-lg ",
                iconsClassName: "size-4",
              },
            ]
          },
          
        ],
        className: 'flex items-center justify-between gap-2',
      },
      {
        className: 'mt-2',
        name: "appointments_table",
        element: 'table',
        loading: true,
        variant: 'plain',
        count:0,
        size: 'small',
        pagination: true, 
        view: true,
        visible: true,
        thead: [
          { name: 'patient_name', label: 'Name', key: 'patient_name',
            render: (e:any) =>{               
              if(e){
                  return (
                    <div className="flex gap-1">
                        <Avatar size={"small"} style={{backgroundColor:`${Utils.stringToColour(e)}`}}> 
                            <p className={`capitalize`}>{Utils.initials(e)}</p>
                        </Avatar> 
                        {<p className={`capitalize`}>{e}</p>}
                    </div>
            
                  )
              }
            }
          },
          {
            name: 'phone_number',
            label: 'Mobile',
            key: 'phone_number',
          },
          {name:'date', label:"Date", key:'date'},
          {name:'start_time', label:"Start Time", key:'startTime'},
          {name:'end_time', label:"End Time", key:'endTime'},
          {name: 'doctor_name', label: 'Doctor', key: 'doctor_name', render: (e:any) => <p className={`capitalize`}>{e}</p>},
          { name: 'department_name', label: 'Department', key: 'department_name', render: (e:any) => <p className={`capitalize`}>{e}</p> },
          { name: 'appointment_status', label: 'Status', key: 'status',render:(e:any)=>{
            
            const bgColor:string = LeadStatusStyles[e] || "bg-[#F2F2F2]/50 text-default-300"
            return <span className={`w-full p-1 px-2 rounded-md font-medium ${bgColor}`}>{e}</span>
          }  },
          {
            name: 'Actions',
            label: 'Actions',
            key: 'actions',
            render:(e:any,record:any)=>{
              return <button 
              onClick={()=>{handleShowPopup(record)}}><img src = {
                `${BASE_URL}/api/public/assets/images/edit.svg`
            } alt = "edit"
            /></button>
            } 
          }
        ],
        tbody: [],
        modalContent: {
          className: "flex flex-col gap-2 overflow-auto max-h-[400px] p-[10px]",
          fields: [],
        },
      },
    ],
  });

  const [dateRange, setDateRange] = useState<[string, string]>([moment().format('DD-MM-YYYY'), moment().format("DD-MM-YYYY")]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [doctorId,setDoctorId] = useState("");
  const [branchId,setBranchId] = useState("")
  const location=useLocation();
  const dispatch=useDispatch();
  const navigate=useNavigate()
  const [pagination,setPagination]=useState({pagenum:1,limit:10});

  const org_id = Storage.getItem("details")?.["org_id"];

  useEffect(()=>{
    (async()=>{
      const doctors = await API_GET_Doctors_List()
      API_GET_Appointments({ startDate: dateRange[0], endDate: dateRange[1], searchtext: searchText});  
      setSchema((prev:any)=>{
        return{
          ...prev,
          schema:prev.schema.map((field:any)=>{
            if(field.name === 'filters'){
              return{
                ...field,
                fields:field.fields.map((f:any)=>{
                  if(f.name === 'refresh_and_download'){
                    return{
                      ...f,
                      fields:f.fields.map((ff:any)=>{
                        if(ff.name === 'doctor-filter'){
                          return{
                            ...ff,
                            options:[{
                              value:'',
                              label: "All",
                            },...doctors]
                          }
                        }
                        return ff
                      })
                    }
                  }
                  return f
                })
              }
            }
            return field
          })
        }
      })
    })()
  },[branchId])

  useEffect(() => {
    if (location.state?.source === "Leads") {
      dispatch(setTopbar(location.state?.source))
      dispatch(setActiveMenu("/leads"))
    }
  }, [location.state?.source, dispatch]);

  useEffect(()=>{
    (async()=>{
      const branches = await Get_User_Branches()
      setBranchId(branches[0].branch_id)
      setSchema((prev:any)=>{
          return{
              ...prev,
              schema:prev.schema.map((field:any)=>{
                  if(field.name === 'filters'){
                      return{
                          ...field,
                          fields:field.fields.map((f:any)=>{
                              if(f.name === 'refresh_and_download'){
                                  return{
                                      ...f,
                                      fields:f.fields.map((ff:any)=>{
                                        if(ff.name === 'branch-filter'){
                                          return{
                                            ...ff,
                                            options:branches.map((branch:any)=>{
                                              return{
                                                  label:branch.branch_name,
                                                  value:branch.branch_id
                                              }
                                            }),
                                            value:{
                                                label:branches[0].branch_name,
                                                value:branches[0].branch_id
                                            }
                                          }
                                        }
                                        return ff
                                      })
                                  }
                              }
                              return f
                          })
                      }
                  }
                  return field
              })
          }
      })
  })()
  },[])


  useEffect(() => {
    const id = setTimeout(() => {
      API_GET_Appointments({ startDate: dateRange[0], endDate: dateRange[1], searchtext: searchText});
    }, 500);

    return () => {
      clearTimeout(id);
    }
  }, [searchText,doctorId])

  useEffect(()=>{
    if(dateRange[0]==="" && dateRange[1]===""){
        change_segments_value(null);
        return;
    }
    const first = moment(dateRange[0], 'DD-MM-YYYY');
    const second = moment(dateRange[1], 'DD-MM-YYYY');
    const diffInDays = second.diff(first, 'days');
    const today = moment().startOf('day');

    if (first.isSame(today, 'day') && second.isSame(today, 'day')) {
        change_segments_value("TD")
    } else if (diffInDays === 7 && second.isSame(today, 'day')) {
        change_segments_value("TW")
    } else if (diffInDays === 30 && second.isSame(today, 'day')) {
        change_segments_value("TM")
    }else{
        change_segments_value(null)
    }
  },[dateRange])

  const change_segments_value=(val:string | null)=>{
    setSchema((prev:any)=>{
        const updatedSchema=prev.schema.map((field:any)=>{
            if(field.name==="filters"){
              field.fields=field.fields.map((ffield:any)=>{
                if(ffield.name==="date_and_search"){
                    ffield.fields=ffield.fields.map((fffield:any)=>{
                        if(fffield.name==="segments"){
                            fffield.value=val;
                        }
                        return fffield;
                    })
                }
                return ffield;
              })
            }
            return field;
        })
        return {...prev,schema:updatedSchema}
    })
  }

  const API_GET_Appointments = async ({
    page = 1,
    limit = 10,
    startDate = "",
    endDate = "",
    searchtext = "",
    departmentId = "",
  } = {}) => {
    setSchema((prev: any) => Utils.handleLoading(prev, true));
    try {
      const res = await Utils.makeApiCall(`/appointments?search=${searchtext}&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&doctorId=${doctorId}&departmentId=${departmentId}&branchId=${branchId}`, "GET");
      if (!res.error) {
        const add = true
        const view = true
        const download = true
        setSchema((prev: any) => {
          return{
            ...prev,
            schema:prev.schema.map((s: any) => {
                if (s.element === "table") {
                  s.count = res.total;
                  s["tbody"] = res.appointments.map((acc: any) => {
                    const createdAt = acc["createdAt"]
                      ? moment(acc["createdAt"]).format("DD-MM-YYYY HH:mm")
                      : "";
                    return {
                      id:acc.id,
                      doctor_name:acc.doctor.name,
                      patient_name:acc.patient?.name,
                      phone_number:acc.patient?.mobile,
                      department_name:acc.department.name,
                      department:acc.department,
                      date:acc.date,
                      start_time:acc.startTime,
                      end_time:acc.endTime,
                      appointment_status:acc.appointmentStatus, 
                      patient:acc.patient,
                      doctor:acc.doctor,
                      duration:acc.duration,
                      createdAt 
                    };
                  });
                  const hasactions = s["thead"].some((ef:any)=>ef.name === "actions");
                  
                  s["loading"] = false;
                } else if (s.name === "filters") {
                  s.fields.map((f:any)=>{
                    if (f.name === "refresh_and_download"){
                      f.fields.map((sff:any)=>{
                        if (sff.name === "btn_add"){
                          sff.visible = add
                        } else if (sff.name === "btn_download"){
                          sff.visible = download
                        }
                      })
                    }
                  })
                }
                return s;
              })
          }
          
        });
      } else {
        toast.error(res.message ? res.message : FAILURE_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
      }
    } catch (error) {
      toast.error(CATCH_ERROR_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
    }
    setSchema((prev: any) => Utils.handleLoading(prev, false));
  };

  const API_GET_Doctors_List = async()=>{
    const res = await Utils.makeApiCall(`/doctors?branchId=${branchId}`, "GET");
    const doctorsList = res.data.map((doc:any)=>{
      return{
        value:doc._id,
        label:doc.name
      }
    })
    return doctorsList
  }

  const API_POST_Change_Appointment_Status = async(appointmentId:any,status:any)=>{
    try {
      const res = await Utils.makeApiCall(`/appointments/changeStauts`, "POST", {
        appointmentId: appointmentId,
        status: status,
      });
      if (!res.error) {
        handleClosePopup()
        toast.success(res.message, { position: "top-center", autoClose: AUTO_CLOSE });
        API_GET_Appointments({
          page: pagination.pagenum,
          limit: pagination.limit,
          startDate: dateRange[0],
          endDate: dateRange[1],
        });
      } else {
        toast.error(res.message ? res.message : FAILURE_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
      }
    } catch (error) {
      toast.error(CATCH_ERROR_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
    }

  }

  const Get_User_Branches = async()=>{
    const response = await Utils.makeApiCall(`/getUserBranches`,'GET')
    return response.data
}

  const downloadToExcel = async () => {
    try {
      const tableSchema = schema.schema.find((s: any) => s.element === "table");
      const { thead } = tableSchema;
      setLoading(true);

      const response = await Utils.makeApiCall(`/appointments?org_id=${org_id}&page=1&limit=10&startDate=${dateRange[0]}&endDate=${dateRange[1]}&download=true&search=${searchText}`, "GET");
      const data = response.appointments;
      setLoading(false);

      if (!data || data.length === 0) {
        toast.error("No data available to download", { position: "top-center", autoClose: AUTO_CLOSE });
        return;
      }

      const excelData = data.map((appt:any)=>{
        return{
          patient_name:appt.patient.name,
          phone_number:appt.patient.mobile,
          date:appt.date,
          startTime:appt.startTime,
          endTime:appt.endTime,
          doctor_name:appt.doctor.name,
          department_name:appt.department.name,
          status:appt.appointmentStatus
        }
      })

      Utils.exportToExcel(thead, excelData, "appointments");
    } catch (error) {
      toast.error("Failed to download data as Excel", { position: "top-center", autoClose: AUTO_CLOSE });
    }
  };

  const handleShowPopup = async(record:any) =>{
    console.log('record is ',record)
    const appointmentPopup = {
      name:"appointment_popup",
      element:"modal",
      visible:true,
      fields: [
        {
            name: 'modal_title',
            className:
                'w-full bg-[#F2F2F2] p-2 pl-4 flex items-center rounded-t-lg justify-between',
            fields: [
                {
                    name: 'modal_title_key',
                    element: 'div',
                    label: 'Appointment details',
                    className:
                        'font-semibold text-lg text-primary',
                },
                {
                    name: 'modal_close',
                    element: 'button',
                    label: 'X',
                    className:
                        'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
                },
            ],
        },
        {
            name: 'modal_body',
            className:
                'flex flex-col max-h-[400px] p-4 overflow-auto gap-1',
            fields: [
              // {
              //   className:'flex flex-row justify-between',
              //   fields:[
              //     {
              //       element:'div',
              //       label:<span><b>Date: </b><span>{record.date}</span></span>
              //     },
              //     {
              //       element:'div',
              //       label:<span><b>Timing: </b><span>{record.start_time}-{record.end_time} ({record.duration})</span></span>
              //     }
              //   ]
              // },
              // {
              //   element:'div',
              //   label:`Doctor`,
              //   className:'font-bold pt-2'
              // },
              // {
              //   className:'flex flex-row gap-4',
              //   fields:[
              //     {
              //       element:'div',
              //       label:<span><b>Name:</b> <span>{record.doctor_name}</span></span>
              //     },
              //     {
              //       element:'div',
              //       label:<span><b>Department:</b> <span>{record.department.name}</span></span>
              //     }
              //   ]
              // },
              // {
              //   element:'div',
              //   label:`Patient`,
              //   className:'font-bold pt-2'
              // },
              // {
              //   className:'flex divide-x-2 gap-4 p-2',
              //   fields:[
              //     {
              //       className:'flex flex-col',
              //       fields:[
              //         {
              //           element:'div',
              //           label:<span><b>Name:</b> <span>{record.patient.name}</span></span>,
              //         },
              //         {
              //           element:'div',
              //           label:<span><b>Mobile:</b> <span>{record.patient.mobile}</span></span>
              //         },
              //         {
              //           element:'div',
              //           label:<span><b>DOB:</b> <span>{record.patient.birthDate}</span></span>
              //         }
              //       ]
              //     },
              //     {
              //       className:'flex flex-col px-4',
              //       fields:[
              //         {
              //           element:'div',
              //           label:<span><b>Age:</b> <span>{record.patient.age}</span></span>
              //         },
              //         {
              //           element:'div',
              //           label:<span><b>Gender:</b> <span>{record.patient.gender}</span></span>
              //         }
              //       ]
              //     },
              //   ]
              // },
              {
                className:'flex flex-col gap-6 w-[50%]',
                fields:[
                    {
                        className:'grid grid-cols-4',
                        fields:[
                            {
                                element:'div',
                                label:'Date: ',
                                className:'text-[#69757B]'
                            },
                            {
                                element:'div',
                                label:`${record.date}`,
                                className:'col-span-3 text-[#37474F]'
                            },
                            {
                                element:'div',
                                label:'Time: ',
                                className:'text-[#69757B]'
                            },
                            {
                                element:'div',
                                label:`${record.start_time}-${record.end_time} (${record.duration} minutes)`,
                                className:'col-span-3 text-[#37474F]'
                            },
                        ]
                    },
                    {
                        className:'grid grid-cols-4',
                        fields:[
                            {
                                element:'div',
                                label:'Doctor: ',
                                className:'text-[#69757B]'
                            },
                            {
                                element:'div',
                                label:`${record.doctor_name}`,
                                className:'col-span-3 text-[#37474F]'
                            },
                            {
                                element:'div',
                                label:'Dept: ',
                                className:'text-[#69757B]'
                            },
                            {
                                element:'div',
                                label:`${record.department.name}`,
                                className:'col-span-3 text-[#37474F]'
                            },
                        ]
                    },
                    {
                        className:'grid grid-cols-4',
                        fields:[
                            {
                                element:'div',
                                label:'Patient: ',
                                className:'text-[#69757B]'
                            },
                            {
                                element:'div',
                                label:`${record.patient.name}`,
                                className:'col-span-3 text-[#37474F]'
                            },
                            record.patient.mobile?{
                                element:'div',
                                label:'Mobile: ',
                                className:'text-[#69757B]'
                            }:{},
                            record.patient.mobile?{
                                element:'div',
                                label:`${record.patient.mobile}`,
                                className:'col-span-3 text-[#37474F]'
                            }:{},
                            record.patient.birthDate?{
                                element:'div',
                                label:'DOB: ',
                                className:'text-[#69757B]'
                            }:{},
                            record.patient.birthDate?{
                                element:'div',
                                label:`${record.patient.birthDate}`,
                                className:'col-span-3 text-[#37474F]'
                            }:{},
                            record.patient.age?{
                                element:'div',
                                label:'Age: ',
                                className:'text-[#69757B]'
                            }:{},
                            record.patient.age?{
                                element:'div',
                                label:`${record.patient.age}`,
                                className:'col-span-3 text-[#37474F]'
                            }:{},
                            record.patient.gender?{
                                element:'div',
                                label:'Gender: ',
                                className:'text-[#69757B]'
                            }:{},
                            record.patient.gender?{
                                element:'div',
                                label:`${record.patient.gender}`,
                                className:'col-span-3 text-[#37474F]'
                            }:{},
                        ]
                    }
                ]
              },
              {
                className:'flex items-center gap-2 justify-end border-t-2 pt-4',
                fields:[
                  {
                    element:'button',
                    name:'cancel-appointment',
                    label:"Cancel Appointment",
                    appointment:record.id,
                    className:"px-2 py-2 bg-red-500 text-white rounded-md",
                  },
                  {
                    element:'button',
                    name:'confirm-appointment',
                    label:"Confirm Appointment",
                    appointment:record.id,
                    className:"px-2 py-2 bg-blue-500 text-white rounded-md",
                  }
                ]
              }
            ],
        },
    ],
    }
    setSchema((prev:any)=>{
      
      return{
        ...prev,
        schema:[...prev.schema, appointmentPopup]
      }
    })
  }

  const handleClosePopup = async()=>{
    setSchema((prev:any)=>{
      const filteredSchema = prev.schema.filter((field: any) => field.name !== "appointment_popup");
      return{
        ...prev,
        schema:filteredSchema
      }
    })
  }


  const handleSelectedRecord = (e: any) => {
    console.log(e);
    if (e.name === "btn_download") {
      downloadToExcel();
    }
    if(e.name==="btn_add"){
      navigate("/appointments/add")
    }
    if (e.name === "search") {
      setSearchText(e.value);
      setSchema((prevState: any) => {
        const updatedSchema = prevState.schema.map((s: any) => {
          if (s.element === "table") {
            s.value = { name: 'pagination', page: 1, pageSize: 10 };
          }
          return s;
        });
        return { ...prevState, schema: updatedSchema };
      });
    }
    if (e.name === "date") {
      if (e.value === "") {

        setDateRange(["", ""]);

        setSchema((prevState: any) => {
          const updatedSchema = prevState.schema.map((s: any) => {
            if (s.element === "table") {
              s.value = { name: 'pagination', page: 1, pageSize: 10 };
            }
            return s;
          });
          return { ...prevState, schema: updatedSchema };
        });
        API_GET_Appointments({ startDate: "", endDate: "" });
      } else {
        setSchema((prevState: any) => {
          const updatedSchema = prevState.schema.map((s: any) => {
            if (s.element === "table") {
              s.value = { name: 'pagination', page: 1, pageSize: 10 };
            }
            return s;
          });
          return { ...prevState, schema: updatedSchema };
        });
        setDateRange(e.value);
        API_GET_Appointments({ startDate: e.value[0], endDate: e.value[1] });
      }
    }
    if (e.name == "btn_refresh") {
      API_GET_Appointments({
        page: 1,
        limit: 10,
        startDate: dateRange[0],
        endDate: dateRange[1],
        searchtext: searchText
      });
    }
    if (e.name === "segments") {
      if (e.value === "TD") {
          setDateRange([
              moment().format('DD-MM-YYYY'),
              moment().format('DD-MM-YYYY'),
          ]);
          API_GET_Appointments({
              startDate: moment().format('DD-MM-YYYY'),
              endDate: moment().format('DD-MM-YYYY'),
              searchtext: searchText,
          })
          setSchema((prev: any) => {
              const updatedSchema = prev.schema.map((field: any) => {
                  if (field.name === "filters") {
                      field.fields = field.fields.map((ffield: any) => {
                          if (ffield.name === "date_and_search") {
                              ffield.fields = ffield.fields.map((ff: any) => {
                                  if (ff.name === "date") {
                                      ff.value = [
                                          moment().format('DD-MM-YYYY'),
                                          moment().format('DD-MM-YYYY'),
                                      ];
                                  }
                                  return ff;
                              });
                          }
                          return ffield;
                      });
                  }
                  if(field.element==="table"){
                    field.value={
                        name: 'pagination',
                        page: 1,
                        pageSize: 10,
                    }
                }
                  return field;
              });
              return { ...prev, schema: updatedSchema };
          });
      }
      if (e.value === "TM") {
          setDateRange([
              moment().subtract(30, 'days').format('DD-MM-YYYY'),
              moment().format('DD-MM-YYYY'),
          ]);
          API_GET_Appointments({
              startDate: moment().subtract(30, 'days').format('DD-MM-YYYY'),
              endDate: moment().format('DD-MM-YYYY'),
              searchtext: searchText,
          })
          setSchema((prev: any) => {
              const updatedSchema = prev.schema.map((field: any) => {
                  if (field.name === "filters") {
                      field.fields = field.fields.map((ffield: any) => {
                          if (ffield.name === "date_and_search") {
                              ffield.fields = ffield.fields.map((ff: any) => {
                                  if (ff.name === "date") {
                                      ff.value = [
                                          moment().subtract(30, 'days').format('DD-MM-YYYY'),
                                          moment().format('DD-MM-YYYY'),
                                      ];
                                  }
                                  return ff;
                              });
                          }
                          return ffield;
                      });
                  }
                  if(field.element==="table"){
                    field.value={
                        name: 'pagination',
                        page: 1,
                        pageSize: 10,
                    }
                }
                  return field;
              });
              return { ...prev, schema: updatedSchema };
          });
      }
      if (e.value === "TW") {
          setDateRange([
              moment().subtract(7, 'days').format('DD-MM-YYYY'),
              moment().format('DD-MM-YYYY'),
          ]);
          API_GET_Appointments({
              startDate: moment().subtract(7, 'days').format('DD-MM-YYYY'),
              endDate: moment().format('DD-MM-YYYY'),
              searchtext: searchText,
          })
          setSchema((prev: any) => {
              const updatedSchema = prev.schema.map((field: any) => {
                  if (field.name === "filters") {
                      field.fields = field.fields.map((ffield: any) => {
                          if (ffield.name === "date_and_search") {
                              ffield.fields = ffield.fields.map((ff: any) => {
                                  if (ff.name === "date") {
                                      ff.value = [
                                          moment().subtract(7, 'days').format('DD-MM-YYYY'),
                                          moment().format('DD-MM-YYYY'),
                                      ];
                                  }
                                  return ff;
                              });
                          }
                          return ffield;
                      });
                  }
                  if(field.element==="table"){
                    field.value={
                        name: 'pagination',
                        page: 1,
                        pageSize: 10,
                    }
                }
                  return field;
              });
              return { ...prev, schema: updatedSchema };
          });
      }
    }
    if (e.name === 'doctor-filter'){
      setDoctorId(e.value.value)
    }

    if (e.name === 'branch-filter'){
      setBranchId(e.value.value)
      setDoctorId('')
      setSchema((prev:any)=>{
        return{
          ...prev,
          schema:prev.schema.map((field:any)=>{
            if(field.name === 'filters'){
              return{
                ...field,
                fields:field.fields.map((f:any)=>{
                  if(f.name === 'refresh_and_download'){
                    return{
                      ...f,
                      fields:f.fields.map((ff:any)=>{
                        if(ff.name === 'doctor-filter'){
                          return{
                            ...ff,
                            value:{
                              value:'',
                              label: "All",
                            }
                          }
                        }
                        return ff
                      })
                    }
                  }
                  return f
                })
              }
            }
            return field
          })
        }
      })
    }

    if (e.name === "appointments_table") {

      if (e.value?.name === "pagination") {
        const { page, pageSize } = e.value;
        setPagination((prev:any)=>{
          return {...prev,pagenum:page,limit:pageSize}
        });
        API_GET_Appointments({
          page: page,
          limit: pageSize,
          startDate: dateRange[0],
          endDate: dateRange[1],
          searchtext: searchText
        });
      }
    }

    if(e.name === 'modal_close'){
      handleClosePopup()
    }

    if(e.name === 'cancel-appointment'){
      API_POST_Change_Appointment_Status(e.appointment,'CANCELLED')
    }

    if(e.name === 'confirm-appointment'){
      API_POST_Change_Appointment_Status(e.appointment,'CONFIRMED')
    }
  };



  return (
    <div className="bg-[#EFF1F9]">
      {loading && <LoadingState />}
      <ElementExecutor
        data={schema}
        selectedRecord={(e: any) => {
          handleSelectedRecord(e);
        }}
      />
    </div>
  );
};
// const refresh_leads = async () => {
//   const response = await Utils.makeApiCall('/leads', 'POST', {})
//   if (response.status) {
//     get_leads_details()
//   }
// }

// const handleSelectedRecord = (e: any) => {
//   if (e.name == 'btn_refresh') {
//     refresh_leads()
//   } else if (e.name == 'modal_close') {
//     handleModaldata({ data: [], visible: false })
//   } else if (e.name === 'table') {
//     const val = e['value'] ? e['value']?.['conversation'] : ''
//     if (val) {
//       API_GET_Conversations(val, e['value'])
//     }
//   }
// }



// return (
//   <div className="bg-[#EFF1F9]">
//     <ElementExecutor
//       data={schema}
//       setData={() => { }}
//       selectedRecord={(e: any) => {
//         handleSelectedRecord(e)
//       }}
//     />
//   </div>
// )



