import { ElementExecutor } from '@apexcura/core';
import React, { useEffect, useState } from 'react';
import { unstable_usePrompt, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CONSTANTS } from '../../utils/app-constants';
import Utils from '../../utils';
import moment from 'moment';
import LoadingState from '../LoadingState';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { RootState } from '../../redux/rootReducer';
import useExitPopUp from '../../hooks/useExitPopUp';
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;

const editPressNotes=() => ({
    className: "w-full bg-white rounded-lg p-8 flex flex-col gap-3 items-center",
    schema: [
      {
        name: "upload_fields",
        className: "w-full flex flex-col gap-2",
        fields: [
          {
            name: "name_and_type",
            className: "grid grid-cols-3  gap-2",
            fields: [
              {
                name: "title",
                element: "input-text",
                placeholder: "",
                label: "Title",
                containerClassName: "w-full flex flex-col",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                required: true,
                visible: true,
                value:null,
              },
              {
                name: "author_name",
                element: "input-text",
                placeholder: "",
                label: "Author Name",
                containerClassName: "w-full flex flex-col",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                required: true,
                visible: true,
                value:null,
              },
              {
                name:"submitted_on",
                className:"w-full flex flex-col gap-1",
                fields:[
                    {
                        element:"div",
                        name:"submitted_on",
                        label:"Submitted On",
                        className:"text-gray-500 font-semibold text-sm "
                    },
                    {
                        name: "date",
                        element: "datepicker",
                        placeholder: "",
                        label: "",
                        className:
                          "rounded-md w-full px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                        required: true,
                        visible: true,
                        value:"",
                    },
                ]
              }
            ],
          },
          {
            className:"flex flex-col gap-2 w-full",
            name:"editor",
            fields:[
              {
                element:"div",
                name:"Content",
                label:"Content",
                className:"text-gray-500 font-semibold text-sm "
            },
            {
              name: "content",
              element: "input-textarea",
              minRows:6,
              maxRows:6,
              className:
                "w-full rounded-md mb-4 text-gray-700 font-semibold focus:outline-none",
              visible: true,
            },
            ]
          },
          {
            name: "meta_keys",
            className: "grid grid-cols-2  gap-2",
            fields: [
              {
                name: "meta_description",
                element: "input-text",
                placeholder: "",
                label: "Meta Description",
                containerClassName: "w-full  col-span-2 flex flex-col",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                visible: true,
              },
              {
                name: "meta_keywords",
                element: "input-text",
                placeholder: "seperate keywords with comma(,)",
                label: "Meta Keywords",
                containerClassName: "w-full flex flex-col",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                visible: true,
              },
              {
                name: "banner_alt_text",
                element: "input-text",
                placeholder: "",
                label: "Alt text for banner Image",
                containerClassName: "w-full flex flex-col",
                labelClassName:
                "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md w-full px-3 py-1 text-gray-500 flex  tyext-sm gap-1 font-semibold focus:outline-none",
                visible: true,
                  
              },
            ],
          },
          {
            className:"flex flex-col gap-2 w-full",
            name:"upload",
            fields:[
              {
                element:"div",
                name:"upload",
                label:"Upload",
                className:"text-gray-500 font-semibold text-sm "
              },
              {
                name: "files",
                element: "upload",
                label: "Upload",
                accept: "image/*",
                multiple: true,
                className: "",
                required: true,
                visible: true,
                value:null,
              }
            ]
          },
        ],
      },
      {
        name: "cancel_and_submit",
        fields: [
          {
            name: "cancel",
            label: "Cancel",
            element: "button",
            className:
              "p-2 px-3  text-default-500 bg-gray-200 text-black rounded-md font-semibold",
          },
          {
            name: "submit",
            label: "Submit",
            element: "button",
            className:
              "p-2 px-3  text-default-500 bg-primary  rounded-md text-white font-semibold",
          },
        ],
        className: "flex self-end gap-2",
      },
    ],
});

const EditPressNote = () => {
    const [schema, setSchema] = useState<any>({
      ...editPressNotes()
    });
    const navigate=useNavigate();
    const [searchParams] = useSearchParams();
    const pressNoteId = searchParams.get('pressNoteId');
    const [imageName,setImageName]=useState("");
    const [loading,setLoadng]=useState(false)

    useEffect(() => {
      if (pressNoteId) {
          API_GET_PRESSNOTE_Details(pressNoteId);
      }
  }, [pressNoteId]);
  function urlToEnhancedBase64FileObject(url:any,orgName:string) {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const fileName = url.split('/').pop() || 'image-from-url';
          const file:any = new File([blob], fileName, { type: blob.type });
          
          const reader = new FileReader();
          reader.onloadend = function() {
            const base64data = reader.result;
            file["uid"]=`rc-upload-${Date.now()}-${Math.floor(Math.random() * 10)}`;
            const fileObject = {
              uid: `rc-upload-${Date.now()}-${Math.floor(Math.random() * 10)}`,
              name: orgName,
              originFileObj: file,
              status: 'done',
              thumbUrl: base64data,
              url: base64data,
            };
            resolve(fileObject);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
        .catch(error => reject(error));
    });
  }

  const getPayload = (e: any): FormData => {
    const formdata = new FormData();
    const title = e?.schema[0]?.fields[0]?.fields[0]?.value;
    const authorname = e?.schema[0]?.fields[0]?.fields[1]?.value;
    const submittedon=e?.schema[0]?.fields[0]?.fields[2]?.fields[1]?.value
    const content = e?.schema[0]?.fields[1]?.fields[1]?.value;
    const metaDescription = e?.schema[0]?.fields[2]?.fields[0]?.value;

    const metaKeywords = e?.schema[0]?.fields[2]?.fields[1]?.value; 

    const imageAltText = e?.schema[0]?.fields[2]?.fields[2]?.value;
    const thumbnail = e?.schema[0]?.fields[3]?.fields[1]?.value;
   

    formdata.append("title", title);
    formdata.append("authorname", authorname);
    formdata.append("submittedon", submittedon);
    formdata.append("content",content)
    formdata.append("metaDescription",metaDescription)
    formdata.append("metaKeywords",metaKeywords)
    formdata.append("imageAltText",imageAltText)
    if(imageName===thumbnail[0].name){
      return formdata;
    }

    let filelinks = [];
    if (thumbnail && thumbnail.length > 0) {
      filelinks = thumbnail.map((f: any) => f.originFileObj);
    }

    if (filelinks.length > 0) {
      filelinks.forEach((file: File, i: any) => {
        formdata.append(`thumbnail`, file);
      });
    }
    return formdata;
  };

  const API_GET_PRESSNOTE_Details = async (pressNoteId:string) => {
    try {
        const res = await Utils.makeApiCall(`/pressNote?pressNoteId=${pressNoteId}`, "GET");
       
        if(res.status){
          const {data}=res;
          setSchema((prev: any) => {
            const updatedSchema = { ...prev };
            updatedSchema.schema[0].fields[0].fields[0].value = data?.title;
            updatedSchema.schema[0].fields[0].fields[1].value = data?.authorname;
            const submittedDate = moment(data.submittedon);
            updatedSchema.schema[0].fields[0].fields[2].fields[1].value = submittedDate.format('DD-MM-YYYY');
            updatedSchema.schema[0].fields[1].fields[1].value = data?.content;
            updatedSchema.schema[0].fields[2].fields[0].value = data?.metaDescription || "";
            updatedSchema.schema[0].fields[2].fields[1].value = data?.metaKeywords || ""; 
            updatedSchema.schema[0].fields[2].fields[2].value = data?.imageAltText || " ";
            if(data?.thumbnail?.path){
              urlToEnhancedBase64FileObject(CONSTANTS.BASE_URL+"/api/"+data?.thumbnail?.path,data?.thumbnail?.original_name)
                .then((fileObject:any) => {
                  updatedSchema.schema[0].fields[3].fields[1].value=[{...fileObject}];
                })
                .catch(error => console.error('Error:', error)); 
              setImageName(data?.thumbnail?.original_name)
            }
            return updatedSchema;
          });
          
        }else{
          toast.error(res.message?res.message:FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
        }
        
    } catch (error) {
        toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
        console.error('Failed to fetch pressNote details', error);
    }
  };

  const [detectChange, setDetectChange] = useState(false)

  // Exit popup's custom hook
  useExitPopUp(detectChange)
  

    const handleSelectedRecord= async (e:any)=>{
      console.log(e)
        if(e.name === "cancel"){
          navigate("/pressNotes")
        }
        if(e.name==="submit"){
          setDetectChange(false)
          const payload:FormData=getPayload(schema);
          setLoadng(false)
          try{
            const res:any = await Utils.makeFormdataApiCall(`/pressNote?pressNoteId=${pressNoteId}`, "PUT",payload);
            if(res.status){
              toast.success(res.message, { position: 'top-center', autoClose: AUTO_CLOSE });
              navigate("/pressNotes")
            }else{
              toast.error(res.message?res.message:FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
            }
          }catch(error){
            toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
            console.error('Failed to send details', error);
          }finally{
            setLoadng(false)
          }

        }
    }

  return (
    <div>
       {loading && <LoadingState/>}
      <ElementExecutor
        data={schema}
        setData={(e: any) => {
          setSchema({ ...e });
        }}
        selectedRecord={(e: any) => {
          handleSelectedRecord(e);
          if(["title", "author_name", "submitted_o", "content", "meta_description", "meta_keywords", "banner_alt_text", "files"].includes(e?.name) ){
            setDetectChange(true)
          }
        }}
      />
    </div>
  )
}

export default EditPressNote;

